import React, { useEffect, useState } from 'react';
import API from '../../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../../ReactSession';
import { useHistory } from "react-router-dom";
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import DatePicker from "reactstrap-date-picker";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Input,
  Modal,
  ModalHeader, ModalBody, ModalFooter,
  Button,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
  Label,
  Col, CardBody, CardTitle
} from "reactstrap";

import Header from "components/Headers/Header.js";

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const PackagesIndex = () => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const history = useHistory();
  
  const [id, setId] = useState('');
  const [code, setCode] = useState('');
  const [title, setTitle] = useState('');
  const [status, setStatus] = useState(false);
  const [trial, setTrial] = useState(false);
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [category, setCategory] = useState('');
  const [canVirtualMoney, setCanVirtualMoney] = useState(false);
  const [canVirtualPoint, setCanVirtualPoint] = useState(false);
  const [canTool, setCanTool] = useState(false);
  const [canGroupUser, setCanGroupUser] = useState(false);
  const [canMultiVariant, setCanMultiVariant] = useState(false);
  const [canPromo, setCanPromo] = useState(false);
  const [canVoucher, setCanVoucher] = useState(false);
  const [canWaNotif, setCanWaNotif] = useState(false);
  const [canReport, setCanReport] = useState(false);
  const [canCashback, setCanCashback] = useState(false);
  const [canSubsidi, setCanSubsidi] = useState(false);
  const [canReferral, setCanReferral] = useState(false);
  const [canAppAndroid, setCanAppAndroid] = useState(false);
  const [canAppIos, setCanAppIos] = useState(false);
  const [canAppPos, setCanAppPos] = useState(false);
  const [canOrderTable, setCanOrderTable] = useState(false);
  const [canMultiOutlet, setCanMultiOutlet] = useState(false);
  const [canReservation, setCanReservation] = useState(false);

  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(1);
  const [paging, setPaging] = useState(true);
  const [search, setSearch] = useState('');
  const [spinner, setSpinner] = useState(true);
  const [loadMore, setLoadMore] = useState(true);
  const [loadMoreButton, setLoadMoreButton] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [spinnerSave, setSpinnerSave] = useState(false);

  const [modal, setModal] = useState(false);
  const toggle = (id) => {
    if(id){
      let dt = data.filter(o => o.id === id)[0]
      
      if(dt){
        setId(dt.id);
        setCode(dt.code);
        setTitle(dt.title);
        setStatus(dt.status);
        setTrial(dt.trial);
        setDescription(dt.description);
        setPrice(dt.price);
        setDiscount(dt.discount);
        setCategory(dt.category);
        setCanVirtualMoney(dt.can_virtual_money);
        setCanVirtualPoint(dt.can_virtual_point);
        setCanTool(dt.can_tool);
        setCanGroupUser(dt.can_group_user);
        setCanMultiVariant(dt.can_multi_variant);
        setCanPromo(dt.can_promo);
        setCanVoucher(dt.can_voucher);
        setCanWaNotif(dt.can_wa_notif);
        setCanReport(dt.can_report);
        setCanCashback(dt.can_cashback);
        setCanSubsidi(dt.can_subsidi);
        setCanReferral(dt.can_referral);
        setCanAppAndroid(dt.can_app_android);
        setCanAppIos(dt.can_app_ios);
        setCanAppPos(dt.can_app_pos);
        setCanOrderTable(dt.can_order_table);
        setCanMultiOutlet(dt.can_multi_outlet);
        setCanReservation(dt.can_reservation);
      }else{
        setId("");
        setCode("");
        setTitle("");
        setStatus(false);
        setTrial(false);
        setDescription("");
        setPrice("");
        setDiscount("");
        setCategory('');
        setCanVirtualMoney(false);
        setCanVirtualPoint(false);
        setCanTool(false);
        setCanGroupUser(false);
        setCanMultiVariant(false);
        setCanPromo(false);
        setCanVoucher(false);
        setCanWaNotif(false);
        setCanReport(false);
        setCanCashback(false);
        setCanSubsidi(false);
        setCanReferral(false);
        setCanAppAndroid(false);
        setCanAppIos(false);
        setCanAppPos(false);
        setCanOrderTable(false);
        setCanMultiOutlet(false);
        setCanReservation(false);
      }
    }else{
      setId("");
      setCode("");
      setTitle("");
      setStatus(false);
      setTrial(false);
      setDescription("");
      setPrice("");
      setDiscount("");
      setCategory('');
      setCanVirtualMoney(false);
      setCanVirtualPoint(false);
      setCanTool(false);
      setCanGroupUser(false);
      setCanMultiVariant(false);
      setCanPromo(false);
      setCanVoucher(false);
      setCanWaNotif(false);
      setCanReport(false);
      setCanCashback(false);
      setCanSubsidi(false);
      setCanReferral(false);
      setCanAppAndroid(false);
      setCanAppIos(false);
      setCanAppPos(false);
      setCanOrderTable(false);
      setCanMultiOutlet(false);
      setCanReservation(false);
    }
    setModal(!modal);
  }

  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);
  const [errorMessage, setErrorMessage] = useState('');

  const actPost = (id) => {
    setSpinnerSave(true);
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };
    const fd = new FormData();
    fd.append('code', code);
    fd.append('title', title);
    fd.append('status', status);
    fd.append('description', description);
    fd.append('trial', trial);
    fd.append('price', price);
    fd.append('discount', discount);
    fd.append('category', category);
    fd.append('can_virtual_money', canVirtualMoney);
    fd.append('can_virtual_point', canVirtualPoint);
    fd.append('can_tool', canTool);
    fd.append('can_group_user', canGroupUser);
    fd.append('can_multi_variant', canMultiVariant);
    fd.append('can_promo', canPromo);
    fd.append('can_voucher', canVoucher);
    fd.append('can_wa_notif', canWaNotif);
    fd.append('can_report', canReport);
    fd.append('can_cashback', canCashback);
    fd.append('can_subsidi', canSubsidi);
    fd.append('can_referral', canReferral);
    fd.append('can_app_android', canAppAndroid);
    fd.append('can_app_ios', canAppIos);
    fd.append('can_app_pos', canAppPos);
    fd.append('can_order_table', canOrderTable);
    fd.append('can_multi_outlet', canMultiOutlet);
    fd.append('can_reservation', canReservation);
    if(id){
      API.put(`packages/${id}`, fd, config )
        .then(res => {
          setId("");
          setCode("");
          setTitle("");
          setStatus(false);
          setTrial(false);
          setDescription("");
          setPrice("");
          setDiscount("");
          setCategory('');
          setCanVirtualMoney(false);
          setCanVirtualPoint(false);
          setCanTool(false);
          setCanGroupUser(false);
          setCanMultiVariant(false);
          setCanPromo(false);
          setCanVoucher(false);
          setCanWaNotif(false);
          setCanReport(false);
          setCanCashback(false);
          setCanSubsidi(false);
          setCanReferral(false);
          setCanAppAndroid(false);
          setCanAppIos(false);
          setCanAppPos(false);
          setCanOrderTable(false);
          setCanMultiOutlet(false);
          setCanReservation(false);
          setSpinnerSave(false);
          loadData('');
          toggle();
        })
        .catch(error => {
          setSpinnerSave(false);
          setVisible(true);
          if(error === undefined){
            setErrorMessage('Gagal, Terjadi kesalahan di server')
          }else{
            setErrorMessage(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }else{
      API.post('packages', fd, config )
        .then(res => {
          setId("");
          setCode("");
          setTitle("");
          setStatus(false);
          setTrial(false);
          setDescription("");
          setPrice("");
          setDiscount("");
          setCategory('');
          setCanVirtualMoney(false);
          setCanVirtualPoint(false);
          setCanTool(false);
          setCanGroupUser(false);
          setCanMultiVariant(false);
          setCanPromo(false);
          setCanVoucher(false);
          setCanWaNotif(false);
          setCanReport(false);
          setCanCashback(false);
          setCanSubsidi(false);
          setCanReferral(false);
          setCanAppAndroid(false);
          setCanAppIos(false);
          setCanAppPos(false);
          setCanOrderTable(false);
          setCanMultiOutlet(false);
          setCanReservation(false);
          setSpinnerSave(false);
          loadData('');
          toggle();
        })
        .catch(error => {
          setSpinnerSave(false);
          setVisible(true);
          if(error === undefined){
            setErrorMessage('Gagal, Terjadi kesalahan di server')
          }else{
            setErrorMessage(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }
  }

  const deletePost = (id) => {
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };
    API.delete(`packages/${id}`, config )
      .then(res => {
        loadData('');
      })
      .catch(error => {
        setVisible(true);
        if(error === undefined){
          setErrorMessage('Gagal, Terjadi kesalahan di server')
        }else{
          setErrorMessage(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const loadData = (keyword) => {    
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {key: keyword }};
    API.get(`packages`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setData(data)
        setSpinner(false)
        setLoadMore(false);
        setTotalCount(res.data.meta.pagination.total_entries);
        if(res.data.meta.pagination.total_pages <= 1){
          setPaging(false);
          console.log('No paging')
        }else{
          setPaging(true);
          console.log('Yes paging')
        }
      })
  }

  const loadMoreData = () => {
    setLoadMoreButton(true);
    setLoadMore(true);
    setOffset(offset+1)
    let offset_page = offset+1
    const config = { headers: { Authorization: `Bearer ${token}` }, params: { page: offset_page, key: search }};
    API.get(`packages`, config)
      .then(res => {
        setLoadMore(false);
        const data2 = res.data.data;
        setData([...data, ...data2]);
        if(res.data.meta.pagination.total_pages === offset_page){
          setPaging(false);
        }else{
          setLoadMoreButton(false);
        }
      })
  }

  const updateSearch = (search) => {
    setOffset(1)
    if(search === '' ){
      setSearch('');
    }else{
      setSearch(search);
    }
    setLoadMoreButton(false);
    loadData(search);
  }

  const clearSearch = (search) => {
    setOffset(1);
    updateSearch('');
    loadData('');
    setLoadMoreButton(false);
  }

  const categories = [
    {id: 'Online Store', name: 'Online Store'},
    {id: 'Online Food', name: 'Online Food'},
    {id: 'Online Hotel', name: 'Online Hotel'}
  ]

  useEffect(() => {
    if(token === undefined){
      history.push("/")
    }

    setSearch('');
    loadData('');
  },[])

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Kelola Paket</ModalHeader>
        <ModalBody>
          <Alert color="warning" isOpen={visible} toggle={onDismiss}>
            {errorMessage}
          </Alert>
          <Form role="form">
            <FormGroup className="mb-3">
              <div className='row'>
                <div className='col-6'>
                  <FormGroup className="form-checkbox">
                    <Label check>
                      <Input type="checkbox" defaultChecked={trial} onChange={e => setTrial(!trial)}/> Trial Paket?
                    </Label>
                  </FormGroup>
                </div>
                <div className='col-6'>
                  <FormGroup className="form-checkbox">
                    <Label check>
                      <Input type="checkbox" defaultChecked={status} onChange={e => setStatus(!status)}/> Aktifkan
                    </Label>
                  </FormGroup>
                </div>
                <div className='col-6'>
                  <Label className='label-form-group'>Kode Paket</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <Input
                      placeholder="Kode Paket"
                      value={code} onChange={e => setCode(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <Label className='label-form-group'>Nama Paket</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <Input
                      placeholder="Nama Paket"
                      value={title} onChange={e => setTitle(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-sm-12'>
                  <Label className='label-form-group'>Keterangan</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <Input
                      type="textarea"
                      placeholder="Keterangan"
                      value={description} onChange={e => setDescription(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <Label className='label-form-group'>Harga Paket</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        RP
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type='number'
                      placeholder="0"
                      value={price} onChange={e => setPrice(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <Label className='label-form-group'>Diskon Paket</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <Input
                      type='number'
                      placeholder="0"
                      value={discount} onChange={e => setDiscount(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-12'>
                  <Label className='label-form-group'>Kategori Paket</Label>
                  <InputGroup className="input-group-alternative">
                    <Input type="select" value={category} onChange={e => setCategory(e.target.value)}>
                      <option value=''>Pilih Kategori</option>
                      { categories ? (
                        categories.map((c) => {
                          return (
                            <option key={c.id} value={c.id}>{c.name}</option>
                          )
                        })
                      ) : null }
                    </Input>
                  </InputGroup>
                </div>
                <div className='col-12'>
                  <Label className='label-form-group'>Fitur Paket</Label>
                  <Card>
                    <CardBody>
                      <div className='row'>
                        <div className='col-6'>
                          <FormGroup className="form-checkbox">
                            <Label check>
                              <Input type="checkbox" defaultChecked={canGroupUser} onChange={e => setCanGroupUser(!canGroupUser)}/> Group User
                            </Label>
                          </FormGroup>
                        </div>
                        <div className='col-6'>
                          <FormGroup className="form-checkbox">
                            <Label check>
                              <Input type="checkbox" defaultChecked={canMultiVariant} onChange={e => setCanMultiVariant(!canMultiVariant)}/> Multi Variant Harga
                            </Label>
                          </FormGroup>
                        </div>
                        <div className='col-6'>
                          <FormGroup className="form-checkbox">
                            <Label check>
                              <Input type="checkbox" defaultChecked={canPromo} onChange={e => setCanPromo(!canPromo)}/> Fitur Promo
                            </Label>
                          </FormGroup>
                        </div>
                        <div className='col-6'>
                          <FormGroup className="form-checkbox">
                            <Label check>
                              <Input type="checkbox" defaultChecked={canVoucher} onChange={e => setCanVoucher(!canVoucher)}/> Fitur Voucher
                            </Label>
                          </FormGroup>
                        </div>
                        <div className='col-6'>
                          <FormGroup className="form-checkbox">
                            <Label check>
                              <Input type="checkbox" defaultChecked={canReport} onChange={e => setCanReport(!canReport)}/> Fitur Report
                            </Label>
                          </FormGroup>
                        </div>
                        <div className='col-6'>
                          <FormGroup className="form-checkbox">
                            <Label check>
                              <Input type="checkbox" defaultChecked={canVirtualMoney} onChange={e => setCanVirtualMoney(!canVirtualMoney)}/> Saldo Akun
                            </Label>
                          </FormGroup>
                        </div>
                        <div className='col-6'>
                          <FormGroup className="form-checkbox">
                            <Label check>
                              <Input type="checkbox" defaultChecked={canVirtualPoint} onChange={e => setCanVirtualPoint(!canVirtualPoint)}/> Point Akun
                            </Label>
                          </FormGroup>
                        </div>
                        <div className='col-6'>
                          <FormGroup className="form-checkbox">
                            <Label check>
                              <Input type="checkbox" defaultChecked={canTool} onChange={e => setCanTool(!canTool)}/> Fitur Tools
                            </Label>
                          </FormGroup>
                        </div>
                        <div className='col-6'>
                          <FormGroup className="form-checkbox">
                            <Label check>
                              <Input type="checkbox" defaultChecked={canWaNotif} onChange={e => setCanWaNotif(!canWaNotif)}/> Notifikasi WA
                            </Label>
                          </FormGroup>
                        </div>
                        <div className='col-6'>
                          <FormGroup className="form-checkbox">
                            <Label check>
                              <Input type="checkbox" defaultChecked={canCashback} onChange={e => setCanCashback(!canCashback)}/> Fitur Cashback
                            </Label>
                          </FormGroup>
                        </div>
                        <div className='col-6'>
                          <FormGroup className="form-checkbox">
                            <Label check>
                              <Input type="checkbox" defaultChecked={canSubsidi} onChange={e => setCanSubsidi(!canSubsidi)}/> Fitur Subsidi
                            </Label>
                          </FormGroup>
                        </div>
                        <div className='col-6'>
                          <FormGroup className="form-checkbox">
                            <Label check>
                              <Input type="checkbox" defaultChecked={canReferral} onChange={e => setCanReferral(!canReferral)}/> Fitur Referral
                            </Label>
                          </FormGroup>
                        </div>
                        <div className='col-6'>
                          <FormGroup className="form-checkbox">
                            <Label check>
                              <Input type="checkbox" defaultChecked={canAppAndroid} onChange={e => setCanAppAndroid(!canAppAndroid)}/> Dilengkapi Aplikasi Android
                            </Label>
                          </FormGroup>
                        </div>
                        <div className='col-6'>
                          <FormGroup className="form-checkbox">
                            <Label check>
                              <Input type="checkbox" defaultChecked={canAppIos} onChange={e => setCanAppIos(!canAppIos)}/> Dilengkapi Aplikasi IOS
                            </Label>
                          </FormGroup>
                        </div>
                        <div className='col-6'>
                          <FormGroup className="form-checkbox">
                            <Label check>
                              <Input type="checkbox" defaultChecked={canAppPos} onChange={e => setCanAppPos(!canAppPos)}/> Dilengkapi Sistem POS
                            </Label>
                          </FormGroup>
                        </div>
                        <div className='col-6'>
                          <FormGroup className="form-checkbox">
                            <Label check>
                              <Input type="checkbox" defaultChecked={canOrderTable} onChange={e => setCanOrderTable(!canOrderTable)}/> Pemesanan Take Away
                            </Label>
                          </FormGroup>
                        </div>
                        <div className='col-6'>
                          <FormGroup className="form-checkbox">
                            <Label check>
                              <Input type="checkbox" defaultChecked={canMultiOutlet} onChange={e => setCanMultiOutlet(!canMultiOutlet)}/> Multi Outlets
                            </Label>
                          </FormGroup>
                        </div>
                        <div className='col-6'>
                          <FormGroup className="form-checkbox">
                            <Label check>
                              <Input type="checkbox" defaultChecked={canReservation} onChange={e => setCanReservation(!canReservation)}/> Bisa Reservasi
                            </Label>
                          </FormGroup>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          { spinnerSave ? (
            <Button color="primary" style={{background: (currentCompany.company_color3)}}>
              <Dots color='#fff' />
            </Button>
          ) : (
            <Button color="primary" style={{background: (currentCompany.company_color3)}} onClick={e => actPost(id)}>Simpan</Button>
          )}
          <Button color="secondary" onClick={(e) => toggle(e)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className='row'>
                  <div className='col-sm-2'>
                    <Button className='btn btn-white full-width'>TOTAL {totalCount}</Button>
                  </div>
                  <div className='col-sm-8'>
                    <Input placeholder="Kata Pencarian" value={search} onChange={e => updateSearch(e.target.value)}/>
                  </div>
                  <div className='col-sm-2'>
                    <Button onClick={(e) => toggle(e)} className='btn btn-info full-width' style={{background: (currentCompany.company_color3)}}><i class="fas fa-plus"></i> TAMBAH</Button>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th width='100px' scope="col text-center">Action</th>
                    <th scope="col">Status</th>
                    <th scope="col">Kode</th>
                    <th scope="col">Kategori</th>
                    <th scope="col">Nama Paket</th>
                    <th scope="col">Harga</th>
                    <th scope="col">Diskon</th>
                    <th scope="col">Trial</th>
                    <th scope="col">Dibuat</th>
                  </tr>
                </thead>
                <tbody>
                  { spinner ? (
                    <tr>
                      <td colspan="8" className='text-center'>
                        <div className='loadLoading'><Dots color='#444' /></div>
                      </td>
                    </tr>
                  ) : data && data.length > 0 ? (
                    <>
                      {
                        data.map((u) => {
                          return (
                            <tr key={u.id}>
                              <td width='100px' className="">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                    <DropdownItem href="#pablo" onClick={(e) => deletePost(u.id)}>
                                      Hapus
                                    </DropdownItem>
                                    <DropdownItem onClick={(e) => toggle(u.id)}>
                                      Ubah
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                              <td>
                                <div style={styles.status(u.status)}>
                                  {u.status ? 'Aktif' : 'Tidak Aktif'}
                                </div>
                              </td>
                              <td>
                                <span className="mb-0 text-sm">
                                  {u.code}
                                </span>
                              </td>
                              <td>{u.category}</td>
                              <td>{u.title}</td>
                              <td>
                                <b>{numberFormat(u.price)}</b>
                              </td>
                              <td>
                                <b>{u.discount ? u.discount : ''}</b>%
                              </td>
                              <td>{u.trial ? 'Paket Trial' : 'Reguler'}</td>
                              <td>{u.created_at}</td>
                            </tr>
                          )
                        })
                      }
                    </>
                  ) : (
                    <div className='col col-md-12 input-field2'>
                      <span>Data Tidak Ditemukan</span>
                    </div>
                  )}
                </tbody>
              </Table>
              {paging ? (
                <div class='col'>
                  <div onClick={loadMoreData} class='load-more-btn'>
                    {loadMore ? (
                      <center><Dots color='#444' /><br/></center>
                    ) : null}
                    SELANJUTNYA
                  </div>
                </div>
              ) : null}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default PackagesIndex;


const styles: StyleSheet = {
  status: (status) => ({
    fontSize: 11,
    color: status === true ? 'green' : 'red' ,
    paddingBottom: 2,
    borderRadius: 5,
  })
}