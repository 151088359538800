import React, { useEffect, useState } from 'react';
import API from '../../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../../ReactSession';
import { useHistory, useParams } from "react-router-dom";
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import DatePicker from "reactstrap-date-picker";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Input,
  Modal,
  ModalHeader, ModalBody, ModalFooter,
  Button,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
  Label,
  Col, CardBody, CardTitle
} from "reactstrap";

import Header from "components/Headers/Header.js";

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const PaymentShow = () => {
  const currentUser = ReactSession.get("current_user");
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const history = useHistory();
  const {id} = useParams();

  const [status, setStatus] = useState('pending');
  const [imageFile, setImageFile] = useState();
  const [imageSrc, setImageSrc] = useState();
  const [accountId, setAccountId] = useState('');
  const [paidDate, setPaidDate] = useState('');
  const [spinnerSave, setSpinnerSave] = useState(false);

  const [paymentNumbers, setPaymentNumbers] = useState([]);
  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(true);

  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  }

  const loadDataAccount = () => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {by_use_payment: true, by_company_id: currentCompany.id, by_status: true, pagination: false }};
    API.get(`accounts`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setPaymentNumbers(data);
      })
  }

  const loadData = () => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {key: '', include: 'company, account' }};
    API.get(`claim_moneys/${id}`, config)
      .then(res => {
        const data = res.data.data;
        setData(data);
        setStatus(data.status);
        setImageSrc(data.image_url);
        setImageFile('');
        setAccountId(data.account_id);
        setPaidDate(data.paid_date);
      })
  }

  const handleImageSelect = (e) => {
    setImageSrc(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setImageFile(files);
  }

  const actPost = (id) => {
    setSpinnerSave(true);
    const config = {
      headers: { Authorization: `Bearer ${token}` } 
    };

    const fd = new FormData();
    if(imageFile){
      fd.append('image', imageFile, imageFile.name);
    }
    fd.append('status', status);
    fd.append('account_id', accountId);
    fd.append('paid_date', paidDate);
    fd.append('include', 'company, account');

    if(id){
      API.put(`claim_moneys/${id}`, fd, config )
        .then(res => {
          setStatus('pending');
          setImageSrc('');
          setImageFile('');
          setAccountId('');
          setPaidDate('');
          setSpinnerSave(false);
          setData(res.data.data);
          toggle();
        })
        .catch(error => {
          setSpinnerSave(false);
          setVisible(true);
          if(error === undefined){
            setErrorMessage('Gagal, Terjadi kesalahan di server')
          }else{
            setErrorMessage(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }
  }

  const statusCollectionPayment = [
    {'name':'Pemeriksaan', 'value': 'pending'},
    {'name':'Dibayar', 'value':'paid'},
    {'name':'Dibatalkan', 'value':'cancelled'}
  ]

  useEffect(() => {
    if(token === undefined){
      history.push("/")
    }

    loadData('');
    loadDataAccount('');

  },[])

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Kelola Data Pencairan Saldo</ModalHeader>
        <ModalBody>
          <Alert color="warning" isOpen={visible} toggle={onDismiss}>
            {errorMessage}
          </Alert>
          <Form role="form">
            <FormGroup className="mb-3">
              <FormGroup className="form-img-select">
                <div className='avatar-user'>
                  <ImageUpload
                    handleImageSelect={handleImageSelect}
                    imageSrc={imageSrc}
                    setImageSrc={setImageSrc}
                    style={{
                      width: 120,
                      height: 120
                    }}
                  />
                </div>
              </FormGroup>

              <div className='row'>
                <div className='col-6'>
                  <FormGroup>
                    <Label className='label-form-group'>Tanggal Pembayaran</Label>
                    <DatePicker options={{ yearRange: 70}} id= "example-datepicker3" value={paidDate} onChange={e => setPaidDate(e)} />
                  </FormGroup>
                </div>
                <div className='col-6'>
                  <Label className='label-form-group'>Akun Pengiriman</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <Input type="select" value={accountId} onChange={e => setAccountId(e.target.value)}>
                      <option value=''>Pilih Akun</option>
                      {
                        paymentNumbers.map((c) => {
                          return (
                            <option key={c.id} value={c.id}>{c.name+'-'+c.code}</option>
                          )
                        })
                      }
                    </Input>
                  </InputGroup>
                </div>
                { currentUser && (currentUser.role_access === 'finance' || currentUser.role_access === 'admin' || currentUser.type === 'Company' || currentUser.type === 'Adminmaster') ? (
                  <div className='col-12'>
                    <Label className='label-form-group'>Status Pembayaran</Label>
                    <InputGroup className="input-group-alternative mb-20">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-star" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="select" value={status} onChange={e => setStatus(e.target.value)}>
                        <option value=''>Pilih Status</option>
                        {
                          statusCollectionPayment.map((c) => {
                            return (
                              <option key={c.value} value={c.value}>{c.name}</option>
                            )
                          })
                        }
                      </Input>
                    </InputGroup>
                  </div>
                ) : null }
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          { spinnerSave ? (
            <Button color="primary" style={{background: (currentCompany.company_color3)}}>
              <Dots color='#fff' />
            </Button>
          ) : (
            <Button color="primary" style={{background: (currentCompany.company_color3)}} onClick={e => actPost(id)}>Simpan</Button>
          )}
          <Button color="secondary" onClick={(e) => toggle(e)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className='row'>
                  <div className='col-sm-12'>
                    <h2>
                      FAKTUR {data.code}
                      <UncontrolledDropdown>
                        <DropdownToggle
                          className="btn-icon-only text-light"
                          href="#pablo"
                          role="button"
                          size="sm"
                          color=""
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fas fa-ellipsis-v" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right>
                          <DropdownItem href="#" onClick={(e) => toggle(data.id)}>
                            Ubah
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </h2>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <div className='col-md-12 table-responsive' style={{padding: 0}}>
                  <table className="align-items-center table-flush table" responsive>
                    <tbody>
                      <tr>
                        <td width='30%'>Pembayaran</td>
                        <td>
                          <div style={styles.status(data.status)}>
                            {data.status_txt}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Kode Faktur</td>
                        <td>
                          <span className="mb-0 text-sm">
                            {data.code}
                          </span> 
                        </td>
                      </tr>
                      <tr>
                        <td>Nama User</td>
                        <td>{data.full_name} ({data.username})</td>
                      </tr>
                      <tr>
                        <td>Nilai</td>
                        <td>
                          <b>{numberFormat(data.virtual_money)}</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Dibayar Dari Akun</td>
                        <td>{data.account ? `${data.account.name}-${data.account.code}` : '-'}</td>
                      </tr>
                      <tr>
                        <td>Transfer Ke</td>
                        <td>{data.payment_number}</td>
                      </tr>
                      {
                        data.paid_date ? (
                          <tr>
                            <td>Tanggal Pembayaran</td>
                            <td>{data.paid_date}</td>
                          </tr>
                        ) : null
                      }
                      <tr>
                        <td>Tanggal Dibuat</td>
                        <td>{data.created_at}</td>
                      </tr>
                      <tr>
                        <td>Deskripsi</td>
                        <td>{data.title}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default PaymentShow;


const styles: StyleSheet = {
  status: (status) => ({
    fontSize: 11,
    color: status === 'pending' ? 'orange' : status === 'paid' ? 'green' : status === 'process' ? '#11cdef' : 'red' ,
    paddingBottom: 2,
    borderRadius: 5,
  })
}