import React, { useEffect, useState } from 'react';
import API from '../../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../../ReactSession';
import { useHistory } from "react-router-dom";
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import DatePicker from "reactstrap-date-picker";
import Select from 'react-select';

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Input,
  Modal,
  ModalHeader, ModalBody, ModalFooter,
  Button,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
  Label
} from "reactstrap";

import Header from "components/Headers/Header.js";

const StaffIndex = () => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const history = useHistory();

  const [id, setId] = useState('');
  const [roleAccess, setRoleAccess] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [gender, setGender] = useState('');
  const [birthday, setBirthday] = useState('');
  const [phone1, setPhone1] = useState('');
  const [phone2, setPhone2] = useState('');
  const [address, setAddress] = useState('');
  const [addressCode, setAddressCode] = useState('');
  const [fullAddress, setFullAddress] = useState('');
  const [editAddress, setEditAddress] = useState(false);
  const [verified, setVerified] = useState(false);
  const [membership, setMembership] = useState(false);
  const [domain, setDomain] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [waKeyType, setWaKeyType] = useState('');
  const [waKey, setWaKey] = useState('');
  const [staffId, setStaffId] = useState('');
  const [staffCommisionRepeatOrder, setStaffCommisionRepeatOrder] = useState(0);
  const [outletIds, setOutletIds] = useState([]);

  const [imageFile, setImageFile] = useState();
  const [imageSrc, setImageSrc] = useState();
  
  const [queryDistrict, setQueryDistrict] = useState('');
  const [districts, setDistricts] = useState([]);
  const [outlets, setOutlets] = useState([]);

  const [companies, setCompanies] = useState([]);
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(1);
  const [paging, setPaging] = useState(true);
  const [search, setSearch] = useState('');
  const [spinner, setSpinner] = useState(true);
  const [loadMore, setLoadMore] = useState(true);
  const [loadMoreButton, setLoadMoreButton] = useState(false);
  const [spinnerSave, setSpinnerSave] = useState(false);

  const roleAccesses = [
    {name: 'Admin', value: 'admin'},
    {name: 'Finance', value: 'finance'},
    {name: 'Inventory', value: 'inventory'},
    {name: 'Customer Service', value: 'cs'}
  ]

  const [modal, setModal] = useState(false);
  const toggle = (id) => {
    if(id){
      let dt = data.filter(o => o.id === id)[0]
      if(dt){
        setId(dt.id);
        setUsername(dt.username);
        setEmail(dt.email);
        setFullName(dt.full_name);
        setGender(dt.gender);
        setBirthday(dt.birthday);
        setPhone1(dt.phone1);
        setPhone2(dt.phone2);
        setAddress(dt.address);
        setAddressCode(dt.address_code);
        setFullAddress(`${dt.district}, ${dt.city}, ${dt.province}`);
        setDomain(dt.domain);
        setMembership(dt.membership);
        setVerified(dt.verified);
        setCompanyId(dt.company_id);
        setRoleAccess(dt.role_access);
        setWaKeyType(dt.wa_key_type);
        setWaKey(dt.wa_key);
        setStaffId(dt.staff_id);
        setStaffCommisionRepeatOrder(dt.staff_commision_repeat_order);
        setImageSrc(dt.avatar_url);
        setImageFile('');
        if(outlets && dt.outlet_ids){
          setOutletIds( outlets.filter((b) => dt.outlet_ids.includes(b.id.toString())) );
        }else{
          setOutletIds([]);
        }
      }else{
        setId('');
        setUsername('');
        setEmail('');
        setFullName('');
        setGender('');
        setBirthday('');
        setPhone1('');
        setPhone2('');
        setAddress('');
        setAddressCode('');
        setFullAddress();
        setDomain('');
        setMembership(false);
        setVerified(false);
        setCompanyId('');
        setRoleAccess('');
        setWaKeyType('');
        setWaKey('');
        setStaffId('');
        setStaffCommisionRepeatOrder(0);
        setImageSrc('');
        setImageFile('');
        setOutletIds([]);
      }
    }else{
      setId('');
      setUsername('');
      setEmail('');
      setFullName('');
      setGender('');
      setBirthday('');
      setPhone1('');
      setPhone2('');
      setAddress('');
      setAddressCode('');
      setFullAddress();
      setDomain('');
      setMembership(false);
      setVerified(false);
      setCompanyId('');
      setRoleAccess('');
      setWaKeyType('');
      setWaKey('');
      setStaffId('');
      setStaffCommisionRepeatOrder(0);
      setImageSrc('');
      setImageFile('');
      setOutletIds([]);
    }
    setModal(!modal);
  }

  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleImageSelect = (e) => {
    setImageSrc(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setImageFile(files);
  }

  const handleOnSearch = (string, results) => {
    console.log(string, results)
    findDistrict(string)
  }

  const handleOnHover = (result) => {
    console.log(result)
  }

  const handleOnSelect = (item) => {
    setAddressCode(item.id)
  }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const formatResult = (item) => {
    return (<div dangerouslySetInnerHTML={{__html: '<span>'+item.name+'</span>'}}></div>); //To format result as html
    // return item;
   // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
  }

  const findDistrict = (query) => {
    setQueryDistrict(query);
    console.log('find district '+query)
    API.get(`regions`, {params: {key: query}} )
      .then(res => {
        const data = res.data.data;
        setDistricts(data);
        console.log(data)
      })
  }

  const onChangeAddress = () => {
    editAddress === true ? setEditAddress(false) : setEditAddress(true)
  }

  const actPost = (id) => {
    setSpinnerSave(true);
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };

    const fd = new FormData();
    if(imageFile){
      fd.append('avatar', imageFile, imageFile.name);
    }
    fd.append('type', 'Staff');
    fd.append('company_id', companyId);
    fd.append('username', username);
    fd.append('email', email);
    fd.append('password', password);
    fd.append('full_name', fullName);
    fd.append('gender', gender);
    fd.append('birthday', birthday);
    fd.append('phone1', phone1);
    fd.append('phone2', phone2);
    fd.append('address', address);
    fd.append('address_code', addressCode);
    fd.append('domain', domain);
    fd.append('membership', membership);
    fd.append('verified', verified);
    fd.append('role_access', roleAccess);
    fd.append('wa_key_type', waKeyType);
    fd.append('wa_key', waKey);
    fd.append('staff_id', staffId);
    fd.append('staff_commision_repeat_order', staffCommisionRepeatOrder);
    fd.append('outlet_ids', outletIds.map((e) => e.id));
    fd.append('include', 'company');
    
    if(id){
      API.put(`users/${id}`, fd, config )
        .then(res => {
          setId('');
          setUsername('');
          setEmail('');
          setFullName('');
          setGender('');
          setBirthday('');
          setPhone1('');
          setPhone2('');
          setAddress('');
          setAddressCode('');
          setFullAddress();
          setDomain('');
          setMembership(false);
          setVerified(false);
          setCompanyId('');
          setRoleAccess('');
          setWaKeyType('');
          setWaKey('');
          setStaffId('');
          setStaffCommisionRepeatOrder(0);
          setImageSrc('');
          setImageFile('');
          setOutletIds([]);
          const dt = res.data.data;
          const oldData = data;
          const new_data = oldData.findIndex((od) => od.id === dt.id)
          if (new_data !== -1){
            oldData[new_data] = dt
            setData(oldData);
          }
          // loadData('');
          setSpinnerSave(false);
          toggle();
        })
        .catch(error => {
          setSpinnerSave(false);
          setVisible(true);
          if(error === undefined){
            setErrorMessage('Gagal, Terjadi kesalahan di server')
          }else{
            setErrorMessage(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }else{
      API.post('users', fd, config )
        .then(res => {
          setId('');
          setUsername('');
          setEmail('');
          setFullName('');
          setGender('');
          setBirthday('');
          setPhone1('');
          setPhone2('');
          setAddress('');
          setAddressCode('');
          setFullAddress();
          setDomain('');
          setMembership(false);
          setVerified(false);
          setCompanyId('');
          setRoleAccess('');
          setWaKeyType('');
          setWaKey('');
          setStaffId('');
          setStaffCommisionRepeatOrder(0);
          setImageSrc('');
          setImageFile('');
          setOutletIds([]);
          loadData('');
          setSpinnerSave(false);
          toggle();
        })
        .catch(error => {
          setSpinnerSave(false);
          setVisible(true);
          if(error === undefined){
            setErrorMessage('Gagal, Terjadi kesalahan di server')
          }else{
            setErrorMessage(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }
    
  }

  const loadData = (keyword) => {    
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {key: keyword, by_user_type: 'Staff', include: 'company' }};
    API.get(`users`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setData(data)
        setSpinner(false)
        setLoadMore(false);
        setTotalCount(res.data.meta.pagination.total_entries);
        if(res.data.meta.pagination.total_pages <= 1){
          setPaging(false);
          console.log('No paging')
        }else{
          setPaging(true);
          console.log('Yes paging')
        }
      })

    const config2 = { headers: { Authorization: `Bearer ${token}`}, params: {by_verified: true, key: keyword, by_user_type: 'Company', pagination: false  }};
    API.get(`users`, config2)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setCompanies(data);
      })
  }

  const loadMoreData = () => {
    setLoadMoreButton(true);
    setLoadMore(true);
    setOffset(offset+1)
    let offset_page = offset+1
    const config = { headers: { Authorization: `Bearer ${token}` }, params: { page: offset_page, key: '', by_user_type: 'Staff', include: 'company'} };
    
    API.get(`users`, config)
      .then(res => {
        setLoadMore(false);
        const data2 = res.data.data;
        setData([...data, ...data2]);
        if(res.data.meta.pagination.total_pages === offset_page){
          setPaging(false);
        }else{
          setLoadMoreButton(false);
        }
      })
  }

  const updateSearch = (search) => {
    setOffset(1)
    if(search === '' ){
      setSearch('');
    }else{
      setSearch(search);
    }
    setLoadMoreButton(false);
    loadData(search);
  }

  const clearSearch = (search) => {
    setOffset(1);
    updateSearch('');
    loadData('');
    setLoadMoreButton(false);
  }

  const changeVerified = (id) => {
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };
    
    API.put(`users/${id}/verified`, {status: ''}, config )
      .then(res => {
        const data = res.data.data;
        alert(`Berhasil di ${data.verified ? 'Aktifkan' : 'NonAktif'}`);
        loadData('');
      })
      .catch(error => {
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const setCompanyLoad = (company_id) => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {by_status: true, by_company_id: company_id, pagination: false }};
    API.get(`outlets`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setOutlets(data);
      })
  }

  useEffect(() => {
    if(token === undefined){
      history.push("/")
    }

    setSearch('');
    loadData('');

    if(currentUser && currentUser.type === 'Company'){
      setCompanyLoad(currentUser.id);
    }else if (currentUser && currentUser.type === 'Staff'){
      setCompanyLoad(currentUser.company_id)
    }else{
      setCompanyLoad('')
    }
  
  },[])

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Kelola Staff</ModalHeader>
        <ModalBody>
          <Alert color="warning" isOpen={visible} toggle={onDismiss}>
            {errorMessage}
          </Alert>
          <Form role="form">
            <FormGroup className="mb-3">
              <FormGroup className="form-img-select">
                <div class='avatar-user'>
                  <ImageUpload
                    handleImageSelect={handleImageSelect}
                    imageSrc={imageSrc}
                    setImageSrc={setImageSrc}
                    style={{
                      width: 120,
                      height: 120
                    }}
                  />
                </div>
              </FormGroup>

              <div className='row'>
                { currentUser.type === 'Adminmaster' ? (
                  <div className='col-sm-12'>
                    <Label className='label-form-group'>Perusahaan</Label>
                    <InputGroup className="input-group-alternative mb-20">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-building" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="select" value={companyId} onChange={e => setCompanyId(e.target.value)}>
                        <option value=''>Pilih Perusahaan</option>
                        {
                          companies.map((c) => {
                            return (
                              <option key={c.id} value={c.id}>{c.full_name}</option>
                            )
                          })
                        }
                      </Input>
                    </InputGroup>
                  </div>
                ) : null}

                { (currentUser.last_package && currentUser.last_package.can_multi_outlet == true ) && outlets && outletIds ? (
                  <div className='col-12'>
                    <FormGroup>
                      <Label className='label-form-group'>Pilih Outlet</Label>
                      <Select
                        isMulti
                        defaultValue={outletIds}
                        onChange={setOutletIds}
                        options={outlets}
                      />
                    </FormGroup>
                  </div>
                ) : null }

                <div className='col-sm-6'>
                  <Label className='label-form-group'>Role Access</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-building" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input type="select" value={roleAccess} onChange={e => setRoleAccess(e.target.value)}>
                      <option value=''>Pilih Akses Role</option>
                      {
                        roleAccesses.map((c) => {
                          return (
                            <option key={c.value} value={c.value}>{c.name}</option>
                          )
                        })
                      }
                    </Input>
                  </InputGroup>
                </div>
                {/* <div className='col-6'>
                  <Label className='label-form-group'>Username</Label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-user" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Username"
                      value={username} onChange={e => setUsername(e.target.value)}
                    />
                  </InputGroup>
                </div> */}
                <div className='col-6'>
                  <Label className='label-form-group'>Alamat Email</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        @
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      value={email} onChange={e => setEmail(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-12'>
                  <Label className='label-form-group'>Nama Lengkap</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-bookmark" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Nama Lengkap"
                      value={fullName} onChange={e => setFullName(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <Label className='label-form-group'>Kata Sandi</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-key" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type='password'
                      placeholder="Kata Sandi"
                      value={password} onChange={e => setPassword(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <Label className='label-form-group'>No.Kontak/WA</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-phone" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="No.Kontak 1"
                      value={phone1} onChange={e => setPhone1(e.target.value)}
                    />
                  </InputGroup>
                </div>
                {/* <div className='col-6'>
                  <Label className='label-form-group'>No.Kontak 2</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-phone" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="No.Kontak 2"
                      value={phone2} onChange={e => setPhone2(e.target.value)}
                    />
                  </InputGroup>
                </div> */}
                <div className='col-6'>
                  <Label className='label-form-group'>Jenis Kelamin</Label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-info" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input type="select" value={gender} onChange={e => setGender(e.target.value)}>
                      <option value=''>Pilih Jenis Kelamin</option>
                      <option value='Laki-Laki'>Laki-Laki</option>
                      <option value='Perempuan'>Perempuan</option>
                    </Input>
                  </InputGroup>
                </div>

                <div className='col-6'>
                  <FormGroup>
                    <Label className='label-form-group'>Tanggal Lahir</Label>
                    <DatePicker options={{ yearRange: 70}} id= "example-datepicker" value={birthday} onChange={e => setBirthday(e)} />
                  </FormGroup>
                </div>

                { roleAccess === 'cs' ? (
                  <>
                    <div className='col-6'>
                      <Label className='label-form-group'>Komisi % Repet Order User</Label>
                      <InputGroup className="input-group-alternative mb-20">
                        <Input
                          type='Number'
                          placeholder="% Komisi Repeat Order"
                          value={staffCommisionRepeatOrder} onChange={e => setStaffCommisionRepeatOrder(e.target.value)}
                        />
                      </InputGroup>
                    </div>
                    <div className='col-6'>
                      <Label className='label-form-group'>WA Key Provider</Label>
                      <InputGroup className="input-group-alternative">
                        <Input type="select" value={waKeyType} onChange={e => setWaKeyType(e.target.value)}>
                          <option value=''>Pilih WA Provider</option>
                          <option value='wanotif'>WaNotif</option>
                          <option value='wonatif'>WoNotif</option>
                        </Input>
                      </InputGroup>
                    </div>
                    <div className='col-12'>
                      <Label className='label-form-group'>WA Api Key ( <a href="https://wanotif.id/" target="_blank">Link 3Party</a> )</Label>
                      <InputGroup className="input-group-alternative mb-20">
                        <Input
                          placeholder="WA Api Key"
                          value={waKey} onChange={e => setWaKey(e.target.value)}
                        />
                      </InputGroup>
                    </div>
                  </>
                ) : null}

                <div className='col-6'>
                  <FormGroup className="form-checkbox">
                    <Label check>
                      <Input type="checkbox" defaultChecked={verified} onChange={e => setVerified(!verified)}/> Aktifkan
                    </Label>
                  </FormGroup>
                </div>
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          { spinnerSave ? (
            <Button color="primary" style={{background: (currentCompany.company_color3)}}>
              <Dots color='#fff' />
            </Button>
          ) : (
            <Button color="primary" style={{background: (currentCompany.company_color3)}} onClick={e => actPost(id)}>Simpan</Button>
          )}
          <Button color="secondary" onClick={(e) => toggle(e)}>Cancel</Button>
        </ModalFooter>
      </Modal>
      
      <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className='row'>
                  { currentUser && currentUser.role_access === 'admin' ? (
                    <>
                      <div className='col-sm-2'>
                        <Button className='btn btn-white full-width'>TOTAL {totalCount}</Button>
                      </div>
                      <div className='col-sm-10'>
                        <Input placeholder="Kata Pencarian" value={search} onChange={e => updateSearch(e.target.value)}/>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='col-sm-2'>
                        <Button className='btn btn-white full-width'>TOTAL {totalCount}</Button>
                      </div>
                      <div className='col-sm-8'>
                        <Input placeholder="Kata Pencarian" value={search} onChange={e => updateSearch(e.target.value)}/>
                      </div>
                      <div className='col-sm-2'>
                        <Button onClick={(e) => toggle(e)} className='btn btn-info full-width' style={{background: (currentCompany.company_color3)}}><i class="fas fa-plus"></i> TAMBAH</Button>
                      </div>
                    </>
                  )}
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th width='100px' scope="col text-center">Action</th>
                    <th scope="col">Status</th>
                    <th scope="col">Nama Staff</th>
                    <th scope="col">Akses Role</th>
                    { currentUser.type === 'Adminmaster' ? (
                      <th scope="col">Perusahaan</th>
                    ) : null}
                    <th scope="col">Kontak</th>
                    <th scope="col">Email</th>
                    <th scope="col">Terdaftar</th>
                  </tr>
                </thead>
                <tbody>
                  { spinner ? (
                    <tr>
                      <td colspan="7" className='text-center'>
                        <div className='loadLoading'><Dots color='#444' /></div>
                      </td>
                    </tr>
                  ) : data && data.length > 0 ? (
                    <>
                      {
                        data.map((u) => {
                          return (
                            <tr key={u.id}>
                              <td width='100px'>
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#pablo"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                    <DropdownItem href={`/admin/staff/${u.id}`}>
                                      Lihat
                                    </DropdownItem>
                                    { currentUser && currentUser.role_access !== 'admin' ? (
                                      <DropdownItem onClick={(e) => toggle(u.id)}>
                                        Ubah
                                      </DropdownItem>
                                    ) : null }
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                              <td>
                                <div style={styles.status(u.verified)}>
                                  {u.verified ? (
                                    <i class="fa-big fa fa-power-off" onClick={(e) => changeVerified(u.id)}></i>
                                  ) : (
                                    <i class="fa-big fa fa-power-off" onClick={(e) => changeVerified(u.id)}></i>
                                  )}
                                </div>
                              </td>
                              <th scope="row">
                                <Media className="align-items-center">
                                  <a className="avatar rounded-circle mr-3" href="#" onClick={(e) => e.preventDefault()}>
                                    <img alt="..." src={u.avatar_url} />
                                  </a>
                                  <Media>
                                    <span className="mb-0 text-sm">
                                      {u.full_name}
                                    </span>
                                  </Media>
                                </Media>  
                              </th>
                              <td>
                                {u.role_access_txt ? u.role_access_txt : u.role_access}
                              </td>
                              { currentUser.type === 'Adminmaster' ? (
                                <td>[{u.company_id}] {u.company.full_name}</td>
                              ) : null}
                              <td>
                                {u.phone1}
                              </td>
                              <td>
                                {u.email}
                              </td>
                              <td>{u.created_at}</td>
                            </tr>
                          )
                        })
                      }
                    </>
                  ) : (
                    <div className='col col-md-12 input-field2'>
                      <span>Data Tidak Ditemukan</span>
                    </div>
                  )}
                </tbody>
              </Table>
              {paging ? (
                <div class='col'>
                  <div onClick={loadMoreData} class='load-more-btn'>
                    {loadMore ? (
                      <center><Dots color='#444' /><br/></center>
                    ) : null}
                    SELANJUTNYA
                  </div>
                </div>
              ) : null}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default StaffIndex;

const styles: StyleSheet = {
  status: (status) => ({
    fontSize: 11,
    color: status === true ? '#1963da' : '#ccc',
    paddingBottom: 2,
    borderRadius: 5,
  })
}

