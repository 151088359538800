import React, { useEffect, useState } from 'react';
import API from '../../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../../ReactSession';
import { Link, useHistory } from "react-router-dom";
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Input,
  Modal,
  ModalHeader, ModalBody, ModalFooter,
  Button,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
  Label
} from "reactstrap";

import Header from "components/Headers/Header.js";

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const AccountNumbers = () => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const history = useHistory();

  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [code, setCode] = useState('');
  const [category, setCategory] = useState('');
  const [ballance, setBallance] = useState(0);
  const [paymentMethodShop, setPaymentMethodShop] = useState(false);

  const [companies, setCompanies] = useState([]);
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(1);
  const [paging, setPaging] = useState(true);
  const [search, setSearch] = useState('');
  const [spinner, setSpinner] = useState(true);
  const [loadMore, setLoadMore] = useState(true);
  const [loadMoreButton, setLoadMoreButton] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [spinnerSave, setSpinnerSave] = useState(false);
  const [spendingCategories, setCategories] = useState([]);

  const [modal, setModal] = useState(false);
  const toggle = (id) => {
    if(id){
      let dt = data.filter(o => o.id === id)[0]
      if(dt){
        setId(dt.id);
        setName(dt.name);
        setCompanyId(dt.user_id);
        setCode(dt.code);
        setCategory(dt.category);
        setBallance(dt.ballance);
        setPaymentMethodShop(dt.payment_method_shop);
      }else{
        setId('');
        setName('');
        setCompanyId('');
        setCode('');
        setCategory('');
        setBallance('');
        setPaymentMethodShop(false);
      }
    }else{
      setId('');
      setName('');
      setCompanyId('');
      setCode('');
      setCategory('');
      setBallance('');
      setPaymentMethodShop(false);
    }
    setModal(!modal);
  }

  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);
  const [errorMessage, setErrorMessage] = useState('');

  const actPost = (id) => {
    setSpinnerSave(true);
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };

    const fd = new FormData();
    fd.append('name', name);
    fd.append('user_id', companyId);
    fd.append('code', code);
    fd.append('category', category);
    fd.append('ballance', ballance);
    fd.append('payment_method_shop', paymentMethodShop);
    
    if(id){
      API.put(`accounts/${id}`, fd, config )
        .then(res => {
          setId('');
          setName('');
          setCompanyId('');
          setCode('');
          setCategory('');
          setBallance('');
          setPaymentMethodShop(false);
          loadData('');
          setSpinnerSave(false);
          toggle();
        })
        .catch(error => {
          setSpinnerSave(false);
          setVisible(true);
          if(error === undefined){
            setErrorMessage('Gagal, Terjadi kesalahan di server')
          }else{
            setErrorMessage(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }else{
      API.post('accounts', fd, config )
        .then(res => {
          setId('');
          setName('');
          setCompanyId('');
          setCode('');
          setCategory('');
          setBallance('');
          setPaymentMethodShop(false);
          loadData('');
          setSpinnerSave(false);
          toggle();
        })
        .catch(error => {
          setSpinnerSave(false);
          setVisible(true);
          if(error === undefined){
            setErrorMessage('Gagal, Terjadi kesalahan di server')
          }else{
            setErrorMessage(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }
    
  }

  const deletePost = (id) => {
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };
    
    API.delete(`accounts/${id}`, config )
      .then(res => {
        loadData('');
      })
      .catch(error => {
        setVisible(true);
        if(error === undefined){
          setErrorMessage('Gagal, Terjadi kesalahan di server')
        }else{
          setErrorMessage(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const loadData = (keyword) => {    
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {key: keyword, include: 'company'  }};
    API.get(`accounts`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setData(data)
        setSpinner(false)
        setLoadMore(false);
        setTotalCount(res.data.meta.pagination.total_entries);
        if(res.data.meta.pagination.total_pages <= 1){
          setPaging(false);
          console.log('No paging')
        }else{
          setPaging(true);
          console.log('Yes paging')
        }
      })
    
    const config2 = { headers: { Authorization: `Bearer ${token}`}, params: {by_verified: true, key: keyword, by_user_type: 'Company', pagination: false  }};
    API.get(`users`, config2)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setCompanies(data);
      })
  }

  const loadMoreData = () => {
    setLoadMoreButton(true);
    setLoadMore(true);
    setOffset(offset+1)
    let offset_page = offset+1
    const config = { headers: { Authorization: `Bearer ${token}` }, params: { page: offset_page, key: '', include: 'company' } };
    
    API.get(`accounts`, config)
      .then(res => {
        setLoadMore(false);
        const data2 = res.data.data;
        setData([...data, ...data2]);
        if(res.data.meta.pagination.total_pages === offset_page){
          setPaging(false);
        }else{
          setLoadMoreButton(false);
        }
      })
  }

  const updateSearch = (search) => {
    setOffset(1)
    if(search === '' ){
      setSearch('');
    }else{
      setSearch(search);
    }
    setLoadMoreButton(false);
    loadData(search);
  }

  const clearSearch = (search) => {
    setOffset(1);
    updateSearch('');
    loadData('');
    setLoadMoreButton(false);
  }

  const categories = [
    {'name': 'Nomor Rekening Bank', 'value': 'payment_number'},
    {'name': 'E-Wallet', 'value': 'ewallet'},
    {'name': 'Wallet', 'value': 'wallet'},
    {'name': 'Vendor', 'value': 'vendor'},
    {'name': 'Nomor Rekening Karyawan', 'value': 'employee_number'},
    {'name': 'Lain-Lain', 'value': 'other'}
  ]

  useEffect(() => {
    if(token === undefined){
      history.push("/")
    }

    setCategories(categories);
    setSearch('');
    loadData('');
 
  },[])

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Kelola Akun</ModalHeader>
        <ModalBody>
          <Alert color="warning" isOpen={visible} toggle={onDismiss}>
            {errorMessage}
          </Alert>
          <Form role="form">
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-credit-card" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Nama Akun"
                  value={name} onChange={e => setName(e.target.value)}
                />
              </InputGroup>
              <br/>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    NO
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Nomor Akun"
                  value={code} onChange={e => setCode(e.target.value)}
                />
              </InputGroup>
              <br/>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    RP
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Ballance"
                  value={ballance} onChange={e => setBallance(e.target.value)}
                />
              </InputGroup>
              <br/>
              <InputGroup className="input-group-alternative mb-20">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-list" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input type="select" value={category} onChange={e => setCategory(e.target.value)}>
                  <option value=''>Pilih Kategori</option>
                  {
                    categories.map((c) => {
                      return (
                        <option key={c.id} value={c.id}>{c.name}</option>
                      )
                    })
                  }
                </Input>
              </InputGroup>
              <br/>
              { currentUser && currentUser.type === 'Adminmaster' ? (
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-building" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input type="select" value={companyId} onChange={e => setCompanyId(e.target.value)}>
                    <option value=''>Pilih Perusahaan</option>
                    {
                      companies.map((c) => {
                        return (
                          <option key={c.id} value={c.id}>{c.full_name}</option>
                        )
                      })
                    }
                  </Input>
                  {/* <Input
                    placeholder="ID Perusahaan"
                    value={companyId} onChange={e => setCompanyId(e.target.value)}
                  /> */}
                </InputGroup>
              ) : null }
            </FormGroup>
            <FormGroup className="form-checkbox">
              <Label check>
                <Input type="checkbox" defaultChecked={paymentMethodShop} onChange={e => setPaymentMethodShop(!paymentMethodShop)}/> Jadikan Pilihan Pembayaran Toko
              </Label>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          { spinnerSave ? (
            <Button color="primary" style={{background: (currentCompany.company_color3)}}>
              <Dots color='#fff' />
            </Button>
          ) : (
            <Button color="primary" style={{background: (currentCompany.company_color3)}} onClick={e => actPost(id)}>Simpan</Button>
          )}
          <Button color="secondary" onClick={(e) => toggle(e)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className='row'>
                  <div className='col-sm-2'>
                    <Button className='btn btn-white full-width'>TOTAL {totalCount}</Button>
                  </div>
                  <div className='col-sm-8'>
                    <Input placeholder="Kata Pencarian" value={search} onChange={e => updateSearch(e.target.value)}/>
                  </div>
                  <div className='col-sm-2'>
                    <Button onClick={(e) => toggle(e)} className='btn btn-info full-width' style={{background: (currentCompany.company_color3)}}><i class="fas fa-plus"></i> TAMBAH</Button>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th width='100px' scope="col text-center">Action</th>
                    <th scope="col">ID</th>
                    <th scope="col">Nama Akun</th>
                    <th scope="col">Nomor</th>
                    <th scope="col">Pembayaran Online</th>
                    <th scope="col">Kategori</th>
                    <th scope="col">Ballance</th>
                    { currentUser && currentUser.type === 'Adminmaster' ? (
                      <th scope="col">Perusahaan</th>
                    ) : null }
                    <th scope="col">Dibuat</th>
                  </tr>
                </thead>
                <tbody>
                  { spinner ? (
                    <tr>
                      <td colspan="8" className='text-center'>
                        <div className='loadLoading'><Dots color='#444' /></div>
                      </td>
                    </tr>
                  ) : data && data.length > 0 ? (
                    <>
                      {
                        data.map((u) => {
                          return (
                            <tr key={u.id}>
                              <td width='100px'>
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#pablo"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                    { currentUser.type === 'Adminmaster' || currentUser.type === 'Company' ? (
                                       <DropdownItem href="#pablo" onClick={(e) => deletePost(u.id)}>
                                        Hapus
                                      </DropdownItem>
                                    ) : null } 
                                    <DropdownItem onClick={(e) => toggle(u.id)}>
                                      Ubah
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                              <td>{u.id}</td>
                              <td>
                                <Link to={'transactions/'+u.id}>
                                  <span className="mb-0 text-sm">
                                    {u.name}
                                  </span>
                                </Link>    
                              </td>
                              <td>{u.code}</td>
                              <td>{u.payment_method_shop ? 'Ya' : 'Tidak'}</td>
                              <td>{u.category}</td>
                              <td><b>{numberFormat(u.ballance)}</b></td>
                              { currentUser && currentUser.type === 'Adminmaster' ? (
                                <td>[{u.user_id}] {u.company.full_name}</td>
                              ) : null }
                              <td>{u.created_at}</td>
                            </tr>
                          )
                        })
                      }
                    </>
                  ) : (
                    <div className='col col-md-12 input-field2'>
                      <span>Data Tidak Ditemukan</span>
                    </div>
                  )}
                </tbody>
              </Table>
              {paging ? (
                <div class='col'>
                  <div onClick={loadMoreData} class='load-more-btn'>
                    {loadMore ? (
                      <center><Dots color='#444' /><br/></center>
                    ) : null}
                    SELANJUTNYA
                  </div>
                </div>
              ) : null}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default AccountNumbers;
