import React, { useEffect, useState } from 'react';
import API from '../../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../../ReactSession';
import { Link, useHistory, useParams } from "react-router-dom";
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import DatePicker from "reactstrap-date-picker";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Input,
  Modal,
  ModalHeader, ModalBody, ModalFooter,
  Button,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
  Label
} from "reactstrap";

import Header from "components/Headers/Header.js";

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const AccountShow = () => {
  const {id} = useParams();
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const history = useHistory();

  const [data, setData] = useState([]);
  const [account, setAccount] = useState({});
  const [offset, setOffset] = useState(1);
  const [paging, setPaging] = useState(true);
  const [search, setSearch] = useState('');
  const [searchStartDate, setSearchStartDate] = useState('');
  const [searchEndDate, setSearchEndDate] = useState('');
  const [spinner, setSpinner] = useState(true);
  const [loadMore, setLoadMore] = useState(true);
  const [loadMoreButton, setLoadMoreButton] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [spinnerSave, setSpinnerSave] = useState(false);

  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [toAccountId, setToAccountId] = useState('');
  const [toAccountText, setToAccountText] = useState('');
  const [transactionType, setTransactionType] = useState('');
  const [subject, setSubject] = useState('');
  const [price, setPrice] = useState(0);
  const [transactionDate, setTransactionDate] = useState('');
  const [changeToAccount, setChangeToAccount] = useState(false);

  const [paymentNumbers, setPaymentNumbers] = useState([]);

  const [modal, setModal] = useState(false);
  const toggle = (id) => {
    if(id){
      let dt = data.filter(o => o.id === id)[0]
      if(dt){
        setToAccountId(dt.to_account_id);
        setToAccountText(dt.to_account_text);
        setSubject(dt.subject);
        setPrice(dt.price);
        setTransactionDate(dt.transaction_date);
        setTransactionType(dt.transaction_type);
      }else{
        setToAccountId('');
        setToAccountText('');
        setSubject('');
        setPrice('');
        setTransactionDate('');
        setTransactionType('');
      }
    }else{
      setToAccountId('');
      setToAccountText('');
      setSubject('');
      setPrice('');
      setTransactionDate('');
      setTransactionType('');
    }
    setModal(!modal);
  }

  const actPost = (id) => {
    setSpinnerSave(true);
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };

    const fd = new FormData();
    fd.append('to_account_id', toAccountId);
    fd.append('to_account_text', toAccountText);
    fd.append('subject', subject);
    fd.append('price', price);
    fd.append('transaction_date', transactionDate);
    fd.append('transaction_type', transactionType);
 
    API.post(`/accounts/${id}/transactions`, fd, config )
      .then(res => {
        setToAccountId('');
        setToAccountText('');
        setSubject('');
        setPrice('');
        setTransactionDate('');
        setTransactionType('');
        setSpinnerSave(false);
        loadData('');
        loadAccount('');
        toggle();
      })
      .catch(error => {
        setSpinnerSave(false);
        setVisible(true);
        if(error === undefined){
          setErrorMessage('Gagal, Terjadi kesalahan di server')
        }else{
          setErrorMessage(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
    
  }

  const loadAccount = () => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {include: ''  }};

    API.get(`accounts/${id}`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setAccount(data)
      })
  }

  const loadAccounts = () => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: { by_company_id: currentCompany.id, by_status: true, pagination: false }};    
    API.get(`accounts`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setPaymentNumbers(data);
      })
  }

  const loadData = (keyword, start_date, end_date) => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {start_at: start_date, end_at: end_date, key: keyword, include: ''  }};
    API.get(`accounts/${id}/transactions`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setData(data)
        setSpinner(false)
        setLoadMore(false);
        setTotalCount(res.data.meta.pagination.total_entries);
        if(res.data.meta.pagination.total_pages <= 1){
          setPaging(false);
          console.log('No paging')
        }else{
          setPaging(true);
          console.log('Yes paging')
        }
      })
  }

  const loadMoreData = () => {
    setLoadMoreButton(true);
    setLoadMore(true);
    setOffset(offset+1)
    let offset_page = offset+1
    const config = { headers: { Authorization: `Bearer ${token}` }, params: { start_at: searchStartDate, end_at: searchEndDate, page: offset_page, key: search, include: '' } };

    API.get(`accounts/${id}/transactions`, config)
      .then(res => {
        setLoadMore(false);
        const data2 = res.data.data;
        setData([...data, ...data2]);
        if(res.data.meta.pagination.total_pages === offset_page){
          setPaging(false);
        }else{
          setLoadMoreButton(false);
        }
      })
  }

  const onChangeToAccount = () => {
    setChangeToAccount(!changeToAccount)
    if(!changeToAccount){
      setToAccountText('')
    }else{
      setToAccountId('')
    }
  }

  const updateSearch = (search) => {
    setOffset(1)
    if(search === '' ){
      setSearch('');
    }else{
      setSearch(search);
    }
    setLoadMoreButton(false);
    loadData(search, searchStartDate, searchEndDate);
  }

  const updateSearchStartDate = (start_date) => {
    setSearchStartDate(start_date)
    loadData(search, start_date, searchEndDate);
  }

  const updateSearchEndDate = (end_date) => {
    setSearchEndDate(end_date)
    loadData(search, searchStartDate, end_date);
  }

  const deletePost = (id_transaction) => {
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    API.delete(`accounts/${id}/transactions/${id_transaction}`, config )
      .then(res => {
        loadData('');
        loadAccount('');
      })
      .catch(error => {
        setVisible(true);
        if(error === undefined){
          setErrorMessage('Gagal, Terjadi kesalahan di server')
        }else{
          setErrorMessage(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const types = [
    {'name':'Debit', 'value': 'Debit'},
    {'name':'Credit', 'value': 'Credit'}
  ]

  useEffect(() => {
    if(token === undefined){
      history.push("/")
    }
    setSearch('');
    loadAccount();
    loadAccounts();
    loadData('');
  },[])

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Kelola Tambahan Dana</ModalHeader>
        <ModalBody>
          <Alert color="warning" isOpen={visible} toggle={onDismiss}>
            {errorMessage}
          </Alert>
          <Form role="form">
            <FormGroup className="mb-3">
              <div className='row'>
                <div className='col-12'>
                  <Label className='label-form-group'>Subyek</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <Input
                      placeholder="Subyek"
                      value={subject} onChange={e => setSubject(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <Label className='label-form-group'>Pilih Tipe</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <Input type="select" value={transactionType} onChange={e => setTransactionType(e.target.value)}>
                      <option value=''>Pilih Tipe</option>
                      {
                        types.map((c) => {
                          return (
                            <option key={c.value} value={c.value}>{c.name}</option>
                          )
                        })
                      }
                    </Input>
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <Label className='label-form-group'>Tanggal Transaksi</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <DatePicker id= "example-datepicker3" value={transactionDate} onChange={e => setTransactionDate(e)} />
                  </InputGroup>
                </div>
                <div className='col-12'>
                  <Label className='label-form-group'>Nilai</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        RP
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type='number'
                      placeholder="0"
                      value={price} onChange={e => setPrice(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-12'>
                  <div className='row'>
                    <div className='col-8'>
                      <Label className='label-form-group'>Tujuan Pembayaran</Label>
                    </div>
                    <div className='col-4' style={{textAlign: 'right', color: 'black'}}>
                      <Label className='label-form-group' onClick={(e) => onChangeToAccount(!changeToAccount)} style={{cursor: 'pointer'}}>Pilih Akun</Label>
                    </div>
                  </div>
                  {
                    changeToAccount ? (
                      <InputGroup className="input-group-alternative mb-20">
                        <Input type="select" value={toAccountId} onChange={e => setToAccountId(e.target.value)}>
                          <option value=''>Pilih</option>
                          {
                            paymentNumbers.map((c) => {
                              return (
                                <option key={c.id} value={c.id}>{c.name+'-'+c.code}</option>
                              )
                            })
                          }
                        </Input>
                      </InputGroup>
                    ) : (
                      <InputGroup className="input-group-alternative mb-20">
                        <Input
                          placeholder="Tujuan Pembayaran"
                          value={toAccountText} onChange={e => setToAccountText(e.target.value)}
                        />
                      </InputGroup>
                    )
                  }
                </div>
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          { spinnerSave ? (
            <Button color="primary" style={{background: (currentCompany.company_color3)}}>
              <Dots color='#fff' />
            </Button>
          ) : (
            <Button color="primary" style={{background: (currentCompany.company_color3)}} onClick={e => actPost(id)}>Simpan</Button>
          )}
          <Button color="secondary" onClick={(e) => toggle(e)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <div className='row'>
          <div className='col-sm-2'>
          <Button onClick={(e) => toggle(e)} className='btn btn-info full-width' style={{background: (currentCompany.company_color3)}}><i class="fas fa-plus"></i> Tambah</Button>
          </div>
          <div className='col-sm-6'>
            <Input placeholder="Kata Pencarian" value={search} onChange={e => updateSearch(e.target.value)}/>
          </div>
          <div className='col-sm-2'>
            <div className='form-group'>
              <DatePicker id= "example-datepicker" value={searchStartDate} onChange={e => updateSearchStartDate(e)} />
            </div>
          </div>
          <div className='col-sm-2'>
            <div className='form-group'>
              <DatePicker id= "example-datepicker2" value={searchEndDate} onChange={e => updateSearchEndDate(e)} />
            </div>
          </div>
        </div>
        <Row>
          <div className="col">
            <Card className="shadow" style={{marginBottom: 10}}>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <td width='25%' style={{fontSize: 14}} scope="col">{account.updated_at}</td>
                    <td width='25%' style={{fontSize: 14, fontWeight: 'bold'}} scope="col">{account.code} - {account.name}</td>
                    <td width='25%' style={{fontSize: 14, textAlign: 'right'}} scope="col">SALDO</td>
                    <td width='25%' style={{fontSize: 14, fontWeight: 'bold', textAlign: 'right'}} scope="col">{numberFormat(account.ballance)}</td>
                  </tr>
                </thead>
              </Table>
            </Card>
            <Card className="shadow">
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th width='20%' scope="col">Nomor</th>
                    <th width='30%' scope="col">Keterangan</th>
                    <th width='20%' style={{textAlign: 'right'}} scope="col">Transaksi</th>
                    <th width='15%' style={{textAlign: 'right'}} scope="col">CREDIT</th>
                    <th width='15%' style={{textAlign: 'right'}} scope="col">DEBIT</th>
                  </tr>
                </thead>
                <tbody>
                  { spinner ? (
                    <tr>
                      <td colspan="5" className='text-center'>
                        <div className='loadLoading'><Dots color='#444' /></div>
                      </td>
                    </tr>
                  ) : data && data.length > 0 ? (
                    <>
                      {
                        data.map((u) => {
                          return (
                            <tr key={u.id}>
                              <td>
                                {
                                  u.account_transactionable_type === 'Account' ? (
                                    <button className='btn btn-sm btn-warning' onClick={(e) => deletePost(u.id)}>
                                      <i class="ni ni-fat-remove"></i>
                                    </button>
                                  ) : null
                                }
                                &nbsp;{u.no}
                              </td>
                              <td>
                                {
                                  u.account_transactionable_type !== 'Account' ? (
                                    <Link to={`/admin/${u.account_transactionable_type.toLowerCase()}/${u.account_transactionable_id}`}>{u.subject}</Link>
                                  ) : (
                                    <span>{u.subject}</span>
                                  )
                                }
                              </td>
                              <td style={{textAlign: 'right'}}>{u.transaction_date}</td>
                              <td style={{textAlign: 'right'}}><b>{u.transaction_type === 'Credit' ? `-${numberFormat(u.amount)}` : ''}</b></td>
                              <td style={{textAlign: 'right'}}><b>{u.transaction_type === 'Debit' ? `+${numberFormat(u.amount)}` : ''}</b></td>
                            </tr>
                          )
                        })
                      }
                    </>
                  ) : (
                    <div className='col col-md-12 input-field2'>
                      <span>Data Tidak Ditemukan</span>
                    </div>
                  )}
                </tbody>
              </Table>
              {paging ? (
                <div class='col'>
                  <div onClick={loadMoreData} class='load-more-btn'>
                    {loadMore ? (
                      <center><Dots color='#444' /><br/></center>
                    ) : null}
                    SELANJUTNYA
                  </div>
                </div>
              ) : null}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default AccountShow;
