import React, { useEffect, useState } from 'react';
import API from '../../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../../ReactSession';
import { useHistory } from "react-router-dom";
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import Creatable, { useCreatable } from 'react-select/creatable';


import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Input,
  Modal,
  ModalHeader, ModalBody, ModalFooter,
  Button,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
  Label
} from "reactstrap";

import Header from "components/Headers/Header.js";

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const TableIndex = () => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const history = useHistory();

  const [id, setId] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [number, setNumber] = useState('');
  const [seats, setSeats] = useState('');
  const [verified, setVerified] = useState(false);
  const [outletId, setOutletId] = useState('');
  const [roomId, setRoomId] = useState('');
  const [generateNumber, setGeneateNumber] = useState(false);

  const [byNumber, setByNumber] = useState(1);
  const [bySeats, setBySeats] = useState('');
  const [byStatus, setByStatus] = useState('');

  const [endNumber, setEndNumber] = useState(1);
  const [outlets, setOutlets] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(1);
  const [paging, setPaging] = useState(true);
  const [search, setSearch] = useState('');
  const [spinner, setSpinner] = useState(true);
  const [loadMore, setLoadMore] = useState(true);
  const [loadMoreButton, setLoadMoreButton] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [spinnerSave, setSpinnerSave] = useState(false);

  const statusCollection = [
    {'name':'Tersedia', 'value': true},
    {'name':'Tidak Tersedia', 'value': false}
  ]

  const [modal, setModal] = useState(false);
  const toggle = (id) => {
    setGeneateNumber(false)
    if(id){
      let dt = data.filter(o => o.id === id)[0]
      if(dt){
        setCompanyId(dt.company_id);
        setId(dt.id);
        setNumber(dt.number);
        setEndNumber('');
        setSeats(dt.seats);
        setVerified(dt.is_available);
        if(currentUser && currentUser.type === 'Adminmaster'){
          setCompanyLoad(dt.company_id);
        }
        setOutletId(dt.outlet_id);
        setRoomId(dt.room_id);
      }else{
        setCompanyId('');
        setId('');
        setNumber('');
        setEndNumber('');
        setSeats(1);
        setVerified(false);
        setOutletId('');
        setRoomId('');
      }
    }else{
      setCompanyId('');
      setId('');
      setNumber('');
      setEndNumber('');
      setSeats(1);
      setVerified(false);
      setOutletId('');
      setRoomId('');
    }
    setModal(!modal);
  }

  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);
  const [errorMessage, setErrorMessage] = useState('');

  const actPost = (id) => {
    setSpinnerSave(true);
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };
    const fd = new FormData();
    fd.append('number', number);
    fd.append('end_number', endNumber);
    fd.append('seats', seats);
    fd.append('is_available', verified);
    fd.append('company_id', companyId);
    fd.append('outlet_id', outletId);
    fd.append('room_id', roomId);

    if(id){
      API.put(`tables/${id}`, fd, config )
        .then(res => {
          setCompanyId('');
          setId('');
          setNumber('');
          setEndNumber('');
          setSeats(1);
          setVerified(false);
          setOutletId('');
          setRoomId('');
          loadData('');
          setSpinnerSave(false);
          toggle();
        })
        .catch(error => {
          setSpinnerSave(false);
          setVisible(true);
          if(error === undefined){
            setErrorMessage('Gagal, Terjadi kesalahan di server')
          }else{
            setErrorMessage(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }else{
      API.post('tables', fd, config )
        .then(res => {
          setCompanyId('');
          setId('');
          setNumber('');
          setEndNumber('');
          setSeats(1);
          setVerified(false);
          setOutletId('');
          setRoomId('');
          loadData('');
          setSpinnerSave(false);
          toggle();
        })
        .catch(error => {
          setSpinnerSave(false);
          setVisible(true);
          if(error === undefined){
            setErrorMessage('Gagal, Terjadi kesalahan di server')
          }else{
            setErrorMessage(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }

  }

  const deletePost = (id) => {
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };

    API.delete(`tables/${id}`, config )
      .then(res => {
        loadData('');
      })
      .catch(error => {
        setVisible(true);
        if(error === undefined){
          setErrorMessage('Gagal, Terjadi kesalahan di server')
        }else{
          setErrorMessage(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const loadData = (keyword, number, seats, status) => {    
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {key: keyword, by_number: number, by_seats: seats, by_status: status, include: 'company,outlet_entity,room_entity'  }};
    API.get(`tables`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setData(data)
        setSpinner(false)
        setLoadMore(false);
        setTotalCount(res.data.meta.pagination.total_entries);
        if(res.data.meta.pagination.total_pages <= 1){
          setPaging(false);
          console.log('No paging')
        }else{
          setPaging(true);
          console.log('Yes paging')
        }
      })

    const config2 = { headers: { Authorization: `Bearer ${token}`}, params: {by_verified: true, key: keyword, by_user_type: 'Company', pagination: false  }};
    API.get(`users`, config2)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setCompanies(data);
      })
  }

  const loadMoreData = () => {
    setLoadMoreButton(true);
    setLoadMore(true);
    setOffset(offset+1)
    let offset_page = offset+1
    const config = { headers: { Authorization: `Bearer ${token}` }, params: { page: offset_page, key: '', by_number: byNumber, by_seats: bySeats, by_status: byStatus, include: 'company,outlet_entity,room_entity' } };

    API.get(`tables`, config)
      .then(res => {
        setLoadMore(false);
        const data2 = res.data.data;
        setData([...data, ...data2]);
        if(res.data.meta.pagination.total_pages === offset_page){
          setPaging(false);
        }else{
          setLoadMoreButton(false);
        }
      })
  }

  const updateSearch = (search) => {
    setOffset(1)
    if(search === '' ){
      setSearch('');
    }else{
      setSearch(search);
    }
    setLoadMoreButton(false);
    loadData(search);
  }

  const updateSearchNumber = (number) => {
    setByNumber(number)
    loadData(search, number, bySeats, byStatus);
  }

  const updateSearchSeats = (seats) => {
    setBySeats(seats)
    loadData(search, byNumber, seats, byStatus);
  }

  const updateSearchStatus = (status) => {
    setByStatus(status)
    loadData(search, byNumber, bySeats, status);
  }

  const clearSearch = (search) => {
    setOffset(1);
    updateSearch('');
    loadData('');
    setLoadMoreButton(false);
  }

  const changeVerified = (id) => {
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };
    
    API.put(`tables/${id}/verified`, {status: ''}, config )
      .then(res => {
        const data = res.data.data;
        alert(`Berhasil di ${data.verified ? 'Aktifkan' : 'NonAktif'}`);
        loadData('');
      })
      .catch(error => {
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const setCompanyLoad = (company_id) => {
    setCompanyId(company_id);

    const config = { headers: { Authorization: `Bearer ${token}`}, params: {by_status: true, by_company_id: company_id, pagination: false }};

    API.get(`outlets`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setOutlets(data);
      })

    API.get(`rooms`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setRooms(data);
      })
  }

  const [tagInputValue, setTagInputValue] = useState('')

  useEffect(() => {
    if(token === undefined){
      history.push("/")
    }

    setSearch('');
    loadData('');

    if(currentUser && currentUser.type === 'Company'){
      setCompanyLoad(currentUser.id);
    }else if (currentUser && currentUser.type === 'Staff'){
      setCompanyLoad(currentUser.company_id)
    }
  },[])

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Kelola Room</ModalHeader>
        <ModalBody>
          <Alert color="warning" isOpen={visible} toggle={onDismiss}>
            {errorMessage}
          </Alert>
          <Form role="form">
            <FormGroup className="mb-3">
              <div className='row'>
                { currentUser && currentUser.type === 'Adminmaster' ? (
                  <div className='col-sm-12'>
                    <Label className='label-form-group'>Perusahaan</Label>
                    <InputGroup className="input-group-alternative mb-20">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-building" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="select" value={companyId} onChange={e => setCompanyLoad(e.target.value)}>
                        <option value=''>Pilih Perusahaan</option>
                        {
                          companies.map((c) => {
                            return (
                              <option key={c.id} value={c.id}>{c.full_name}</option>
                            )
                          })
                        }
                      </Input>
                    </InputGroup>
                  </div>  
                ) : null }

                {(currentUser.last_package && currentUser.last_package.can_multi_outlet == true ) ? (
                  <div className='col-sm-12'>
                    <Label className='label-form-group'>Outlet</Label>
                    <InputGroup className="input-group-alternative mb-20">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-building" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="select" value={outletId} onChange={e => setOutletId(e.target.value)}>
                        <option value=''>Pilih Outlet</option>
                        {
                          outlets.map((c) => {
                            return (
                              <option key={c.id} value={c.id}>{c.name}</option>
                            )
                          })
                        }
                      </Input>
                    </InputGroup>
                  </div>  
                ) : null }

                <div className='col-sm-12'>
                  <Label className='label-form-group'>Room</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-building" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input type="select" value={roomId} onChange={e => setRoomId(e.target.value)}>
                      <option value=''>Pilih Room</option>
                      {
                        rooms.map((c) => {
                          return (
                            <option key={c.id} value={c.id}>{c.name}</option>
                          )
                        })
                      }
                    </Input>
                  </InputGroup>
                </div>  

                {
                  generateNumber ? (
                    <>
                      <div className='col-6'>
                        <Label className='label-form-group'>Buat Table Dari</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <Input
                            placeholder="Nomor"
                            value={number} onChange={e => setNumber(e.target.value)}
                          />
                        </InputGroup>
                      </div>

                      <div className='col-6'>
                        <Label className='label-form-group'>Sampai Table</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <Input
                            placeholder="Nomor"
                            value={endNumber} onChange={e => setEndNumber(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                    </>
                  ) : (
                    <div className='col-12'>
                      <Label className='label-form-group'>
                        Nomor Table 
                        {
                          id ? null : (
                            <span style={{float: 'right'}}>Buat Banyak? <a href="#" onClick={e => setGeneateNumber(!generateNumber)}>Klik Disini</a></span>
                          )
                        }
                      </Label>
                      <InputGroup className="input-group-alternative mb-20">
                        <Input
                          placeholder="Nomor"
                          value={number} onChange={e => setNumber(e.target.value)}
                        />
                      </InputGroup>
                    </div>
                  )
                }

                <div className='col-12'>
                  <Label className='label-form-group'>Kapasitas Kursi</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <Input
                      type="number"
                      placeholder="Jumlah Kursi"
                      value={seats} onChange={e => setSeats(e.target.value)}
                    />
                  </InputGroup>
                </div>

                <div className='col-12'>
                  <FormGroup className="form-checkbox">
                    <Label check style={{paddingTop: 15}}>
                      <Input type="checkbox" defaultChecked={verified} onChange={e => setVerified(!verified)}/> Table Tersedia
                    </Label>
                  </FormGroup>
                </div>
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          { spinnerSave ? (
            <Button color="primary" style={{background: (currentCompany.company_color3)}}>
              <Dots color='#fff' />
            </Button>
          ) : (
            <Button color="primary" style={{background: (currentCompany.company_color3)}} onClick={e => actPost(id)}>Simpan</Button>
          )}
          <Button color="secondary" onClick={(e) => toggle(e)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className='row'>
                  <div className='col-sm-2'>
                    <Button className='btn btn-white full-width'>TOTAL {totalCount}</Button>
                  </div>
                  <div className='col-sm-2'>
                    <Input placeholder="Kata Pencarian" value={search} onChange={e => updateSearch(e.target.value)}/>
                  </div>
                  <div className='col-sm-2'>
                    <Input placeholder="Nomor" value={byNumber} onChange={e => updateSearchNumber(e.target.value)}/>
                  </div>
                  <div className='col-sm-2'>
                    <Input placeholder="Jumlah Kursi" value={bySeats} onChange={e => updateSearchSeats(e.target.value)}/>
                  </div>
                  <div className='col-sm-2'>
                    <Input type="select" value={byStatus} onChange={e => updateSearchStatus(e.target.value)}>
                      <option value=''>Status Ketersediaan</option>
                      { statusCollection.map((s) => {
                        return (
                          <option value={s.value}>{s.name}</option>
                        )
                      })}
                    </Input>
                  </div>
                  <div className='col-sm-2'>
                    <Button onClick={(e) => toggle(e)} className='btn btn-info full-width' style={{background: (currentCompany.company_color3)}}><i class="fas fa-plus"></i> TAMBAH</Button>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th width='100px' scope="col text-center">Action</th>
                    <th width='120px'>Ketersediaan</th>
                    <th scope="col">ID</th>
                    <th scope="col">Room</th>
                    <th scope="col">Nomor</th>
                    <th scope="col">Seats</th>
                    { currentUser && currentUser.type === 'Adminmaster' ? (
                      <th scope="col">Perusahaan</th>
                    ) : null }
                    <th scope="col">Outlet</th>
                  </tr>
                </thead>
                <tbody>
                  { spinner ? (
                    <tr>
                      <td colspan="6" className='text-center'>
                        <div className='loadLoading'><Dots color='#444' /></div>
                      </td>
                    </tr>
                  ) : data && data.length > 0 ? (
                    <>
                      {
                        data.map((u) => {
                          return (
                            <tr key={u.id}>
                              <td width='100px'>
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#pablo"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                    { currentUser.type === 'Adminmaster' || currentUser.type === 'Company' ? (
                                       <DropdownItem href="#pablo" onClick={(e) => deletePost(u.id)}>
                                        Hapus
                                      </DropdownItem>
                                    ) : null } 
                                    <DropdownItem onClick={(e) => toggle(u.id)}>
                                      Ubah
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                              <td>
                                <div style={styles.status(u.is_available)}>
                                  {u.is_available ? (
                                    <i class="fa-big fa fa-power-off" onClick={(e) => changeVerified(u.id)}></i>
                                  ) : (
                                    <i class="fa-big fa fa-power-off" onClick={(e) => changeVerified(u.id)}></i>
                                  )}
                                </div>
                              </td>
                              <td>{u.id}</td>
                              <td>{u?.room?.name}</td>
                              <td>{u.number}</td>
                              <td>{u.seats}</td>
                              { currentUser && currentUser.type === 'Adminmaster' ? (
                                <td>[{u.company_id}] {u?.company?.full_name}</td>
                              ) : null }
                              <td>
                                {
                                  u.outlet_id ? (
                                    <>[{u.outlet_id}] {u?.outlet?.full_name}</>
                                  ) : '-'
                                }
                              </td>
                            </tr>
                          )
                        })
                      }
                    </>
                  ) : (
                    <div className='col col-md-12 input-field2'>
                      <span>Data Tidak Ditemukan</span>
                    </div>
                  )}
                </tbody>
              </Table>
              {paging ? (
                <div class='col'>
                  <div onClick={loadMoreData} class='load-more-btn'>
                    {loadMore ? (
                      <center><Dots color='#444' /><br/></center>
                    ) : null}
                    SELANJUTNYA
                  </div>
                </div>
              ) : null}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default TableIndex;


const styles: StyleSheet = {
  status: (status) => ({
    fontSize: 11,
    color: status === true ? '#1963da' : '#ccc',
    paddingBottom: 2,
    borderRadius: 5,
  })
}