import React, { useEffect, useState } from 'react';
import API from '../../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../../ReactSession';
import { useHistory, useParams } from "react-router-dom";
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import DatePicker from "reactstrap-date-picker";
import Creatable, { useCreatable } from 'react-select/creatable';

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Input,
  Modal,
  ModalHeader, ModalBody, ModalFooter,
  CardBody,
  Button,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
  Label
} from "reactstrap";

import Header from "components/Headers/Header.js";

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const CompanyEdit = () => {
  const {id} = useParams();
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const history = useHistory();

  // ATTRIBUTES PRODUCT
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [emailPassword, setEmailPassword] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [gender, setGender] = useState('');
  const [birthday, setBirthday] = useState('');
  const [phone1, setPhone1] = useState('');
  const [phone2, setPhone2] = useState('');
  const [address, setAddress] = useState('');
  const [addressCode, setAddressCode] = useState('');
  const [fullAddress, setFullAddress] = useState('');
  const [editAddress, setEditAddress] = useState(false);
  const [domain, setDomain] = useState('');
  const [domains, setDomains] = useState([]);
  const [imageFile, setImageFile] = useState();
  const [imageSrc, setImageSrc] = useState();
  const [imageSrc2, setImageSrc2] = useState();
  const [waKeyType, setWaKeyType] = useState('');
  const [waKey, setWaKey] = useState('');
  const [themeplate, setThemeplate] = useState('');
  const [companyAppType, setCompanyAppType] = useState('');
  const [groupTelegram, setGroupTelegram] = useState('');
  const [groupFacebook, setGroupFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [facebook, setFacebook] = useState('');
  const [addressOffice, setAddressOffice] = useState('');
  const [youtube, setYoutube] = useState('');

  const [data, setData] = useState({});
  const [useCod, setUseCod] = useState(false);
  const [useTakeAway, setUseTakeAway] = useState(false);
  const [useSaldoVirtual, setUseSaldoVirtual] = useState(false);
  const [usePointVirtual, setUsePointVirtual] = useState(false);
  const [useUpgradeUser, setUseUpgradeUser] = useState(false);
  const [useExcludePurchase, setUseExcludePurchase] = useState(false);

  const [companyName, setCompanyName] = useState('');
  const [companyIndustry, setCompanyIndustry] = useState('');
  const [companyDescription, setCompanyDescription] = useState('');
  const [companyColor1, setCompanyColor1] = useState('');
  const [companyColor2, setCompanyColor2] = useState('');
  const [companyColor3, setCompanyColor3] = useState('');
  const [companyColor4, setCompanyColor4] = useState('');
  const [companyLogoFile, setCompanyLogoFile] = useState();
  const [companyLogoSrc, setCompanyLogoSrc] = useState();
  const [companyIconFile, setCompanyIconFile] = useState();
  const [companyIconSrc, setCompanyIconSrc] = useState();
  const [publishedHomepage, setPublishedHomepage] = useState(false);
  
  const [queryDistrict, setQueryDistrict] = useState('');
  const [districts, setDistricts] = useState([]);
  const [galleries, setGalleries] = useState([]);
  const [galleriesPromo, setGalleriesPromo] = useState([]);

  const [spinner, setSpinner] = useState(true);
  const [spinnerSave, setSpinnerSave] = useState(false);

  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);
  const [errorMessage, setErrorMessage] = useState('');

  const findDistrict = (query) => {
    setQueryDistrict(query);
    console.log('find district '+query)
    API.get(`regions`, {params: {key: query}} )
      .then(res => {
        const data = res.data.data;
        setDistricts(data);
        console.log(data)
      })
  }

  const onChangeAddress = () => {
    editAddress === true ? setEditAddress(false) : setEditAddress(true)
  }

  const handleImageSelect = (e) => {
    setImageSrc(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setImageFile(files);
  }

  const handleOnSearch = (string, results) => {
    console.log(string, results)
    findDistrict(string)
  }

  const handleOnHover = (result) => {
    console.log(result)
  }

  const handleOnSelect = (item) => {
    setAddressCode(item.id)
  }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const formatResult = (item) => {
    return (<div dangerouslySetInnerHTML={{__html: '<span>'+item.name+'</span>'}}></div>); //To format result as html
    // return item;
   // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
  }

  const actPost = () => {
    setSpinnerSave(true);
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };

    const fd = new FormData();
    if(imageFile){
      fd.append('avatar', imageFile, imageFile.name);
    }
    if(companyLogoFile){
      fd.append('company_logo', companyLogoFile, companyLogoFile.name);
    }
    if(companyIconFile){
      fd.append('company_icon', companyIconFile, companyIconFile.name);
    }
    fd.append('username', username);
    fd.append('email', email);
    fd.append('email_password', emailPassword);
    fd.append('password', password);
    fd.append('full_name', fullName);
    fd.append('gender', gender);
    fd.append('birthday', birthday);
    fd.append('phone1', phone1);
    fd.append('phone2', phone2);
    fd.append('address', address);
    fd.append('address_code', addressCode);
    fd.append('company_name', companyName);
    fd.append('company_description', companyDescription);
    fd.append('group_facebook', groupFacebook);
    fd.append('group_telegram', groupTelegram);
    fd.append('instagram', instagram);
    fd.append('facebook', facebook);
    fd.append('address_office', addressOffice);
    fd.append('youtube', youtube);
    if(domain){
      fd.append('domain', domain);
    }
    fd.append('company_color1', companyColor1);
    fd.append('company_color2', companyColor2);
    fd.append('company_color3', companyColor3);
    fd.append('company_color4', companyColor4);
    fd.append('wa_key_type', waKeyType);
    fd.append('wa_key', waKey);
    fd.append('themeplate', themeplate);
    fd.append('config_features', JSON.stringify({
      "use_cod": useCod, 
      "use_take_away": useTakeAway, 
      "use_saldo": useSaldoVirtual, 
      "use_point_virtual": usePointVirtual, 
      "use_upgrade_user": useUpgradeUser,
      "use_exclude_purchase": useExcludePurchase
    }));
    fd.append('config_domains',  JSON.stringify(domains));
    fd.append('published_homepage',  publishedHomepage);
    fd.append('company_app_type',  companyAppType);
    
    API.put(`users/${id}`, fd, config )
      .then(res => {
        setSpinnerSave(false);
        alert('Akun berhasil diubah!');
      })
      .catch(error => {
        setVisible(true);
        setSpinnerSave(false);
        if(error === undefined){
          setErrorMessage('Gagal, Terjadi kesalahan di server')
        }else{
          setErrorMessage(error.response.data.meta.status.message);
        }
      })
    
  }

  const loadData = () => {    
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {include: '', by_user_type: 'Company' } };

    API.get(`users/${id}`, config)
      .then(res => {
        const data = res.data.data;

        setData(data);
        setUseCod(data.config_features['use_cod'] === undefined ? false : data.config_features['use_cod'] );
        setUseTakeAway(data.config_features['use_take_away'] === undefined ? false : data.config_features['use_take_away'] );
        setUseSaldoVirtual(data.config_features['use_saldo'] === undefined ? false : data.config_features['use_saldo'] );
        setUsePointVirtual(data.config_features['use_point_virtual'] === undefined ? false : data.config_features['use_point_virtual'] );
        setUseUpgradeUser(data.config_features['use_upgrade_user'] === undefined ? false : data.config_features['use_upgrade_user'] );
        setUseExcludePurchase(data.config_features['use_exclude_purchase'] === undefined ? false : data.config_features['use_exclude_purchase'] );

        setUsername(data.username);
        setEmail(data.email);
        setEmailPassword(data.email_password);
        setFullName(data.full_name);
        setGender(data.gender);
        setBirthday(data.birthday);
        setPhone1(data.phone1);
        setPhone2(data.phone2);
        setAddress(data.address);
        setAddressCode(data.address_code);
        setFullAddress(`${data.district}, ${data.city}, ${data.province}`)
        setDistricts([{id: data.address_code, name: data.district+','+data.city+','+data.province }])
        setDomain(data.domain);
        setSpinner(false);
        setImageSrc(data.avatar_url);
        setCompanyLogoSrc(data.company_logo_url);
        setCompanyIconSrc(data.company_icon_url);
        setCompanyName(data.company_name);
        setCompanyDescription(data.company_description);
        setCompanyColor1(data.company_color1);
        setCompanyColor2(data.company_color2);
        setCompanyColor3(data.company_color3);
        setCompanyColor4(data.company_color4);
        setWaKeyType(data.wa_key_type);
        setWaKey(data.wa_key);
        setThemeplate(data.themeplate);
        setCompanyAppType(data.company_app_type);
        setGroupFacebook(data.group_facebook);
        setGroupTelegram(data.group_telegram);
        setFacebook(data.facebook);
        setYoutube(data.youtube);
        setAddressOffice(data.address_office);
        setInstagram(data.instagram);
        setCompanyIndustry(data.company_industry);
        setDomains(data.config_domains);
        setPublishedHomepage(data.published_homepage);
      })
      .catch(error => {
        if(error === undefined){
          alert.show('Gagal, Terjadi kesalahan di server')
        }else{
          alert.show(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const loadGalleries = (user_id) => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {user_id: id}};    
    API.get(`websetting_galleries`, config)
      .then(res => {
        const data = res.data.data;
        if(data){
          setGalleries(data.filter((g) => g.category === 'home_app'));
          setGalleriesPromo(data.filter((g) => g.category === 'home_promo'));
        }
      })
      .catch(error => {
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const postGalleries = (e) => {
    const files = e.target.files[0]

    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };

    const fd = new FormData(); 
    if(files){
      fd.append('image', files, files.name);
    }
    fd.append('user_id', id);
    fd.append('category', 'home_app');
    
    API.post(`websetting_galleries`, fd, config )
      .then(res => {
        loadGalleries(currentUser.id);
      })
      .catch(error => {
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const postGalleriesPromo = (e) => {
    const files = e.target.files[0]

    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };

    const fd = new FormData(); 
    if(files){
      fd.append('image', files, files.name);
    }
    fd.append('user_id', id);
    fd.append('category', 'home_promo');
    
    API.post(`websetting_galleries`, fd, config )
      .then(res => {
        loadGalleries(currentUser.id);
      })
      .catch(error => {
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const deleteGallery = (gallery_id) => {
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };
    
    API.delete(`websetting_galleries/${gallery_id}`, config )
      .then(res => {
        loadGalleries(currentUser.id);
      })
      .catch(error => {
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const handleImageSelectLogo = (e) => {
    setCompanyLogoSrc(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setCompanyLogoFile(files);
  }

  const handleImageSelectIcon = (e) => {
    setCompanyIconSrc(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setCompanyIconFile(files);
  }

  const [tagInputValue, setTagInputValue] = useState('')

  const handleChange = (field, value) => {
    setDomains(value)
  }

  const handleKeyDown = event => {
    if (!tagInputValue) return
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setDomains([...domains, createOption(tagInputValue)])
        setTagInputValue('')

        event.preventDefault()
        break
      default:
        break
    }
  }

  const createOption = label => ({
    label,
    value: label
  })

  const handleInputChange = (value) => {
    setTagInputValue(value)
  }

  useEffect(() => {
    if(token === undefined){
      history.push("/")
    }

    loadData();
    loadGalleries(id);
  
  },[])

  return (
    <>
      <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        
        { spinner === false ? (
          <Row>
            <div className="col-md-8">
              <Card className="shadow mb-20">
                <CardHeader>Pengaturan Akun</CardHeader>
                <CardBody>
                  <Alert color="warning" isOpen={visible} toggle={onDismiss}>
                    {errorMessage}
                  </Alert>
                  <FormGroup className="mb-3">
                    <div className='row'>
                      <div className='col-sm-6 col-6'>
                        <Label className='label-form-group'>Alamat Email Bisnis</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              @
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email"
                            value={email} onChange={e => setEmail(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                      <div className='col-sm-6 col-6'>
                        <Label className='label-form-group'>Kata Sandi Dashboard</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-key" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type='password'
                            placeholder="Kata Sandi"
                            value={password} onChange={e => setPassword(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                      <div className='col-sm-6 col-6'>
                        <Label className='label-form-group'>Sandi Gsuite ( <a href="https://workspace.google.com/business/signup/welcome" target="_blank">Link 3Party</a> )</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-key" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type='text'
                            placeholder="Kata Sandi Email Bisnis"
                            value={emailPassword} onChange={e => setEmailPassword(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                      <div className='col-sm-6 col-6'>
                        <Label className='label-form-group'>Kontak Owner</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-phone" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Kontak Owner Bisnis"
                            value={phone2} onChange={e => setPhone2(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  </FormGroup>
                </CardBody>
              </Card>

              <Card className="shadow mb-20">
                <CardHeader>
                  WhatsApp Notifikasi ( <a href="https://wanotif.id/" target="_blank">Link 3Party</a> )
                </CardHeader>
                <CardBody>
                  <Alert color="warning" isOpen={visible} toggle={onDismiss}>
                    {errorMessage}
                  </Alert>
                  <FormGroup className="mb-3">
                    <div className='row'>
                      <div className='col-sm-4'>
                        <Label className='label-form-group'>Kontak Utama</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-phone" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Kontak Utama Bisnis"
                            value={phone1} onChange={e => setPhone1(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                      <div className='col-sm-4'>
                        <Label className='label-form-group'>WA Key Provider Binis</Label>
                        <InputGroup className="input-group-alternative">
                          <Input type="select" value={waKeyType} onChange={e => setWaKeyType(e.target.value)}>
                            <option value=''>Pilih WA Provider</option>
                            <option value='wanotif'>WaNotif</option>
                            <option value='wonatif'>WoNotif</option>
                          </Input>
                        </InputGroup>
                      </div>
                      <div className='col-sm-4'>
                        <Label className='label-form-group'>WA Api Key Bisnis</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <Input
                            placeholder="WA Api Key"
                            value={waKey} onChange={e => setWaKey(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  </FormGroup>
                </CardBody>
              </Card>

              <Card className="shadow mb-20">
                <CardHeader>
                  Official Perusahaan
                </CardHeader>
                <CardBody>
                  <Alert color="warning" isOpen={visible} toggle={onDismiss}>
                    {errorMessage}
                  </Alert>
                  <FormGroup className="mb-3">
                    <div className='row'>
                      <div className='col-sm-6'>
                        <Label className='label-form-group'>Nama Bisnis/Toko/Perusahaan</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-bookmark" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Nama Lengkap"
                            value={fullName} onChange={e => setFullName(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                      <div className='col-sm-6'>
                        <Label className='label-form-group'>Domain Utama</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-globe" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Domain"
                            value={domain} onChange={e => setDomain(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                      <div className='col-12'>
                        <FormGroup>
                          <Label className='label-form-group'>Domain Toko</Label>
                          <Creatable
                            clearable={true}
                            isMulti
                            components={{ DropdownIndicator: null }}
                            inputValue={tagInputValue}
                            menuIsOpen={false}
                            onChange={(value) => handleChange('tags', value)}
                            placeholder=''
                            onKeyDown={handleKeyDown}
                            onInputChange={handleInputChange}
                            value={domains}
                          />
                        </FormGroup>
                      </div>
                      <div className='col-sm-12'>
                        <Label className='label-form-group'>Deskripsi</Label>
                        <InputGroup className="input-group-alternative">
                          <Input
                            type="textarea"
                            placeholder="Deskripsi Bisnis/Toko/Perusahaan"
                            value={companyDescription} onChange={e => setCompanyDescription(e.target.value)}
                            className='editor-text'
                          />
                        </InputGroup>
                      </div>
                      <div className='col-sm-12'>
                        <Label className='label-form-group'>Alamat Lengkap Official</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-map-marker" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Alamat Lengkap Official"
                            value={addressOffice} onChange={e => setAddressOffice(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  </FormGroup>
                </CardBody>
              </Card>

              <Card className="shadow mb-20">
                <CardHeader>
                  Warehouse Utama (Gudang)
                </CardHeader>
                <CardBody>
                  <FormGroup className="mb-0">
                    <div className='row'>
                      <div class="col-sm-12 autocomplete-field">
                        <div class="active" for="icon_prefix">
                          <Label className='label-form-group'>Kecamatan, Kota, Provinsi</Label>
                          { editAddress !== true ? (<span href='#' style={{cursor: 'pointer'}} class='btn-right-label' onClick={ onChangeAddress } ><i class="ni ni-settings-gear-65"></i> Ganti Kecamatan</span>) : null }
                        </div>
                        { editAddress ? (
                          <ReactSearchAutocomplete
                            items={districts}
                            onSearch={handleOnSearch}
                            onHover={handleOnHover}
                            onSelect={handleOnSelect}
                            formatResult={formatResult}
                            />
                        ) : (
                          <div class='full-address-box'>
                            {fullAddress}
                          </div>
                        )}
                        <br/>
                      </div>
                      <div className='col-sm-12'>
                        <Label className='label-form-group'>Alamat Lengkap Pengiriman</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-map-marker" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Alamat Lengkap"
                            value={address} onChange={e => setAddress(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  </FormGroup>
                </CardBody>
              </Card>
              
              <Card className="shadow mb-20">
                <CardHeader>
                  Sosial Media dan Group
                </CardHeader>
                <CardBody>
                  <FormGroup className="mb-0">
                    <div className='row'>
                      <div className='col-sm-6'>
                        <Label className='label-form-group'>Link Instagram</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <Input
                            placeholder="Masukan Link Instagram"
                            value={instagram} onChange={e => setInstagram(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                      <div className='col-sm-6'>
                        <Label className='label-form-group'>Link Facebook Official</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <Input
                            placeholder="Masukan Link Facebook"
                            value={facebook} onChange={e => setFacebook(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                      <div className='col-sm-6'>
                        <Label className='label-form-group'>Link Group Facebook</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <Input
                            placeholder="Masukan Link Group Facebook"
                            value={groupFacebook} onChange={e => setGroupFacebook(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                      <div className='col-sm-6'>
                        <Label className='label-form-group'>Link Group Telegram</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <Input
                            placeholder="Masukan Link Group Telegram"
                            value={groupTelegram} onChange={e => setGroupTelegram(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                      <div className='col-sm-12'>
                        <Label className='label-form-group'>Link Youtube Official</Label>
                        <InputGroup className="input-group-alternative mb-20">
                          <Input
                            placeholder="Masukan Link Youtube"
                            value={youtube} onChange={e => setYoutube(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  </FormGroup>
                </CardBody>
              </Card>
            </div>

            <div className="col-md-4">
              <Card className="shadow mb-20">
                <CardBody>
                  { spinnerSave ? (
                    <Button className='full-width' color="primary" style={{background: (currentCompany.company_color3)}}>
                      <Dots color='#fff' />
                    </Button>
                  ) : (
                    <Button className='full-width' color="primary" style={{background: (currentCompany.company_color3)}} onClick={e => actPost()}>Simpan Perubahan</Button>
                  )}
                </CardBody>
              </Card>

              <Card className="shadow mb-20">
                <CardHeader>
                  Ganti Avatar Profil
                </CardHeader>
                <CardBody>
                  <Form role="form">
                    <FormGroup className="mb-3">
                      <FormGroup className="form-img-select">
                        <div class='avatar-user avatar-user-sidebar'>
                          <ImageUpload
                            handleImageSelect={handleImageSelect}
                            imageSrc={imageSrc}
                            setImageSrc={setImageSrc}
                            style={{
                              width: 120,
                              height: 120
                            }}
                          />
                        </div>
                      </FormGroup>
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>
              
              { (currentUser && currentUser.type === 'Company') || (currentUser && currentUser.type === 'Adminmaster') ? (
                <Card className="shadow mb-20">
                  <CardHeader>
                    Pengaturan Website Bisnis
                  </CardHeader>
                  <CardBody>
                    <div className='row'>
                      <div className='col-sm-6'>
                        <Label className='label-form-group'>Logo</Label>
                        <div className='image-galleries'>
                          <div className='image-gallery-list'>
                            <FormGroup className="form-img-select">
                              <div class='avatar-user avatar-user-sidebar2'>
                                <ImageUpload
                                  handleImageSelect={handleImageSelectLogo}
                                  imageSrc={companyLogoSrc}
                                  setImageSrc={setCompanyLogoSrc}
                                  style={{
                                    width: 120,
                                    height: 120
                                  }}
                                />
                              </div>
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-6'>
                        <Label className='label-form-group'>Icon</Label>
                        <div className='image-galleries'>
                          <div className='image-gallery-list'>
                            <FormGroup className="form-img-select">
                              <div class='avatar-user avatar-user-sidebar2'>
                                <ImageUpload
                                  handleImageSelect={handleImageSelectIcon}
                                  imageSrc={companyIconSrc}
                                  setImageSrc={setCompanyIconSrc}
                                  style={{
                                    width: 120,
                                    height: 120
                                  }}
                                />
                              </div>
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-12'><hr/></div>
                      <div className='col-sm-6'>
                        <Label className='label-form-group'>Warna Icon</Label>
                        <InputGroup className="input-group-alternative">
                          <Input
                            type="color"
                            placeholder="Masukan Kode Warna"
                            value={companyColor1} onChange={e => setCompanyColor1(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                      <div className='col-sm-6'>
                        <Label className='label-form-group'>Warna Background</Label>
                        <InputGroup className="input-group-alternative">
                          <Input
                            type="color"
                            placeholder="Masukan Kode Warna"
                            value={companyColor2} onChange={e => setCompanyColor2(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                      <div className='col-sm-6'>
                        <Label className='label-form-group'>Warna Button</Label>
                        <InputGroup className="input-group-alternative">
                          <Input
                            type="color"
                            placeholder="Masukan Kode Warna"
                            value={companyColor3} onChange={e => setCompanyColor3(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                      <div className='col-sm-6'>
                        <Label className='label-form-group'>Screen Background</Label>
                        <InputGroup className="input-group-alternative">
                          <Input
                            type="color"
                            placeholder="Masukan Kode Warna"
                            value={companyColor4} onChange={e => setCompanyColor4(e.target.value)}
                          />
                        </InputGroup>
                      </div>
                      <div className='col-sm-12'>
                        <Label className='label-form-group'>App Themeplate</Label>
                        <InputGroup className="input-group-alternative">
                          <Input type="select" value={themeplate} onChange={e => setThemeplate(e.target.value)}>
                            <option value=''>Pilih Theme</option>
                            <option value='App Theme 1'>App Theme 1</option>
                            <option value='App Theme 2'>App Theme 2</option>
                          </Input>
                        </InputGroup>
                      </div>
                      <div className='col-sm-12'>
                        <Label className='label-form-group'>Tipe App</Label>
                        <InputGroup className="input-group-alternative">
                          <Input type="select" value={companyAppType} onChange={e => setCompanyAppType(e.target.value)}>
                            <option value=''>Pilih Tipe</option>
                            <option value='onlinestore'>Onlinestore</option>
                            <option value='hotel'>Hotel</option>
                            <option value='reservation'>Reservation</option>
                            <option value='restaurant'>Restaurant</option>
                            <option value='showroom'>Showroom</option>
                          </Input>
                        </InputGroup>
                      </div>
                    </div>
                  </CardBody>
                </Card> ) : null 
              }

              { (currentUser && currentUser.type === 'Company') || (currentUser && currentUser.type === 'Adminmaster') ? (
                <>
                  {
                    data ? (
                      <Card className="shadow mb-20">
                        <CardHeader>
                          Pengaturan Fitur
                        </CardHeader>
                        <CardBody>
                          <div className='row'>
                            <div className='col-sm-12 use-feature-checkbox'>
                              <Input type="checkbox" defaultChecked={publishedHomepage} onChange={e => setPublishedHomepage(!publishedHomepage)}/> Logo Publish to Homepage Aplikabizz
                            </div>
                            <div className='col-sm-12 use-feature-checkbox'>
                              <Input type="checkbox" defaultChecked={useCod} onChange={e => setUseCod(!useCod)}/> Fitur COD
                            </div>
                            <div className='col-sm-12 use-feature-checkbox'>
                              <Input type="checkbox" defaultChecked={useTakeAway} onChange={e => setUseTakeAway(!useTakeAway)}/> Fitur Take Away
                            </div>
                            <div className='col-sm-12 use-feature-checkbox'>
                              <Input type="checkbox" defaultChecked={useSaldoVirtual} onChange={e => setUseSaldoVirtual(!useSaldoVirtual)}/> Fitur Saldo Virtual
                            </div>
                            <div className='col-sm-12 use-feature-checkbox'>
                              <Input type="checkbox" defaultChecked={usePointVirtual} onChange={e => setUsePointVirtual(!usePointVirtual)}/> Fitur Poin Virtual
                            </div>
                            <div className='col-sm-12 use-feature-checkbox'>
                              <Input type="checkbox" defaultChecked={useUpgradeUser} onChange={e => setUseUpgradeUser(!useUpgradeUser)}/> Fitur Upgrade User
                            </div>
                            <div className='col-sm-12 use-feature-checkbox'>
                              <Input type="checkbox" defaultChecked={useExcludePurchase} onChange={e => setUseExcludePurchase(!useExcludePurchase)}/> Dashboard Margin diambil dari HPP
                            </div>
                          </div>
                        </CardBody>
                      </Card> 
                    ) : null
                  }
                  <Card className="shadow mb-20">
                    <CardHeader>
                      Slider Gambar Halaman Utama App
                    </CardHeader>
                    <CardBody>
                      <div className='row'>
                        <div className='col-sm-12'>
                          <div className='image-galleries'>
                            <div className='image-gallery-list'>
                              <FormGroup className="form-img-select">
                                <div class='avatar-user avatar-user-sidebar'>
                                  <ImageUpload
                                    handleImageSelect={postGalleries}
                                    imageSrc={imageSrc2}
                                    setImageSrc={setImageSrc2}
                                    style={{
                                      width: 120,
                                      height: 120
                                    }}
                                  />
                                </div>
                              </FormGroup>
                            </div>

                            { 
                              galleries.map((i) => {
                                return (
                                  <div className='image-gallery-list'>
                                    <div className='image-gallery-delete' onClick={(e) => deleteGallery(i.id)}>
                                      <i className='ni ni-fat-remove'></i>
                                    </div>
                                    <img src={i.image_url}/>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card> 
                  <Card className="shadow mb-20">
                    <CardHeader>
                      Slider Promo Utama
                    </CardHeader>
                    <CardBody>
                      <div className='row'>
                        <div className='col-sm-12'>
                          <div className='image-galleries'>
                            <div className='image-gallery-list'>
                              <FormGroup className="form-img-select">
                                <div class='avatar-user avatar-user-sidebar'>
                                  <ImageUpload
                                    handleImageSelect={postGalleriesPromo}
                                    imageSrc={imageSrc2}
                                    setImageSrc={setImageSrc2}
                                    style={{
                                      width: 120,
                                      height: 120
                                    }}
                                  />
                                </div>
                              </FormGroup>
                            </div>

                            { 
                              galleriesPromo.map((i) => {
                                return (
                                  <div className='image-gallery-list'>
                                    <div className='image-gallery-delete' onClick={(e) => deleteGallery(i.id)}>
                                      <i className='ni ni-fat-remove'></i>
                                    </div>
                                    <img src={i.image_url}/>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card> 
                </>
                ) : null 
              }     
            </div>
          </Row>
        ) : null }
      </Container>
    </>
  );
};

export default CompanyEdit;
