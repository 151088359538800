import React, { useEffect, useState } from 'react';
import API from '../../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../../ReactSession';
import { useHistory, useParams } from "react-router-dom";
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import DatePicker from "reactstrap-date-picker";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Input,
  Modal,
  ModalHeader, ModalBody, ModalFooter,
  CardBody,
  Button,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
  Label
} from "reactstrap";

import Header from "components/Headers/Header.js";

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const LandingPressEdit = () => {
  const {id} = useParams();
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const history = useHistory();

  // ATTRIBUTES PRODUCT
  const [brandId, setBrandId] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const [companies, setCompanies] = useState([]);
  const [brands, setBrands] = useState([]);

  const [textSection3, setTextSection3] = useState('');
  const [textSection4, setTextSection4] = useState('');
  const [linkVideo, setLinkVideo] = useState('');
  
  const [bannerSection1File, setBannerSection1File] = useState();
  const [bannerSection1Src, setBannerSection1Src] = useState();

  const [img1Section2File, setImg1Section2File] = useState();
  const [img1Section2Src, setImg1Section2Src] = useState();
  const [img2Section2File, setImg2Section2File] = useState();
  const [img2Section2Src, setImg2Section2Src] = useState();
  const [img3Section2File, setImg3Section2File] = useState();
  const [img3Section2Src, setImg3Section2Src] = useState();

  const [img1Section3File, setImg1Section3File] = useState();
  const [img1Section3Src, setImg1Section3Src] = useState();
  const [img1Section4File, setImg1Section4File] = useState();
  const [img1Section4Src, setImg1Section4Src] = useState();

  const [img1EndorsFile, setImg1EndorsFile] = useState();
  const [img1EndorsSrc, setImg1EndorsSrc] = useState();
  const [img2EndorsFile, setImg2EndorsFile] = useState();
  const [img2EndorsSrc, setImg2EndorsSrc] = useState();
  const [img3EndorsFile, setImg3EndorsFile] = useState();
  const [img3EndorsSrc, setImg3EndorsSrc] = useState();
  const [img4EndorsFile, setImg4EndorsFile] = useState();
  const [img4EndorsSrc, setImg4EndorsSrc] = useState();
  const [img5EndorsFile, setImg5EndorsFile] = useState();
  const [img5EndorsSrc, setImg5EndorsSrc] = useState();
  const [img6EndorsFile, setImg6EndorsFile] = useState();
  const [img6EndorsSrc, setImg6EndorsSrc] = useState();

  const [img1TestimonialFile, setImg1TestimonialFile] = useState();
  const [img1TestimonialSrc, setImg1TestimonialSrc] = useState();
  const [img2TestimonialFile, setImg2TestimonialFile] = useState();
  const [img2TestimonialSrc, setImg2TestimonialSrc] = useState();
  const [img3TestimonialFile, setImg3TestimonialFile] = useState();
  const [img3TestimonialSrc, setImg3TestimonialSrc] = useState();
  const [img4TestimonialFile, setImg4TestimonialFile] = useState();
  const [img4TestimonialSrc, setImg4TestimonialSrc] = useState();
  const [img5TestimonialFile, setImg5TestimonialFile] = useState();
  const [img5TestimonialSrc, setImg5TestimonialSrc] = useState();
  const [img6TestimonialFile, setImg6TestimonialFile] = useState();
  const [img6TestimonialSrc, setImg6TestimonialSrc] = useState();

  const [spinner, setSpinner] = useState(true);
  const [spinnerSave, setSpinnerSave] = useState(false);

  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleImageSelect1 = (e) => {
    setBannerSection1Src(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setBannerSection1File(files);
  }

  const handleImageSelect2 = (e) => {
    setImg1Section2Src(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setImg1Section2File(files);
  }

  const handleImageSelect3 = (e) => {
    setImg2Section2Src(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setImg2Section2File(files);
  }

  const handleImageSelect4 = (e) => {
    setImg3Section2Src(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setImg3Section2File(files);
  }

  const handleImageSelect5 = (e) => {
    setImg1Section3Src(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setImg1Section3File(files);
  }

  const handleImageSelect6 = (e) => {
    setImg1Section4Src(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setImg1Section4File(files);
  }

  const handleImageSelect7 = (e) => {
    setImg1EndorsSrc(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setImg1EndorsFile(files);
  }

  const handleImageSelect8 = (e) => {
    setImg2EndorsSrc(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setImg2EndorsFile(files);
  }

  const handleImageSelect9 = (e) => {
    setImg3EndorsSrc(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setImg3EndorsFile(files);
  }

  const handleImageSelect10 = (e) => {
    setImg4EndorsSrc(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setImg4EndorsFile(files);
  }

  const handleImageSelect11 = (e) => {
    setImg5EndorsSrc(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setImg5EndorsFile(files);
  }

  const handleImageSelect12 = (e) => {
    setImg6EndorsSrc(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setImg6EndorsFile(files);
  }

  const handleImageSelect13 = (e) => {
    setImg1TestimonialSrc(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setImg1TestimonialFile(files);
  }

  const handleImageSelect14 = (e) => {
    setImg2TestimonialSrc(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setImg2TestimonialFile(files);
  }

  const handleImageSelect15 = (e) => {
    setImg3TestimonialSrc(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setImg3TestimonialFile(files);
  }

  const handleImageSelect16 = (e) => {
    setImg4TestimonialSrc(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setImg4TestimonialFile(files);
  }

  const handleImageSelect17 = (e) => {
    setImg5TestimonialSrc(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setImg5TestimonialFile(files);
  }

  const handleImageSelect18 = (e) => {
    setImg6TestimonialSrc(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setImg6TestimonialFile(files);
  }

  const actPost = () => {
    setSpinnerSave(true);
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };

    const fd = new FormData();
    if(bannerSection1File){
      fd.append('banner_section1', bannerSection1File, bannerSection1File.name);
    }
    if(img1Section2File){
      fd.append('img1_section2', img1Section2File, img1Section2File.name);
    }
    if(img2Section2File){
      fd.append('img2_section2', img2Section2File, img2Section2File.name);
    }
    if(img3Section2File){
      fd.append('img3_section2', img3Section2File, img3Section2File.name);
    }
    if(img1Section3File){
      fd.append('img1_section3', img1Section3File, img1Section3File.name);
    }
    if(img1Section4File){
      fd.append('img1_section4', img1Section4File, img1Section4File.name);
    }
    if(img1EndorsFile){
      fd.append('img1_endors', img1EndorsFile, img1EndorsFile.name);
    }
    if(img2EndorsFile){
      fd.append('img2_endors', img2EndorsFile, img2EndorsFile.name);
    }
    if(img3EndorsFile){
      fd.append('img3_endors', img3EndorsFile, img3EndorsFile.name);
    }
    if(img4EndorsFile){
      fd.append('img4_endors', img4EndorsFile, img4EndorsFile.name);
    }
    if(img5EndorsFile){
      fd.append('img5_endors', img5EndorsFile, img5EndorsFile.name);
    }
    if(img6EndorsFile){
      fd.append('img6_endors', img6EndorsFile, img6EndorsFile.name);
    }

    if(img1TestimonialFile){
      fd.append('img1_testimonial', img1TestimonialFile, img1TestimonialFile.name);
    }
    if(img2TestimonialFile){
      fd.append('img2_testimonial', img2TestimonialFile, img2TestimonialFile.name);
    }
    if(img3TestimonialFile){
      fd.append('img3_testimonial', img3TestimonialFile, img3TestimonialFile.name);
    }
    if(img4TestimonialFile){
      fd.append('img4_testimonial', img4TestimonialFile, img4TestimonialFile.name);
    }
    if(img5TestimonialFile){
      fd.append('img5_testimonial', img5TestimonialFile, img5TestimonialFile.name);
    }
    if(img6TestimonialFile){
      fd.append('img6_testimonial', img6TestimonialFile, img6TestimonialFile.name);
    }

    fd.append('brand_id', brandId);
    fd.append('user_id', companyId);
    fd.append('title', title);
    fd.append('description', description);
    fd.append('text_section3', textSection3);
    fd.append('text_section4', textSection4);
    fd.append('link_video', linkVideo);
    
    API.put(`landing_presses/${id}`, fd, config )
      .then(res => {
        setSpinnerSave(false);
        alert('Data berhasil diubah!');
      })
      .catch(error => {
        setVisible(true);
        setSpinnerSave(false);
        if(error === undefined){
          setErrorMessage('Gagal, Terjadi kesalahan di server')
        }else{
          setErrorMessage(error.response.data.meta.status.message);
        }
      })
    
  }

  const loadData = () => {    
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {include: '', by_user_type: 'Company' } };

    API.get(`landing_presses/${id}`, config)
      .then(res => {
        const data = res.data.data;
        setSpinner(false);
        setBrandId(data.brand_id);
        setCompanyId(data.user_id);
        setTitle(data.title);
        setDescription(data.description);
        setTextSection3(data.text_section3);
        setTextSection4(data.text_section4);
        setLinkVideo(data.link_video);

        setBannerSection1Src(data.banner_section1_url);
        setImg1Section2Src(data.img1_section2_url);
        setImg2Section2Src(data.img2_section2_url);
        setImg3Section2Src(data.img3_section2_url);
        setImg1Section3Src(data.img1_section3_url);
        setImg1Section4Src(data.img1_section4_url);
        setImg1EndorsSrc(data.img1_endors_url);
        setImg2EndorsSrc(data.img2_endors_url);
        setImg3EndorsSrc(data.img3_endors_url);
        setImg4EndorsSrc(data.img4_endors_url);
        setImg5EndorsSrc(data.img5_endors_url);
        setImg6EndorsSrc(data.img6_endors_url);
        setImg1TestimonialSrc(data.img1_testimonial_url);
        setImg2TestimonialSrc(data.img2_testimonial_url);
        setImg3TestimonialSrc(data.img3_testimonial_url);
        setImg4TestimonialSrc(data.img4_testimonial_url);
        setImg5TestimonialSrc(data.img5_testimonial_url);
        setImg6TestimonialSrc(data.img6_testimonial_url);
      })
      .catch(error => {
        if(error === undefined){
          alert.show('Gagal, Terjadi kesalahan di server')
        }else{
          alert.show(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  useEffect(() => {
    if(token === undefined){
      history.push("/")
    }

    loadData();

  },[])

  return (
    <>
      <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        
        { spinner === false ? (
          <Row>
            <div className="col-md-12">
              <Card className="shadow mb-20">
                <CardHeader>
                  <div style={{paddingTop: 10, float: 'left'}}>Pengaturan Landing Page</div>
                  { spinnerSave ? (
                    <Button className='pull-right' color="primary" style={{background: (currentCompany.company_color3)}}>
                      <Dots color='#fff' />
                    </Button>
                  ) : (
                    <Button className='pull-right' color="primary" style={{background: (currentCompany.company_color3)}} onClick={e => actPost()}>SIMPAN</Button>
                  )}
                </CardHeader>
                <CardBody>
                  <Alert color="warning" isOpen={visible} toggle={onDismiss}>
                    {errorMessage}
                  </Alert>
                  <div className='lp_section1'>
                    <ImageUpload handleImageSelect={handleImageSelect1} imageSrc={bannerSection1Src} setImageSrc={setBannerSection1Src} style={{width: '100%', height: 500}}/>
                  </div>
                  <div className='lp_section2'>
                    <Input placeholder="Masukan Judul Utama" value={title} onChange={e => setTitle(e.target.value)} style={{marginBottom:20, textAlign: 'center'}}/>
                    <div className='row'>
                      <div className='col-md-4' style={{marginBottom: 20}}>
                        <ImageUpload handleImageSelect={handleImageSelect2} imageSrc={img1Section2Src} setImageSrc={setImg1Section2Src} style={{width: '100%', height: 300}}/>
                      </div>
                      <div className='col-md-4' style={{marginBottom: 20}}>
                        <ImageUpload handleImageSelect={handleImageSelect3} imageSrc={img2Section2Src} setImageSrc={setImg2Section2Src} style={{width: '100%', height: 300}}/>
                      </div>
                      <div className='col-md-4' style={{marginBottom: 20}}>
                        <ImageUpload handleImageSelect={handleImageSelect4} imageSrc={img3Section2Src} setImageSrc={setImg3Section2Src} style={{width: '100%', height: 300}}/>
                      </div>
                      <div className='col-md-12'>
                        <Input type='textarea' placeholder="Masukan Deskripsi" value={description} onChange={e => setDescription(e.target.value)} style={{marginBottom:20, height: 200}}/>
                      </div>
                    </div>
                  </div>
                  <div className='lp_section3'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <Input type='textarea' placeholder="Masukan Deskripsi" value={textSection3} onChange={e => setTextSection3(e.target.value)} style={{marginBottom:20, height: 400}}/>
                      </div>
                      <div className='col-md-6'>
                        <ImageUpload handleImageSelect={handleImageSelect5} imageSrc={img1Section3Src} setImageSrc={setImg1Section3Src} style={{width: '100%', height: 400}}/>
                      </div>
                    </div>
                  </div>
                  <div className='lp_section3'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <ImageUpload handleImageSelect={handleImageSelect6} imageSrc={img1Section4Src} setImageSrc={setImg1Section4Src} style={{width: '100%', height: 400}}/>
                      </div>
                      <div className='col-md-6'>
                        <Input type='textarea' placeholder="Masukan Deskripsi" value={textSection4} onChange={e => setTextSection4(e.target.value)} style={{marginBottom:20, height: 400}}/>
                      </div>
                    </div>
                  </div>
                  <div className='lp_section2'>
                    <h2 align='center'>Sudah Banyak Yang Membuktikan</h2>
                    <div className='row'>
                      <div className='col-md-4' style={{marginBottom: 20}}>
                        <ImageUpload handleImageSelect={handleImageSelect7} imageSrc={img1EndorsSrc} setImageSrc={setImg1EndorsSrc} style={{width: '100%', height: 300}}/>
                      </div>
                      <div className='col-md-4' style={{marginBottom: 20}}>
                        <ImageUpload handleImageSelect={handleImageSelect8} imageSrc={img2EndorsSrc} setImageSrc={setImg2EndorsSrc} style={{width: '100%', height: 300}}/>
                      </div>
                      <div className='col-md-4' style={{marginBottom: 20}}>
                        <ImageUpload handleImageSelect={handleImageSelect9} imageSrc={img3EndorsSrc} setImageSrc={setImg3EndorsSrc} style={{width: '100%', height: 300}}/>
                      </div>
                      <div className='col-md-4' style={{marginBottom: 20}}>
                        <ImageUpload handleImageSelect={handleImageSelect10} imageSrc={img4EndorsSrc} setImageSrc={setImg4EndorsSrc} style={{width: '100%', height: 300}}/>
                      </div>
                      <div className='col-md-4' style={{marginBottom: 20}}>
                        <ImageUpload handleImageSelect={handleImageSelect11} imageSrc={img5EndorsSrc} setImageSrc={setImg5EndorsSrc} style={{width: '100%', height: 300}}/>
                      </div>
                      <div className='col-md-4' style={{marginBottom: 20}}>
                        <ImageUpload handleImageSelect={handleImageSelect12} imageSrc={img6EndorsSrc} setImageSrc={setImg6EndorsSrc} style={{width: '100%', height: 300}}/>
                      </div>
                    </div>
                  </div>
                  <div className='lp_section1' style={{height: 'auto', background: 'white'}}>
                    <div className='row'>
                      <div className='col-md-12'>
                        <div class="video-container">
                          <iframe width="100%" height="480" src={`//www.youtube.com/embed/${linkVideo}?rel=0`} frameborder="0" allowfullscreen></iframe>
                        </div>
                        <Input placeholder="Kode Link Youtube" value={linkVideo} onChange={e => setLinkVideo(e.target.value)} style={{marginBottom:10, marginTop:10, textAlign: 'center'}}/>
                      </div>
                    </div>
                  </div>
                  <div className='lp_section2'>
                    <h2 align='center'>Testimonial</h2>
                    <div className='row'>
                      <div className='col-md-4' style={{marginBottom:20}}>
                        <ImageUpload handleImageSelect={handleImageSelect13} imageSrc={img1TestimonialSrc} setImageSrc={setImg1TestimonialSrc} style={{width: '100%', height: 300}}/>
                      </div>
                      <div className='col-md-4' style={{marginBottom:20}}>
                        <ImageUpload handleImageSelect={handleImageSelect14} imageSrc={img2TestimonialSrc} setImageSrc={setImg2TestimonialSrc} style={{width: '100%', height: 300}}/>
                      </div>
                      <div className='col-md-4' style={{marginBottom:20}}>
                        <ImageUpload handleImageSelect={handleImageSelect15} imageSrc={img3TestimonialSrc} setImageSrc={setImg3TestimonialSrc} style={{width: '100%', height: 300}}/>
                      </div>
                      <div className='col-md-4' style={{marginBottom:20}}>
                        <ImageUpload handleImageSelect={handleImageSelect16} imageSrc={img4TestimonialSrc} setImageSrc={setImg4TestimonialSrc} style={{width: '100%', height: 300}}/>
                      </div>
                      <div className='col-md-4' style={{marginBottom:20}}>
                        <ImageUpload handleImageSelect={handleImageSelect17} imageSrc={img5TestimonialSrc} setImageSrc={setImg5TestimonialSrc} style={{width: '100%', height: 300}}/>
                      </div>
                      <div className='col-md-4' style={{marginBottom:20}}>
                        <ImageUpload handleImageSelect={handleImageSelect18} imageSrc={img6TestimonialSrc} setImageSrc={setImg6TestimonialSrc} style={{width: '100%', height: 300}}/>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Row>
        ) : null }
      </Container>
    </>
  );
};

export default LandingPressEdit;
