import React, { useEffect, useState } from 'react';
import API from '../../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../../ReactSession';
import { useHistory, useParams } from "react-router-dom";
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import DatePicker from "reactstrap-date-picker";
import classnames from "classnames";
import Chart from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import dateFormat from "dateformat";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Input,
  Modal,
  ModalHeader, ModalBody, ModalFooter,
  CardBody,
  Button,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
  Label, Col, CardTitle,
  NavItem,
  NavLink,
  Nav
} from "reactstrap";

import Header from "components/Headers/Header.js";

import {
  chartOptions,
  parseOptions
} from "variables/charts.js";


function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const StaffShow = () => {
  let {id} = useParams();
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const history = useHistory();

  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [spinnerSave, setSpinnerSave] = useState(false);

  const [searchStartDate, setSearchStartDate] = useState('');
  const [searchEndDate, setSearchEndDate] = useState('');
  const [report, setReport] = useState({});
  const [report2, setReport2] = useState({});
  const [reportChartDaily, setReportChartDaily] = useState([]);
  const [reportChartMonthly, setReportChartMonthly] = useState([]);

  const [activeNav, setActiveNav] = useState(1);
  const [chartData, setChartData] = useState("data1");

  const [offset, setOffset] = useState(1);
  const [paging, setPaging] = useState(true);
  const [loadMore, setLoadMore] = useState(true);

  const [offsetMonthly, setOffsetMonthly] = useState(1);
  const [pagingMonthly, setPagingMonthly] = useState(true);
  const [loadMoreMonthly, setLoadMoreMonthly] = useState(true);

  const [spinnerOrderDaily, setSpinnerOrderDaily] = useState(true);
  const [spinnerOrderMonthly, setSpinnerOrderMonthly] = useState(true);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartData("data" + index);
  };

  const loadData = (start_date, end_date) => {    
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {include: '', by_user_type: 'Staff' } };
    API.get(`users/${id}`, config)
      .then(res => {
        const data = res.data.data;
        setData(data);

        id = data.role_access === 'cs' ? id : null
        const config2 = { headers: { Authorization: `Bearer ${token}`}, params: {by_staff_id: id, by_status_payment: 'paid', category_date: 'paid_date', start_at: start_date, end_at: end_date}};
        API.get(`report_user`, config2)
          .then(res => {
            const data = res.data.report;
            setReport(data)
          })
        
        const config3 = { headers: { Authorization: `Bearer ${token}`}, params: {by_category_status: 'Repeat Order', by_staff_id: id, by_status_payment: 'paid', category_date: 'paid_date', start_at: start_date, end_at: end_date}};
          API.get(`report_user`, config3)
            .then(res => {
              const data = res.data.report;
              setReport2(data)
            })
        
        loadChartMonthly(start_date, end_date, id);
        loadChartDaily(start_date, end_date, id);
      })
      .catch(error => {
        if(error === undefined){
          alert.show('Gagal, Terjadi kesalahan di server')
        }else{
          alert.show(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
    
    setOffset(1);
    setOffsetMonthly(1);
  }

  const loadMoreData = (id) => {
    setLoadMore(true);
    setOffset(offset+1)
    let offset_page = offset+1

    const config = { headers: { Authorization: `Bearer ${token}`}, params: {by_staff_id: id, page: offset_page, category: 'group_by_daily_order', category_date: 'paid_date', start_at: searchStartDate, end_at: searchEndDate}};
    API.get(`report_chart`, config)
      .then(res => {
        setLoadMore(false);
        const data2 = res.data.report;
        setReportChartDaily([...reportChartDaily, ...data2]);
        if(res.data.total_pages === offset_page){
          setPaging(false);
        }
      }).catch(error => {
        console.log(error.response);
      })
  }

  const loadMoreDataMonthly = (id) => {
    setLoadMoreMonthly(true);
    setOffsetMonthly(offsetMonthly+1)
    let offset_page = offsetMonthly+1

    const config = { headers: { Authorization: `Bearer ${token}`}, params: {by_staff_id: id, page: offset_page, category: 'group_by_monthly_order', category_date: 'paid_date', start_at: searchStartDate, end_at: searchEndDate}};
    API.get(`report_chart`, config)
      .then(res => {
        setLoadMoreMonthly(false);
        const data2 = res.data.report;
        setReportChartMonthly([...reportChartMonthly, ...data2]);
        if(res.data.total_pages === offset_page){
          setPagingMonthly(false);
        }
      }).catch(error => {
        console.log(error.response);
      })
  }

  const updateSearchStartDate = (start_date) => {
    setSearchStartDate(start_date)
    loadData(start_date, searchEndDate);
  }

  const updateSearchEndDate = (end_date) => {
    setSearchEndDate(end_date)
    loadData(searchStartDate, end_date);
  }

  const loadChartDaily = (start_date, end_date, id) =>{
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {by_staff_id: id, category: 'group_by_daily_order', category_date: 'paid_date', start_at: start_date, end_at: end_date}};
    API.get(`report_chart`, config)
      .then(res => {
        setSpinnerOrderDaily(false);
        const data = res.data.report;
        setReportChartDaily(data);
        setLoadMore(false);
        if(res.data.total_pages <= 1){
          setPaging(false);
          console.log('No paging')
        }else{
          setPaging(true);
          console.log('Yes paging')
        }
      }).catch(error => {
        console.log(error.response);
      })
  }

  const loadChartMonthly = (start_date, end_date, id) =>{
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {by_staff_id: id, category: 'group_by_monthly_order', category_date: 'paid_date', start_at: start_date, end_at: end_date}};
    API.get(`report_chart`, config)
      .then(res => {
        setSpinnerOrderMonthly(false);
        const data = res.data.report;
        setReportChartMonthly(data);
        setLoadMoreMonthly(false);
        if(res.data.total_pages <= 1){
          setPagingMonthly(false);
          console.log('No paging')
        }else{
          setPagingMonthly(true);
          console.log('Yes paging')
        }
      }).catch(error => {
        console.log(error.response);
      })
  }

  var colors = {
    gray: {
      100: "#f6f9fc",
      200: "#e9ecef",
      300: "#dee2e6",
      400: "#ced4da",
      500: "#adb5bd",
      600: "#8898aa",
      700: "#525f7f",
      800: "#32325d",
      900: "#212529",
    },
    theme: {
      default: "#172b4d",
      primary: "#5e72e4",
      secondary: "#f4f5f7",
      info: "#11cdef",
      success: "#2dce89",
      danger: "#f5365c",
      warning: "#fb6340",
    },
    black: "#12263F",
    white: "#FFFFFF",
    transparent: "transparent",
  };

  let chartOrderCount = {
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              callback: function (value) {
                if (!(value % 10)) {
                  //return '$' + value + 'k'
                  return value;
                }
              },
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (item, data) {
            var label = data.datasets[item.datasetIndex].label || "";
            var yLabel = item.yLabel;
            var content = "";
            if (data && data.datasets && data.datasets.length > 1) {
              content += label;
            }
            content += yLabel;
            return content;
          },
        },
      },
    },
    data: {
      labels: reportChartMonthly.map((d) => dateFormat(d.month, "mm/yyyy")).reverse(),
      datasets: [
        {
          label: "Sales",
          data: reportChartMonthly.map((d) => d.count).reverse(),
          maxBarThickness: 10,
        },
      ],
    },
  };

  let chartOrderSum = {
    options: {
      scales: {
        yAxes: [
          {
            gridLines: {
              color: colors.gray[900],
              zeroLineColor: colors.gray[900],
            },
            ticks: {
              callback: function (value) {
                if (!(value % 10)) {
                  return numberFormat(value);
                }
              },
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (item, data) {
            var label = data.datasets[item.datasetIndex].label || "";
            var yLabel = item.yLabel;
            var content = "";
  
            if (data && data.datasets && data.datasets.length > 1) {
              content += label;
            }
  
            content += numberFormat(yLabel);
            return content;
          },
        },
      },
    },
    data1: (canvas) => {
      return {
        labels: reportChartDaily.map((d) => d.date),
        datasets: [
          {
            label: "Performance",
            data: reportChartDaily.map((d) => d.store_price),
          },
        ],
      };
    },
    data2: (canvas) => {
      return {
        labels: reportChartMonthly.map((d) => dateFormat(d.month, "mm/yyyy")).reverse(),
        datasets: [
          {
            label: "Performance",
            data: reportChartMonthly.map((d) => d.store_price).reverse(),
          },
        ],
      };
    },
  };

  useEffect(() => {
    if(token === undefined){
      history.push("/")
    }

    loadData();
  
  },[])

  return (
    <>
      <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className='col-md-12'>
          { data.role_access === 'cs' || data.role_access == 'admin' || data.role_access == 'finance' ? (
              <>
                <Row>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              USER
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {report.count_order_members ? numberFormat(report.count_order_members) : numberFormat(0)}
                            </span>
                            <div>
                              Baru <b>{report.count_order_members ? numberFormat(report.count_order_members-report2.count_order_members) : numberFormat(0)}</b>
                              <br/>
                              Repeat Order <b>{report2.count_order_members ? numberFormat(report2.count_order_members) : numberFormat(0)}</b>
                            </div>
                          </div>
                          <Col className="col-auto padding0">
                            <div className="icon icon-shape bg-info-new text-white rounded-circle shadow" style={{background: (currentCompany ? currentCompany.company_color1 : '')}}>
                              <i className="fas fa-users" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              PESANAN
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {report.order_count ? numberFormat(report.order_count) : numberFormat(0)}
                            </span>
                            <div>
                              Baru <b>{report.order_count ? numberFormat(report.order_count-report2.order_count) : numberFormat(0)}</b>
                              <br/>
                              Repeat Order <b>{report2.order_count ? numberFormat(report2.order_count) : numberFormat(0)}</b>
                            </div>
                          </div>
                          <Col className="col-auto padding0">
                            <div className="icon icon-shape bg-info-new text-white rounded-circle shadow" style={{background: (currentCompany ? currentCompany.company_color1 : '')}}>
                              <i className="fas fa-truck" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              LABA PENJUALAN
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {report.order_store ? numberFormat(report.order_store) : numberFormat(0)}
                            </span>
                            <div>
                              Baru <b>{report.order_store ? numberFormat(report.order_store-report2.order_store) : numberFormat(0)}</b>
                              <br/>
                              Repeat Order <b>{report2.order_store ? numberFormat(report2.order_store) : numberFormat(0)}</b>
                            </div>
                          </div>
                          <Col className="col-auto padding0">
                            <div className="icon icon-shape bg-info-new text-white rounded-circle shadow" style={{background: (currentCompany ? currentCompany.company_color1 : '')}}>
                              <i className="fas fa-chart-bar" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              KOMISI
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {report.staff_commision ? numberFormat(parseInt(report.staff_commision) + parseInt(report2.staff_commision_repeat_order)) : numberFormat(0)}
                            </span>
                            <div>
                              Baru <b>{report.staff_commision ? numberFormat(report.staff_commision) : numberFormat(0)}</b>
                              <br/>
                              Repeat Order <b>{report2.staff_commision_repeat_order ? numberFormat(report2.staff_commision_repeat_order) : numberFormat(0)}</b>
                            </div>
                          </div>
                          <Col className="col-auto padding0">
                            <div className="icon icon-shape bg-info-new text-white rounded-circle shadow" style={{background: (currentCompany ? currentCompany.company_color1 : '')}}>
                              <i className="fas fa-coins" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              ONGKIR
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {report.order_shipping ? numberFormat(parseInt(report.order_shipping)+parseInt(report.order_shipping_cod)) : numberFormat(0)}
                            </span>
                            <div>
                              Baru <b>{report.order_shipping ? numberFormat((parseInt(report.order_shipping)+parseInt(report.order_shipping_cod))-(parseInt(report2.order_shipping)+parseInt(report2.order_shipping_cod))) : numberFormat(0)}</b>
                              <br/>
                              Repeat Order <b>{report2.order_shipping ? numberFormat(parseInt(report2.order_shipping)+parseInt(report2.order_shipping_cod)) : numberFormat(0)}</b>
                            </div>
                          </div>
                          <Col className="col-auto padding0">
                            <div className="icon icon-shape bg-info-new text-white rounded-circle shadow" style={{background: (currentCompany ? currentCompany.company_color1 : '')}}>
                              <i className="fas fa-truck" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              SUBSIDI
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {report.subsidi ? numberFormat(report.subsidi) : numberFormat(0)}
                            </span>
                            <div>
                              Baru <b>{report.subsidi ? numberFormat(report.subsidi-report2.subsidi) : numberFormat(0)}</b>
                              <br/>
                              Repeat Order <b>{report2.subsidi ? numberFormat(report2.subsidi) : numberFormat(0)}</b>
                            </div>
                          </div>
                          <Col className="col-auto padding0">
                            <div className="icon icon-shape bg-info-new text-white rounded-circle shadow" style={{background: (currentCompany ? currentCompany.company_color1 : '')}}>
                              <i className="fas fa-filter" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              MARGIN
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {
                                numberFormat(report.order_store ? (report.order_store-report.order_basic) : 0)
                              }
                            </span>
                            <div>
                              Baru <b>{report.order_store ? numberFormat((report.order_store-report.order_basic)-(report2.order_store-report2.order_basic)) : numberFormat(0)}</b>
                              <br/>
                              Repeat Order <b>{report2.order_store ? numberFormat((report2.order_store-report2.order_basic)) : numberFormat(0)}</b>
                            </div>
                          </div>
                          <Col className="col-auto padding0">
                            <div className="icon icon-shape bg-info-new text-white rounded-circle shadow" style={{background: (currentCompany ? currentCompany.company_color1 : '')}}>
                              <i className="fas fa-random" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0 m-b20">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                              LABA BERSIH
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {
                                numberFormat(report.order_store ? (report.order_store-report.order_basic-report.subsidi) : 0)
                              }
                            </span>
                            <div>
                              Baru <b>{report.order_store ? numberFormat((report.order_store-report.order_basic-report.subsidi)-(report2.order_store-report2.order_basic-report2.subsidi)) : numberFormat(0)}</b>
                              <br/>
                              Repeat Order <b>{report2.order_store ? numberFormat((report2.order_store-report2.order_basic-report2.subsidi)) : numberFormat(0)}</b>
                            </div>
                          </div>
                          <Col className="col-auto padding0">
                            <div className="icon icon-shape bg-info-new text-white rounded-circle shadow" style={{background: (currentCompany ? currentCompany.company_color1 : '')}}>
                              <i className="fas fa-coins" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <br/>
              </>
            ) : null }
          </div>
          <div className="col-md-3">
            <Card className="shadow mb-20">
              <CardBody>
                <div style={{marginBottom:15}}>
                  <img src={data.avatar_url} style={{width:'100%'}}/>
                </div>
                <div style={{marginBottom:10, fontWeight:'bold', paddingLeft:12}}>STATUS AKUN</div>
                <Table className="align-items-center table-flush" responsive>
                  <tr> 
                    <td width='30%'><b>Akun</b></td>
                    <td>: {data.verified ? 'Aktif' : 'Blum Aktif'}</td>
                  </tr>
                  { data.role_access === 'cs' ? (
                    <tr> 
                      <td><b>% Komisi RO User</b></td>
                      <td>: {data.staff_commision_repeat_order ? data.staff_commision_repeat_order+'%'+' Dari Omset' : ""}</td>
                    </tr>
                  ) : null}
                </Table>
              </CardBody>
            </Card>
          </div>
          <div className="col-md-9">
            { data.role_access === 'cs' || data.role_access == 'admin' || data.role_access == 'finance' ? (
              <Card className="bg-gradient-default shadow mb-20">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-light ls-1 mb-1">
                        Overview
                      </h6>
                      <h2 className="text-white mb-0">Statistik Omset</h2>
                    </div>
                    <div className="col">
                      <Row>
                        <div className='col-sm-6 datepicker-chart'>
                          <DatePicker id= "example-datepicker" value={searchStartDate} onChange={e => updateSearchStartDate(e)} />
                        </div>
                        <div className='col-sm-6 datepicker-chart'>
                          <DatePicker id= "example-datepicker2" value={searchEndDate} onChange={e => updateSearchEndDate(e)} />
                        </div>
                      </Row>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Nav className="justify-content-end" pills>
                    <NavItem>
                      <NavLink
                        className={classnames("py-2 px-3", {
                          active: activeNav === 1,
                        })}
                        href="#pablo"
                        onClick={(e) => toggleNavs(e, 1)}
                      >
                        <span className="d-none d-md-block">Harian</span>
                        <span className="d-md-none">Hari</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames("py-2 px-3", {
                          active: activeNav === 2,
                        })}
                        data-toggle="tab"
                        href="#pablo"
                        onClick={(e) => toggleNavs(e, 2)}
                      >
                        <span className="d-none d-md-block">Bulanan</span>
                        <span className="d-md-none">Bulan</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <div className="chart">
                    <Line
                      data={chartOrderSum[chartData]}
                      options={chartOrderSum.options}
                      getDatasetAtEvent={(e) => console.log(e)}
                    />
                  </div>
                </CardBody>
              </Card>
            ) : null }

            <Card className="shadow mb-20">
              <CardBody>
                <div style={{marginBottom:10, fontWeight:'bold', paddingLeft:12}}>DETAIL AKUN</div>
                <Table className="align-items-center table-flush" responsive>
                  <tr>
                    <td width='20%'><b>Akses</b></td>
                    <td>: {data.role_access}</td>
                  </tr>
                  <tr>
                    <td><b>Username</b></td>
                    <td>: {data.username}</td>
                  </tr>
                  <tr>
                    <td><b>Email</b></td>
                    <td>: {data.email}</td>
                  </tr>
                  <tr>
                    <td><b>Dibuat Sejak</b></td>
                    <td>: {data.created_at}</td>
                  </tr>
                </Table>
              </CardBody>
            </Card>

            <Card className="shadow mb-20">
              <CardBody>
                <div style={{marginBottom:10, fontWeight:'bold', paddingLeft:12}}>PROFIL USER</div>
                <Table className="align-items-center table-flush" responsive>
                  <tr>
                    <td width='20%'><b>Nama Lengkap</b></td>
                    <td>: {data.full_name}</td>
                  </tr>
                  <tr>
                    <td><b>Jenis Kelamin</b></td>
                    <td>: {data.gender}</td>
                  </tr>
                  <tr>
                    <td><b>Tanggal Lahir</b></td>
                    <td>: {data.birthday}</td>
                  </tr>
                  <tr>
                    <td><b>No.HP 1</b></td>
                    <td>: {data.phone1}</td>
                  </tr>
                  <tr>
                    <td><b>No.HP 2</b></td>
                    <td>: {data.phone2}</td>
                  </tr>
                  <tr>
                    <td><b>Alamat</b></td>
                    <td>: {data.address}, {data.district}, {data.city}, {data.province}</td>
                  </tr>
                </Table>
              </CardBody>
            </Card>

            { data.role_access === 'cs' ? (
              <Card className="shadow mb-20">
                <CardBody>
                  <div style={{marginBottom:10, fontWeight:'bold', paddingLeft:12}}>WA API</div>
                  <Table className="align-items-center table-flush" responsive>
                    <tr>
                      <td width='20%'><b>Provider</b></td>
                      <td>: {data.wa_key_type}</td>
                    </tr>
                    <tr>
                      <td><b>Provider Key</b></td>
                      <td>: {data.wa_key}</td>
                    </tr>
                  </Table>
                </CardBody>
              </Card>
            ) : null}

          </div>
        </Row>
      </Container>
    </>
  );
};

export default StaffShow;

const styles: StyleSheet = {
  status: (status) => ({
    fontSize: 11,
    color: status === true ? '#1963da' : 'red',
    paddingBottom: 2,
    borderRadius: 5,
  }),
  status_verified: (status) => ({
    fontSize: 11,
    color: status === true ? '#1963da' : '#ccc',
    paddingBottom: 2,
    borderRadius: 5,
  })
}