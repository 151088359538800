import React, { useEffect, useState } from 'react';
import API from '../../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../../ReactSession';
import { useHistory, Link } from "react-router-dom";
import AuthFooter from "components/Footers/AuthFooter.js";
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import { Helmet } from 'react-helmet';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
  Alert
} from "reactstrap";

const Register = () => {
  const history = useHistory();
  const [domain, setDomain] = useState('');
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [phone, setPhone] = useState('');
  const [companyIndustry, setCompanyIndustry] = useState('');
  const [password, setPassword] = useState('');
  const [packageId, setPackageId] = useState('');
  const [company, setCompany] = useState({});
  const [packages, setPackages] = useState([]);
  const [spinnerSave, setSpinnerSave] = useState(false);

  const [success, setSuccess] = useState(false);
  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);
  const onDismissSuccess = () => setSuccess(false);
  const [errorMessage, setErrorMessage] = useState('');

  const loadData = () => {
    // let hostName = window.location.hostname.split('.').slice(1).join('.')
    let hostName = window.location.hostname;
    setDomain(hostName);
    const config = { params: {domain: hostName, include: ''} };

    API.get(`get_company`, config )
      .then(res => {
        const data = res.data.data;
        setCompany(data);
      })

    API.get(`packages`, config )
      .then(res => {
        const data = res.data.data;
        setPackages(data);
      })
  }

  const actRegister = () => {
    setSpinnerSave(true);
    API.post('/register_company', {full_name: fullName, email: email, phone1: phone, company_name: companyName, company_industry: companyIndustry, password: password, last_package_id: packageId, include: 'company, last_billing, last_package'} )
      .then(res => {
        setSpinnerSave(false);
        setFullName('');
        setEmail('');
        setCompanyName('');
        setPhone('');
        setCompanyIndustry('');
        setPackageId('');
        setPassword('');
        setSuccess(true);
      })
      .catch(error => {
        setSpinnerSave(false);
        setVisible(true);
        if(error === undefined){
          setErrorMessage('Pendaftaran gagal, Terjadi kesalahan di server')
        }else{
          setErrorMessage(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  useEffect(() => {
    loadData('');
  },[])

  return (
    <>
      { company ? (
        <Helmet>
          <title>{company ? company.full_name : 'Dashboard'}</title>
          <meta name="description" content="" />
          <link rel="apple-touch-icon" href={company.avatar_url} />
          <link rel="icon" href={company.avatar_url} />
        </Helmet>
      ) : null}

      <div className="header py-7 py-lg-8 pb-0">
        <Container>
          <div className="header-body text-center mb-4">
            <Row className="justify-content-center">
              <Col lg="4" md="7">
                <a href="https://www.onlinestore.web.id" >
                  {
                    company ? (
                      <img
                          alt="..."
                          style={{width:'90%'}}
                          src={
                            company.company_icon_url
                          }
                        />
                    ) : (
                      <img
                          alt="..."
                          style={{width:'90%'}}
                          src="https://s3.ap-southeast-1.amazonaws.com/cdn.onlinestore.web.id/official-logo.png"
                        />
                    )
                  }
                </a>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <Container className="pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent">
                <div className="text-muted text-center mt-2">
                  <div className="text-center text-muted">
                    <h1 style={{marginBottom: 10, color: '#444'}}>Daftar</h1>
                  </div>
                  <p>Tingkatkan Bisnis Perusahamu Sekarang</p>
                </div>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form">
                  <Alert color="warning" isOpen={visible} toggle={onDismiss}>
                    {errorMessage}
                  </Alert>
                  <Alert color="success" isOpen={success} toggle={onDismissSuccess}>
                    Pendaftaran berhasil, kami akan verifikasi pendaftaran anda terlebih dahulu silahkan tunggu email balasan dari kami! Terimakasih
                  </Alert>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-building" style={{color: (company ? company.company_color1 : '')}} />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Masukan Nama Bisnis/Perusahaan"
                        value={fullName} onChange={e => setFullName(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" style={{color: (company ? company.company_color1 : '')}} />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Masukan Email"
                        value={email} onChange={e => setEmail(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-mobile-button" style={{color: (company ? company.company_color1 : '')}} />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Masukan No Kontak"
                        value={phone} onChange={e => setPhone(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  {/* <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-building" style={{color: (company ? company.company_color1 : '')}} />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Masukan Nama Bisnis/Perusahaan"
                        value={companyName} onChange={e => setCompanyName(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup> */}
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-shop" style={{color: (company ? company.company_color1 : '')}} />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Industri Bisnis: Fashion, F&B, etc."
                        value={companyIndustry} onChange={e => setCompanyIndustry(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <Input type="select" value={packageId} onChange={e => setPackageId(e.target.value)}>
                        <option value=''>Pilih Paket</option>
                        {
                          packages.map((p)=>{
                            return(
                              <option value={p.id}>{p.title}</option>
                            )
                          })
                        }
                      </Input>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" style={{color: (company ? company.company_color1 : '')}} />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Kata Sandi"
                        type="password"
                        value={password} onChange={e => setPassword(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    { spinnerSave ? (
                      <Button className="full-width btn-lg" style={{marginbottom: 20, background: (company ? company.company_color3 : '')}} color="primary" type="button">
                        <Dots color='#fff' />
                      </Button>
                    ) : (
                      <Button className="full-width btn-lg" style={{marginbottom: 20, background: (company ? company.company_color3 : '')}} color="primary" type="button" onClick={actRegister}>
                        DAFTAR SEKARANG
                      </Button>
                    )}
                  </div>
                  <div className="text-muted font-italic" style={{paddingTop:20}}>
                    <small>
                      Anda Sudah Punya Akun ? <Link to="/auth/login" >Login Sekarang</Link>
                    </small>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Register;
