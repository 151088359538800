import React, { useEffect, useState } from 'react';
import API from '../../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../../ReactSession';
import { useHistory } from "react-router-dom";
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Input,
  Modal,
  ModalHeader, ModalBody, ModalFooter,
  Button,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
  Label
} from "reactstrap";

import Header from "components/Headers/Header.js";

const Outlets = () => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const history = useHistory();

  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [verified, setVerified] = useState(false);
  const [phone1, setPhone1] = useState('');
  const [phone2, setPhone2] = useState('');
  const [address, setAddress] = useState('');
  const [clockStart, setClockStart] = useState('');
  const [clockEnd, setClockEnd] = useState('');
  const [addressCode, setAddressCode] = useState('');
  const [fullAddress, setFullAddress] = useState('');
  const [editAddress, setEditAddress] = useState(false);

  const [queryDistrict, setQueryDistrict] = useState('');
  const [districts, setDistricts] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(1);
  const [paging, setPaging] = useState(true);
  const [search, setSearch] = useState('');
  const [spinner, setSpinner] = useState(true);
  const [loadMore, setLoadMore] = useState(true);
  const [loadMoreButton, setLoadMoreButton] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [spinnerSave, setSpinnerSave] = useState(false);

  const [modal, setModal] = useState(false);
  const toggle = (id) => {
    if(id){
      let dt = data.filter(o => o.id === id)[0]
      if(dt){
        setId(dt.id);
        setName(dt.name);
        setCompanyId(dt.company_id);
        setVerified(dt.verified);
        setPhone1(dt.phone1);
        setPhone2(dt.phone2);
        setAddress(dt.address);
        setAddressCode(dt.address_code);
        setFullAddress(`${dt.district}, ${dt.city}, ${dt.province}`);
        setClockStart(dt.clock_start);
        setClockEnd(dt.clock_end);
      }else{
        setId('');
        setName('');
        setCompanyId('');
        setVerified(false);
        setPhone1('');
        setPhone2('');
        setAddress('');
        setAddressCode('');
        setFullAddress();
        setClockStart('');
        setClockEnd('');
      }
    }else{
      setId('');
      setName('');
      setCompanyId('');
      setVerified(false);
      setPhone1('');
      setPhone2('');
      setAddress('');
      setAddressCode('');
      setFullAddress();
      setClockStart('');
      setClockEnd('');
    }
    setModal(!modal);
  }

  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleOnSearch = (string, results) => {
    console.log(string, results)
    findDistrict(string)
  }

  const handleOnHover = (result) => {
    console.log(result)
  }

  const handleOnSelect = (item) => {
    setAddressCode(item.id)
  }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const formatResult = (item) => {
    return (<div dangerouslySetInnerHTML={{__html: '<span>'+item.name+'</span>'}}></div>); //To format result as html
    // return item;
   // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
  }

  const findDistrict = (query) => {
    setQueryDistrict(query);
    console.log('find district '+query)
    API.get(`regions`, {params: {key: query}} )
      .then(res => {
        const data = res.data.data;
        setDistricts(data);
        console.log(data)
      })
  }

  const actPost = (id) => {
    setSpinnerSave(true);
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };

    const fd = new FormData();
    fd.append('name', name);
    fd.append('user_id', companyId);
    fd.append('verified', verified);
    fd.append('phone1', phone1);
    fd.append('phone2', phone2);
    fd.append('address', address);
    fd.append('address_code', addressCode);
    fd.append('company_id', companyId);
    fd.append('clock_start', clockStart);
    fd.append('clock_end', clockEnd);
    
    if(id){
      API.put(`outlets/${id}`, fd, config )
        .then(res => {
          setId('');
          setName('');
          setCompanyId('');
          setVerified(false);
          setPhone1('');
          setPhone2('');
          setAddress('');
          setAddressCode('');
          setFullAddress();
          setClockStart('');
          setClockEnd('');
          loadData('');
          setSpinnerSave(false);
          toggle();
        })
        .catch(error => {
          setSpinnerSave(false);
          setVisible(true);
          if(error === undefined){
            setErrorMessage('Gagal, Terjadi kesalahan di server')
          }else{
            setErrorMessage(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }else{
      API.post('outlets', fd, config )
        .then(res => {
          setId('');
          setName('');
          setCompanyId('');
          setVerified(false);
          setPhone1('');
          setPhone2('');
          setAddress('');
          setAddressCode('');
          setFullAddress();
          setClockStart('');
          setClockEnd('');
          loadData('');
          setSpinnerSave(false);
          toggle();
        })
        .catch(error => {
          setSpinnerSave(false);
          setVisible(true);
          if(error === undefined){
            setErrorMessage('Gagal, Terjadi kesalahan di server')
          }else{
            setErrorMessage(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }
    
  }

  const deletePost = (id) => {
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };
    
    API.delete(`outlets/${id}`, config )
      .then(res => {
        loadData('');
      })
      .catch(error => {
        setVisible(true);
        if(error === undefined){
          setErrorMessage('Gagal, Terjadi kesalahan di server')
        }else{
          setErrorMessage(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const loadData = (keyword) => {    
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {key: keyword, include: 'company'  }};
    API.get(`outlets`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setData(data)
        setSpinner(false)
        setLoadMore(false);
        setTotalCount(res.data.meta.pagination.total_entries);
        if(res.data.meta.pagination.total_pages <= 1){
          setPaging(false);
          console.log('No paging')
        }else{
          setPaging(true);
          console.log('Yes paging')
        }
      })
    
    const config2 = { headers: { Authorization: `Bearer ${token}`}, params: {by_verified: true, key: keyword, by_user_type: 'Company', pagination: false  }};
    API.get(`users`, config2)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setCompanies(data);
      })
  }

  const loadMoreData = () => {
    setLoadMoreButton(true);
    setLoadMore(true);
    setOffset(offset+1)
    let offset_page = offset+1
    const config = { headers: { Authorization: `Bearer ${token}` }, params: { page: offset_page, key: '', include: 'company' } };
    
    API.get(`outlets`, config)
      .then(res => {
        setLoadMore(false);
        const data2 = res.data.data;
        setData([...data, ...data2]);
        if(res.data.meta.pagination.total_pages === offset_page){
          setPaging(false);
        }else{
          setLoadMoreButton(false);
        }
      })
  }

  const updateSearch = (search) => {
    setOffset(1)
    if(search === '' ){
      setSearch('');
    }else{
      setSearch(search);
    }
    setLoadMoreButton(false);
    loadData(search);
  }

  const changeVerified = (id, status) => {
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };
    
    API.put(`outlets/${id}`, {verified: status}, config )
      .then(res => {
        const data = res.data.data;
        alert(`Berhasil di ${data.verified ? 'Aktifkan' : 'NonAktif'}`);
        loadData('');
      })
      .catch(error => {
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const onChangeAddress = () => {
    editAddress === true ? setEditAddress(false) : setEditAddress(true)
  }

  useEffect(() => {
    if(token === undefined){
      history.push("/")
    }

    setSearch('');
    loadData('');
    
  },[])

  return (
    <>
     <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Kelola Outlet</ModalHeader>
        <ModalBody>
          <Alert color="warning" isOpen={visible} toggle={onDismiss}>
            {errorMessage}
          </Alert>
          <Form role="form">
            <FormGroup className="mb-3">
              <div className='row'>
                { currentUser.type === 'Adminmaster' ? (
                    <div className='col-12'>
                      <Label className='label-form-group'>Perusahaan</Label>
                      <InputGroup className="input-group-alternative mb-20">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-building" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="select" value={companyId} onChange={e => setCompanyId(e.target.value)}>
                          <option value=''>Pilih Perusahaan</option>
                          {
                            companies.map((c) => {
                              return (
                                <option key={c.id} value={c.id}>{c.full_name}</option>
                              )
                            })
                          }
                        </Input>
                      </InputGroup>
                    </div>
                ) : null }
                <div className='col-12'>
                  <Label className='label-form-group'>Nama Outlet</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-bookmark" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Nama Outlet"
                      value={name} onChange={e => setName(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <Label className='label-form-group'>No.Kontak 1</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-phone" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="No.Kontak 1"
                      value={phone1} onChange={e => setPhone1(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <Label className='label-form-group'>No.Kontak 2</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-phone" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="No.Kontak 2"
                      value={phone2} onChange={e => setPhone2(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div class="col-sm-12 autocomplete-field">
                  <div class="active" for="icon_prefix">
                    <small>Kecamatan Domisili</small>
                    { id !== '' ? (<a href='#' class='btn-right-label' onClick={ onChangeAddress } ><i class="ni ni-settings-gear-65"></i> Ganti Kecamatan</a>) : null }
                  </div>
                  { editAddress || id === '' ? (
                    <ReactSearchAutocomplete
                      items={districts}
                      onSearch={handleOnSearch}
                      onHover={handleOnHover}
                      onSelect={handleOnSelect}
                      formatResult={formatResult}
                      />
                  ) : (
                    <div class='full-address-box'>
                      {fullAddress.includes('null') ? 'Alamat belum ditentukan' : fullAddress}
                    </div>
                  )}
                  <br/>
                </div>
                <div className='col-sm-12'>
                  <Label className='label-form-group'>Alamat Lengkap</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-map-marker" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Alamat Lengkap"
                      value={address} onChange={e => setAddress(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <Label className='label-form-group'>Jam Buka</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <Input
                      placeholder="Jam Buka"
                      type='time'
                      value={clockStart} onChange={e => setClockStart(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-6'>
                  <Label className='label-form-group'>Jam Tutup</Label>
                  <InputGroup className="input-group-alternative mb-20">
                    <Input
                      type='time'
                      placeholder="Jam Tutup"
                      value={clockEnd} onChange={e => setClockEnd(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className='col-sm-12'>
                  <FormGroup className="form-checkbox">
                    <Label check>
                      <Input type="checkbox" defaultChecked={verified} onChange={e => setVerified(!verified)}/> Aktifkan
                    </Label>
                  </FormGroup>
                </div>
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          { spinnerSave ? (
            <Button color="primary" style={{background: (currentCompany.company_color3)}}>
              <Dots color='#fff' />
            </Button>
          ) : (
            <Button color="primary" style={{background: (currentCompany.company_color3)}} onClick={e => actPost(id)}>Simpan</Button>
          )}
          <Button color="secondary" onClick={(e) => toggle(e)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className='row'>
                  <div className='col-sm-2'>
                    <Button className='btn btn-white full-width'>TOTAL {totalCount}</Button>
                  </div>
                  <div className='col-sm-8'>
                    <Input placeholder="Kata Pencarian" value={search} onChange={e => updateSearch(e.target.value)}/>
                  </div>
                  <div className='col-sm-2'>
                    <Button onClick={(e) => toggle(e)} className='btn btn-info full-width' style={{background: (currentCompany.company_color3)}}><i class="fas fa-plus"></i> TAMBAH</Button>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th width='100px' scope="col text-center">Action</th>
                    <th scope="col">Status</th>
                    <th scope="col">ID</th>
                    <th scope="col">Nama Outlet</th>
                    { currentUser && currentUser.type === 'Adminmaster' ? (
                      <th scope="col">Perusahaan</th>
                    ) : null }
                    <th scope="col">Alamat</th>
                    <th scope="col">Buka - Tutup</th>
                    <th scope="col">Dibuat</th>
                  </tr>
                </thead>
                <tbody>
                  { spinner ? (
                    <tr>
                      <td colspan="7" className='text-center'>
                        <div className='loadLoading'><Dots color='#444' /></div>
                      </td>
                    </tr>
                  ) : data && data.length > 0 ? (
                    <>
                      {
                        data.map((u) => {
                          return (
                            <tr key={u.id}>
                              <td width='100px'>
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#pablo"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                    { currentUser.type === 'Adminmaster' || currentUser.type === 'Company' ? (
                                       <DropdownItem href="#pablo" onClick={(e) => deletePost(u.id)}>
                                        Hapus
                                      </DropdownItem>
                                    ) : null } 
                                    <DropdownItem onClick={(e) => toggle(u.id)}>
                                      Ubah
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                              <td>
                                <div style={styles.status(u.verified)}>
                                  {u.verified ? (
                                    <i class="fa-big fa fa-power-off" onClick={(e) => changeVerified(u.id, false)}></i>
                                  ) : (
                                    <i class="fa-big fa fa-power-off" onClick={(e) => changeVerified(u.id, true)}></i>
                                  )}
                                </div>
                              </td>
                              <td>{u.id}</td>
                              <td>
                                <Media className="align-items-center">
                                  <Media>
                                    <span className="mb-0 text-sm">
                                      {u.name}
                                    </span>
                                  </Media>
                                </Media>    
                              </td>
                              { currentUser && currentUser.type === 'Adminmaster' ? (
                                <td>[{u.company.id}] {u.company.full_name}</td>
                              ) : null }
                              <td>{u.address}, {u.city}, {u.province}</td>
                              <td>{u.clock_start ? u.clock_start : '-'} s/d {u.clock_end ? u.clock_end : '-'} WIB</td>
                              <td>{u.created_at}</td>
                            </tr>
                          )
                        })
                      }
                    </>
                  ) : (
                    <div className='col col-md-12 input-field2'>
                      <span>Data Tidak Ditemukan</span>
                    </div>
                  )}
                </tbody>
              </Table>
              {paging ? (
                <div class='col'>
                  <div onClick={loadMoreData} class='load-more-btn'>
                    {loadMore ? (
                      <center><Dots color='#444' /><br/></center>
                    ) : null}
                    SELANJUTNYA
                  </div>
                </div>
              ) : null}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Outlets;


const styles: StyleSheet = {
  status: (status) => ({
    fontSize: 11,
    color: status === true ? '#1963da' : '#ccc',
    paddingBottom: 2,
    borderRadius: 5,
  })
}