import Login from "views/examples/Login.js";
import Register from "views/examples/Register.js";
import Index from "views/Index.js";
import MerchantsIndex from "views/merchants/index.js";
import MerchantShow from "views/merchants/show.js";
import CompanyIndex from "views/companies/index.js";
import CompanyShow from "views/companies/show.js";
import CompanyEdit from "views/companies/edit.js";
import StaffIndex from "views/staffs/index.js";
import StaffShow from "views/staffs/show.js";
import MemberIndex from "views/members/index.js";
import MemberShow from "views/members/show.js";
import GroupUserIndex from "views/group_users/index.js";
import BrandIndex from "views/brands/index.js";
import CategoryIndex from "views/categories/index.js";
import Expeditions from "views/expeditions/index.js";
import PaymentNumbers from "views/payment_numbers/index.js";
import ProductIndex from "views/products/index.js";
import ProductEdit from "views/products/edit.js";
import OrderIndex from "views/orders/index.js";
import OrderShow from "views/orders/show.js";
import Account from "views/account/edit.js";
import SpendingCategoryIndex from "views/spending_categories/index.js";
import SpendingIndex from "views/spendings/index.js";
import SpendingShow from "views/spendings/show.js";
import ReportIndexDaily from "views/reports/index.js";
import ReportIndexMonthly from "views/reports/index.js";
import ReportIndexStaff from "views/reports/index.js";
import ReportIndexMember from "views/reports/index.js";
import ReportIndexRegion from "views/reports/index.js";
import ReportIndexProduct from "views/reports/index.js";
import ReportIndexExpedition from "views/reports/index.js";
import ReportIndexSpending from "views/reports/index.js";
import ShopIndex from "views/shops/index.js";
import TrackShippingIndex from "views/shops/track_shipping.js";
import CheckShippingIndex from "views/shops/check_shipping.js";
import MarketingKitIndex from "views/brands/content.js";
import RewardsIndex from "views/rewards/index.js";
import BlogsIndex from "views/blogs/index.js";
import PaymentsIndex from "views/payments/index.js";
import PaymentShowIndex from "views/payments/show.js";
import VirtualsIndex from "views/virtuals/index.js";
import BillingsIndex from "views/billings/index.js";
import BillingShow from "views/billings/show.js";
import PackagesIndex from "views/packages/index.js";
import WaSettingsIndex from "views/wa_settings/index.js";
import LandingPressesIndex from "views/landing_presses/index.js";
import LandingPressEdit from "views/landing_presses/edit.js";
import RequestLpIndex from "views/request_landing_presses/index.js";
import ReportMembers from "views/report_members/index.js";
import VouchersIndex from "views/vouchers/index.js";
import PromoIndex from "views/promos/index.js";
import ScanCode from "views/scan_code/index.js";
import PurchaseIndex from "views/purchases/index.js";
import PurchaseShow from "views/purchases/show.js";
import PurchaseNew from "views/purchases/new.js";
import PurchaseEdit from "views/purchases/edit.js";
import AccountIndex from "views/accounts/index.js";
import AccountShow from "views/accounts/show.js";
import RoomIndex from "views/rooms/index.js";
import OutletIndex from "views/outlets/index.js";
import ConfirmationIndex from "views/confirmations/index.js";
import TableIndex from "views/tables/index.js";
import BookingOrderIndex from "views/booking_orders/index.js";
// import Profile from "views/examples/Profile.js";
// import Register from "views/examples/Register.js";
// import Tables from "views/examples/Tables.js";
import Icons from "views/examples/Icons.js";

var routes = [
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-tv-2 text-primary",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-tv-2 text-primary",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
  },
  {
    path: "/companies",
    name: "Perusahaan",
    icon: "ni ni-bullet-list-67 text-red",
    component: CompanyIndex,
    layout: "/admin",
  },
  {
    path: "/staffs",
    name: "Staff",
    icon: "ni ni-bullet-list-67 text-red",
    component: StaffIndex,
    layout: "/admin",
  },
  {
    path: "/members",
    name: "User",
    icon: "ni ni-bullet-list-67 text-red",
    component: MemberIndex,
    layout: "/admin",
  },
  {
    path: "/group_users",
    name: "Level User",
    icon: "ni ni-bullet-list-67 text-red",
    component: GroupUserIndex,
    layout: "/admin",
  },
  {
    path: "/brands",
    name: "Merek Produk",
    icon: "ni ni-bullet-list-67 text-red",
    component: BrandIndex,
    layout: "/admin",
  },
  {
    path: "/categories",
    name: "Kategori Produk",
    icon: "ni ni-bullet-list-67 text-red",
    component: CategoryIndex,
    layout: "/admin",
  },
  {
    path: "/icons",
    name: "Icons",
    icon: "ni ni-planet text-blue",
    component: Icons,
    layout: "/admin",
  },
  {
    path: "/expeditions",
    name: "Expedisi Pengiriman",
    icon: "ni ni-bullet-list-67 text-red",
    component: Expeditions,
    layout: "/admin",
  },
  {
    path: "/accounts",
    name: "Kelola Akun",
    icon: "ni ni-bullet-list-67 text-red",
    component: AccountIndex,
    layout: "/admin",
  },
  {
    path: "/transactions/:id",
    name: "Detail Akun",
    icon: "ni ni-bullet-list-67 text-red",
    component: AccountShow,
    layout: "/admin",
  },
  {
    path: "/payment_numbers",
    name: "Nomor Rekening",
    icon: "ni ni-bullet-list-67 text-red",
    component: PaymentNumbers,
    layout: "/admin",
  },
  {
    path: "/products",
    name: "Produk",
    icon: "ni ni-bullet-list-67 text-red",
    component: ProductIndex,
    layout: "/admin",
  },
  {
    path: "/product_edit/:id",
    name: "Pengaturan Produk",
    icon: "ni ni-bullet-list-67 text-red",
    component: ProductEdit,
    layout: "/admin",
  },
  {
    path: "/orders",
    name: "Pesanan",
    icon: "ni ni ni ni-cart text-blue",
    component: OrderIndex,
    layout: "/admin",
  },
  {
    path: "/confirmations",
    name: "Konfirmasi Pembayaran",
    icon: "ni ni ni ni-cart text-blue",
    component: ConfirmationIndex,
    layout: "/admin",
  },
  {
    path: "/order/:id",
    name: "Detail Pesanan",
    icon: "ni ni ni ni-cart text-blue",
    component: OrderShow,
    layout: "/admin",
  },
  {
    path: "/account",
    name: "Pengaturan Akun",
    icon: "ni ni ni ni-cart text-blue",
    component: Account,
    layout: "/admin",
  },
  {
    path: "/spending_categories",
    name: "Kategori Pengeluaran",
    icon: "ni ni-bullet-list-67 text-red",
    component: SpendingCategoryIndex,
    layout: "/admin",
  },
  {
    path: "/spendings",
    name: "Pengeluaran",
    icon: "ni ni-bullet-list-67 text-red",
    component: SpendingIndex,
    layout: "/admin",
  },
  {
    path: "/spending/:id",
    name: "Detail Pengeluaran",
    icon: "ni ni-bullet-list-67 text-red",
    component: SpendingShow,
    layout: "/admin",
  },
  {
    path: "/daily_report",
    name: "Report",
    icon: "ni ni-bullet-list-67 text-red",
    component: ReportIndexDaily,
    layout: "/admin",
  },
  {
    path: "/monthly_report",
    name: "Report",
    icon: "ni ni-bullet-list-67 text-red",
    component: ReportIndexMonthly,
    layout: "/admin",
  },
  {
    path: "/member_report",
    name: "Report User",
    icon: "ni ni-chart-bar-32 text-blue",
    component: ReportIndexMember,
    layout: "/admin",
  },
  {
    path: "/staff_report",
    name: "Report User",
    icon: "ni ni-chart-bar-32 text-blue",
    component: ReportIndexStaff,
    layout: "/admin",
  },
  {
    path: "/region_report",
    name: "Report Daerah",
    icon: "ni ni-chart-bar-32 text-blue",
    component: ReportIndexRegion,
    layout: "/admin",
  },
  {
    path: "/product_report",
    name: "Report Produk",
    icon: "ni ni-chart-bar-32 text-blue",
    component: ReportIndexProduct,
    layout: "/admin",
  },
  {
    path: "/expedition_report",
    name: "Report Expedisi",
    icon: "ni ni-chart-bar-32 text-blue",
    component: ReportIndexExpedition,
    layout: "/admin",
  },
  {
    path: "/spending_report",
    name: "Report Pengeluaran",
    icon: "ni ni-chart-bar-32 text-blue",
    component: ReportIndexSpending,
    layout: "/admin",
  },
  {
    path: "/shops",
    name: "Sistem POS",
    icon: "ni ni-chart-bar-32 text-blue",
    component: ShopIndex,
    layout: "/admin",
  },
  {
    path: "/track_shipping",
    name: "Track Pengiriman",
    icon: "ni ni-chart-bar-32 text-blue",
    component: TrackShippingIndex,
    layout: "/admin",
  },
  {
    path: "/check_shipping",
    name: "Cek Ongkir",
    icon: "ni ni-chart-bar-32 text-blue",
    component: CheckShippingIndex,
    layout: "/admin",
  },
  {
    path: "/marketingkit",
    name: "Marketing Kit",
    icon: "ni ni-chart-bar-32 text-blue",
    component: MarketingKitIndex,
    layout: "/admin",
  },
  {
    path: "/blogs",
    name: "Blog",
    icon: "ni ni-chart-bar-32 text-blue",
    component: BlogsIndex,
    layout: "/admin",
  },
  {
    path: "/rewards",
    name: "Reward",
    icon: "ni ni-chart-bar-32 text-blue",
    component: RewardsIndex,
    layout: "/admin",
  },
  {
    path: "/payments",
    name: "Pengajuan Pencairan Saldo",
    icon: "ni ni-chart-bar-32 text-blue",
    component: PaymentsIndex,
    layout: "/admin",
  },
  {
    path: "/claimmoney/:id",
    name: "Detail Pengajuan Pencairan Saldo",
    icon: "ni ni-chart-bar-32 text-blue",
    component: PaymentShowIndex,
    layout: "/admin",
  },
  {
    path: "/virtuals",
    name: "Kelola Saldo & Poin",
    icon: "ni ni-chart-bar-32 text-blue",
    component: VirtualsIndex,
    layout: "/admin",
  },
  {
    path: "/member/:id",
    name: "Detail User",
    icon: "ni ni-bullet-list-67 text-red",
    component: MemberShow,
    layout: "/admin",
  },
  {
    path: "/company/:id",
    name: "Detail Perusahaan",
    icon: "ni ni-bullet-list-67 text-red",
    component: CompanyShow,
    layout: "/admin",
  },
  {
    path: "/staff/:id",
    name: "Detail Staff",
    icon: "ni ni-bullet-list-67 text-red",
    component: StaffShow,
    layout: "/admin",
  },
  {
    path: "/edit_company/:id",
    name: "Edit Perusahaan",
    icon: "ni ni-bullet-list-67 text-red",
    component: CompanyEdit,
    layout: "/admin",
  },
  {
    path: "/billings",
    name: "Billing",
    icon: "ni ni-bullet-list-67 text-red",
    component: BillingsIndex,
    layout: "/admin",
  },
  {
    path: "/billing/:id",
    name: "Detail Billing",
    icon: "ni ni-bullet-list-67 text-red",
    component: BillingShow,
    layout: "/admin",
  },
  {
    path: "/wa_settings",
    name: "WA Setting",
    icon: "ni ni-bullet-list-67 text-red",
    component: WaSettingsIndex,
    layout: "/admin",
  },
  {
    path: "/landing_presses",
    name: "Landing Press",
    icon: "ni ni-bullet-list-67 text-red",
    component: LandingPressesIndex,
    layout: "/admin",
  },
  {
    path: "/edit_landing_press/:id",
    name: "Pengaturan Landing Press",
    icon: "ni ni-bullet-list-67 text-red",
    component: LandingPressEdit,
    layout: "/admin",
  },
  {
    path: "/request_landing_presses",
    name: "Request Landing Page",
    icon: "ni ni-bullet-list-67 text-red",
    component: RequestLpIndex,
    layout: "/admin",
  },
  {
    path: "/report_members",
    name: "Penyebaran Domisili User",
    icon: "ni ni-bullet-list-67 text-red",
    component: ReportMembers,
    layout: "/admin",
  },
  {
    path: "/vouchers",
    name: "Voucher",
    icon: "ni ni-bullet-list-67 text-red",
    component: VouchersIndex,
    layout: "/admin",
  },
  {
    path: "/promos",
    name: "Promo",
    icon: "ni ni-bullet-list-67 text-red",
    component: PromoIndex,
    layout: "/admin",
  },
  {
    path: "/merchants",
    name: "Merchant",
    icon: "ni ni-bullet-list-67 text-red",
    component: MerchantsIndex,
    layout: "/admin",
  },
  {
    path: "/merchant/:id",
    name: "Detail Merchant",
    icon: "ni ni-bullet-list-67 text-red",
    component: MerchantShow,
    layout: "/admin",
  },
  {
    path: "/scan-code",
    name: "Scan Code",
    icon: "ni ni-bullet-list-67 text-red",
    component: ScanCode,
    layout: "/admin",
  },
  {
    path: "/purchases",
    name: "Purchasing",
    icon: "ni ni-bullet-list-67 text-red",
    component: PurchaseIndex,
    layout: "/admin",
  },
  {
    path: "/purchase/:id",
    name: "Detail Purchase",
    icon: "ni ni-bullet-list-67 text-red",
    component: PurchaseShow,
    layout: "/admin",
  },
  {
    path: "/purchase_new",
    name: "New Purchase",
    icon: "ni ni-bullet-list-67 text-red",
    component: PurchaseNew,
    layout: "/admin",
  },
  {
    path: "/edit_purchase/:id",
    name: "Ubah Purchase",
    icon: "ni ni-bullet-list-67 text-red",
    component: PurchaseEdit,
    layout: "/admin",
  },
  {
    path: "/packages",
    name: "Kelola Paket",
    icon: "ni ni-bullet-list-67 text-red",
    component: PackagesIndex,
    layout: "/admin",
  },
  {
    path: "/manage_rooms",
    name: "Kelola Room",
    icon: "ni ni-bullet-list-67 text-red",
    component: RoomIndex,
    layout: "/admin",
  },
  {
    path: "/outlets",
    name: "Kelola Outlet",
    icon: "ni ni-bullet-list-67 text-red",
    component: OutletIndex,
    layout: "/admin",
  },
  {
    path: "/tables",
    name: "Kelola Table",
    icon: "ni ni-bullet-list-67 text-red",
    component: TableIndex,
    layout: "/admin",
  },
  {
    path: "/booking_orders",
    name: "Daftar Booking",
    icon: "ni ni-bullet-list-67 text-red",
    component: BookingOrderIndex,
    layout: "/admin",
  },
];
export default routes;
