import React, { useEffect, useState } from 'react';
import API from '../../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../../ReactSession';
import { useHistory } from "react-router-dom";
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import DatePicker from "reactstrap-date-picker";
import Select from 'react-select';

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Input,
  Modal,
  ModalHeader, ModalBody, ModalFooter,
  Button,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
  Label
} from "reactstrap";

import Header from "components/Headers/Header.js";

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const VouchersIndex = () => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const history = useHistory();

  const [id, setId] = useState('');
  const [nameCategory, setNameCategory] = useState('');
  const [title, setTitle] = useState('');
  const [code, setCode] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [verified, setVerified] = useState(false);
  const [brandIds, setBrandIds] = useState([]);
  const [voucherValue, setVoucherValue] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [quota, setQuota] = useState(0);

  const [companies, setCompanies] = useState([]);
  const [brands, setBrands] = useState([]);
  
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(1);
  const [paging, setPaging] = useState(true);
  const [search, setSearch] = useState('');
  const [spinner, setSpinner] = useState(true);
  const [loadMore, setLoadMore] = useState(true);
  const [loadMoreButton, setLoadMoreButton] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [spinnerSave, setSpinnerSave] = useState(false);

  const categories = [
    {id: 'discount', name: 'Diskon Harga'},
    {id: 'nominal', name: 'Potongan Harga'}
  ]

  const [modal, setModal] = useState(false);
  const toggle = (id) => {
    if(id){
      let dt = data.filter(o => o.id === id)[0]
      if(dt){
        setId(dt.id);
        setNameCategory(dt.category);
        setCompanyId(dt.user_id);
        setTitle(dt.title);
        setCode(dt.code);
        setVerified(dt.verified);
        if(brands && dt.brand_ids){
          setBrandIds( brands.filter((b) => dt.brand_ids.includes(b.id.toString())) );
        }else{
          setBrandIds([]);
        }
        setVoucherValue(dt.voucher_value);
        setStartDate(dt.start_date);
        setEndDate(dt.end_date);
        setQuota(dt.quota);
        if(currentUser && currentUser.type === 'Adminmaster'){
          setLoadCompany(dt.user_id);
        }
      }else{
        setId('');
        setNameCategory('');
        setCompanyId('');
        setTitle('');
        setCode('');
        setVerified(false);
        setBrandIds([]);
        setVoucherValue(0);
        setStartDate('');
        setEndDate('');
        setQuota(0);
      }
    }else{
      setId('');
      setNameCategory('');
      setCompanyId('');
      setTitle('');
      setCode('');
      setVerified(false);
      setBrandIds([]);
      setVoucherValue(0);
      setStartDate('');
      setEndDate('');
      setQuota(0);
    }
    setModal(!modal);
  }

  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);
  const [errorMessage, setErrorMessage] = useState('');

  const setLoadCompany = (company_id) => {
    setCompanyId(company_id);

    const config = { headers: { Authorization: `Bearer ${token}`}, params: {by_status: true, by_company_id: company_id, pagination: false }};
    API.get(`brands`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setBrands(data);
      })
  }

  const actPost = (id) => {
    setSpinnerSave(true);
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };
    
    const fd = new FormData();
    fd.append('category', nameCategory);
    fd.append('title', title);
    fd.append('code', code);
    fd.append('user_id', companyId);
    fd.append('verified', verified);
    fd.append('voucher_value', voucherValue);
    fd.append('start_date', startDate);
    fd.append('end_date', endDate);
    fd.append('quota', quota);
    fd.append('brand_ids', brandIds.map((e) => e.id));
    
    if(id){
      API.put(`vouchers/${id}`, fd, config )
        .then(res => {
          setId('');
          setNameCategory('');
          setCompanyId('');
          setTitle('');
          setCode('');
          setVerified(false);
          setBrandIds([]);
          setVoucherValue(0);
          setStartDate('');
          setEndDate('');
          setQuota(0);
          loadData('');
          setSpinnerSave(false);
          toggle();
        })
        .catch(error => {
          setSpinnerSave(false);
          setVisible(true);
          if(error === undefined){
            setErrorMessage('Gagal, Terjadi kesalahan di server')
          }else{
            setErrorMessage(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }else{
      API.post('vouchers', fd, config )
        .then(res => {
          setId('');
          setNameCategory('');
          setCompanyId('');
          setTitle('');
          setCode('');
          setVerified(false);
          setBrandIds([]);
          setVoucherValue(0);
          setStartDate('');
          setEndDate('');
          setQuota(0);
          loadData('');
          setSpinnerSave(false);
          toggle();
        })
        .catch(error => {
          setSpinnerSave(false);
          setVisible(true);
          if(error === undefined){
            setErrorMessage('Gagal, Terjadi kesalahan di server')
          }else{
            setErrorMessage(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }
    
  }

  const deletePost = (id) => {
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };
    
    API.delete(`vouchers/${id}`, config )
      .then(res => {
        loadData('');
      })
      .catch(error => {
        setVisible(true);
        if(error === undefined){
          setErrorMessage('Gagal, Terjadi kesalahan di server')
        }else{
          setErrorMessage(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const loadData = (keyword) => {    
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {key: keyword, include: 'company, brands'  }};
    API.get(`vouchers`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setData(data)
        setSpinner(false)
        setLoadMore(false);
        setTotalCount(res.data.meta.pagination.total_entries);
        if(res.data.meta.pagination.total_pages <= 1){
          setPaging(false);
          console.log('No paging')
        }else{
          setPaging(true);
          console.log('Yes paging')
        }
      })
    
    const config2 = { headers: { Authorization: `Bearer ${token}`}, params: {by_verified: true, key: keyword, by_user_type: 'Company', pagination: false  }};
    API.get(`users`, config2)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setCompanies(data);
      })
  }

  const loadMoreData = () => {
    setLoadMoreButton(true);
    setLoadMore(true);
    setOffset(offset+1)
    let offset_page = offset+1
    const config = { headers: { Authorization: `Bearer ${token}` }, params: { page: offset_page, key: '', include: 'company, parent' } };
    
    API.get(`vouchers`, config)
      .then(res => {
        setLoadMore(false);
        const data2 = res.data.data;
        setData([...data, ...data2]);
        if(res.data.meta.pagination.total_pages === offset_page){
          setPaging(false);
        }else{
          setLoadMoreButton(false);
        }
      })
  }

  const updateSearch = (search) => {
    setOffset(1)
    if(search === '' ){
      setSearch('');
    }else{
      setSearch(search);
    }
    setLoadMoreButton(false);
    loadData(search);
  }

  const clearSearch = (search) => {
    setOffset(1);
    updateSearch('');
    loadData('');
    setLoadMoreButton(false);
  }

  const changeVerified = (id) => {
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };
    
    API.put(`vouchers/${id}/verified`, {status: ''}, config )
      .then(res => {
        const data = res.data.data;
        alert(`Berhasil di ${data.verified ? 'Aktifkan' : 'NonAktif'}`);
        loadData('');
      })
      .catch(error => {
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const onChangeMulti = (event) => {
    let opts = [], opt;
    for (let i = 0, len = event.target.options.length; i < len; i++) {
        opt = event.target.options[i];
        if (opt.selected) {
            opts.push(opt.value);
        }
    }
    setBrandIds(opts);
  }

  useEffect(() => {
    if(token === undefined){
      history.push("/")
    }

    setSearch('');
    loadData('');

    if(currentUser && currentUser.type === 'Company'){
      setLoadCompany(currentUser.id);
    }else if (currentUser && currentUser.type === 'Staff'){
      setLoadCompany(currentUser.company_id)
    }
  
  },[])

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Kelola Voucher</ModalHeader>
        <ModalBody>
          <Alert color="warning" isOpen={visible} toggle={onDismiss}>
            {errorMessage}
          </Alert>
          <Form role="form">
            <FormGroup className="mb-3">
              <div className='row'>
                { currentUser && currentUser.type === 'Adminmaster' ? (
                  <div className='col-12'>
                    <Label className='label-form-group'>Pilih Perusahaan</Label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-building" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="select" value={companyId} onChange={e => setLoadCompany(e.target.value)}>
                        <option value=''>Pilih Perusahaan</option>
                        { companies ? (
                            companies.map((c) => {
                              return (
                                <option key={c.id} value={c.id}>{c.full_name}</option>
                              )
                            })
                          ) : null
                        }
                      </Input>
                    </InputGroup>
                  </div>
                ) : null }
                
                <div className='col-12'>
                  <Label className='label-form-group'>Judul Voucher</Label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-notification-70" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Judul Voucher"
                      value={title} onChange={e => setTitle(e.target.value)}
                    />
                  </InputGroup>
                </div>
                
                <div className='col-6'>
                  <Label className='label-form-group'>Kategori Voucher</Label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-folder" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input type="select" value={nameCategory} onChange={e => setNameCategory(e.target.value)}>
                      <option value=''>Pilih Kategori</option>
                      { categories ? (
                          categories.map((c) => {
                            return (
                              <option key={c.id} value={c.id}>{c.name}</option>
                            )
                          })
                        ) : null
                      }
                    </Input>
                  </InputGroup>
                </div>
                
                <div className='col-6'>
                  <Label className='label-form-group'>Masukan Kode Voucher</Label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-tablet-button" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Masukan Kode Voucher"
                      value={code} onChange={e => setCode(e.target.value)}
                    />
                  </InputGroup>
                </div>
                
                <div className='col-6'>
                  <Label className='label-form-group'>Masukan Nominal</Label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-credit-card" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Masukan Nominal"
                      value={voucherValue} onChange={e => setVoucherValue(e.target.value)}
                    />
                  </InputGroup>
                </div>

                <div className='col-6'>
                  <Label className='label-form-group'>Kuota Voucher</Label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-archive-2" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type='number'
                      placeholder="Masukan Quota"
                      value={quota} onChange={e => setQuota(e.target.value)}
                    />
                  </InputGroup>
                </div>

                <div className='col-6'>
                  <FormGroup>
                    <Label className='label-form-group'>Tanggal Mulai</Label>
                    <DatePicker options={{ yearRange: 70}} id= "example-datepicker" value={startDate} onChange={e => setStartDate(e)} />
                  </FormGroup>
                </div>

                <div className='col-6'>
                  <FormGroup>
                    <Label className='label-form-group'>Tanggal Berakhir</Label>
                    <DatePicker options={{ yearRange: 70}} id= "example-datepicker2" value={endDate} onChange={e => setEndDate(e)} />
                  </FormGroup>
                </div>

                <div className='col-12'>
                  <FormGroup>
                    <Label className='label-form-group'>Pilih Brand</Label>
                    <Select
                      isMulti
                      defaultValue={brandIds}
                      onChange={setBrandIds}
                      options={brands}
                    />
                  </FormGroup>
                </div>
                
                <div className='col-12'>
                  <FormGroup className="form-checkbox">
                    <Label check>
                      <Input type="checkbox" defaultChecked={verified} onChange={e => setVerified(!verified)}/> Aktifkan
                    </Label>
                  </FormGroup>
                </div>
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          { spinnerSave ? (
            <Button color="primary" style={{background: (currentCompany.company_color3)}}>
              <Dots color='#fff' />
            </Button>
          ) : (
            <Button color="primary" style={{background: (currentCompany.company_color3)}} onClick={e => actPost(id)}>Simpan</Button>
          )}
          <Button color="secondary" onClick={(e) => toggle(e)}>Cancel</Button>
        </ModalFooter>
      </Modal>
      
      <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className='row'>
                  <div className='col-sm-2'>
                    <Button className='btn btn-white full-width'>TOTAL {totalCount}</Button>
                  </div>
                  <div className='col-sm-8'>
                    <Input placeholder="Kata Pencarian" value={search} onChange={e => updateSearch(e.target.value)}/>
                  </div>
                  <div className='col-sm-2'>
                    <Button onClick={(e) => toggle(e)} className='btn btn-info full-width' style={{background: (currentCompany.company_color3)}}><i class="fas fa-plus"></i> TAMBAH</Button>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th width='100px' scope="col text-center">Action</th>
                    <th scope="col">Status</th>
                    <th scope="col">ID</th>
                    <th scope="col">Voucher</th>
                    <th scope="col">Kode Voucher</th>
                    <th scope="col">Kategori</th>
                    { currentUser && currentUser.type === 'Adminmaster' ? (
                      <th scope="col">Perusahaan</th>
                    ) : null }
                    <th scope="col">Nominal</th>
                    <th scope="col">Berlaku</th>
                    <th scope="col">Kuota</th>
                    <th scope="col">Dibuat</th>
                  </tr>
                </thead>
                <tbody>
                  { spinner ? (
                    <tr>
                      <td colspan="11" className='text-center'>
                        <div className='loadLoading'><Dots color='#444' /></div>
                      </td>
                    </tr>
                  ) : data && data.length > 0 ? (
                    <>
                      {
                        data.map((u) => {
                          return (
                            <tr key={u.id}>
                              <td width='100px'>
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#pablo"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                    {/* { currentUser.type === 'Adminmaster' ? (
                                       <DropdownItem href="#pablo" onClick={(e) => deletePost(u.id)}>
                                        Hapus
                                      </DropdownItem>
                                    ) : null }  */}
                                    <DropdownItem onClick={(e) => toggle(u.id)}>
                                      Ubah
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                              <td>
                                <div style={styles.status(u.verified)}>
                                  {u.verified ? (
                                    <i class="fa-big fa fa-power-off" onClick={(e) => changeVerified(u.id)}></i>
                                  ) : (
                                    <i class="fa-big fa fa-power-off" onClick={(e) => changeVerified(u.id)}></i>
                                  )}
                                </div>
                              </td>
                              <td>{u.id}</td>
                              <td>{u.title}</td>
                              <td>{u.code}</td>
                              <td>{u.category === 'discount' ? 'Diskon Harga' : 'Potongan Harga'}</td>
                              { currentUser && currentUser.type === 'Adminmaster' ? (
                                <td>[{u.user_id}] {u.company.full_name}</td>
                              ) : null }
                              <td>
                                {u.category === 'discount' ? `${u.voucher_value}%` : currencyFormat(u.voucher_value)}
                              </td>
                              <td>{u.start_date} s/d {u.end_date}</td>
                              <td>{u.quota}</td>
                              <td>{u.created_at}</td>
                            </tr>
                          )
                        })
                      }
                    </>
                  ) : (
                    <div className='col col-md-12 input-field2'>
                      <span>Data Tidak Ditemukan</span>
                    </div>
                  )}
                </tbody>
              </Table>
              {paging ? (
                <div class='col'>
                  <div onClick={loadMoreData} class='load-more-btn'>
                    {loadMore ? (
                      <center><Dots color='#444' /><br/></center>
                    ) : null}
                    SELANJUTNYA
                  </div>
                </div>
              ) : null}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default VouchersIndex;


const styles: StyleSheet = {
  status: (status) => ({
    fontSize: 11,
    color: status === true ? '#1963da' : '#ccc',
    paddingBottom: 2,
    borderRadius: 5,
  })
}