import React, { useEffect, useState } from 'react';
import API from '../../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../../ReactSession';
import { useHistory } from "react-router-dom";
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import DatePicker from "reactstrap-date-picker";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Input,
  Modal,
  ModalHeader, ModalBody, ModalFooter,
  Button,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
  Label,
  Col, CardBody, CardTitle
} from "reactstrap";

import Header from "components/Headers/Header.js";

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const PurchaseIndex = () => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const history = useHistory();

  const [report, setReport] = useState({});
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(1);
  const [paging, setPaging] = useState(true);
  const [search, setSearch] = useState('');
  const [spinner, setSpinner] = useState(true);
  const [loadMore, setLoadMore] = useState(true);
  const [loadMoreButton, setLoadMoreButton] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [spinnerSave, setSpinnerSave] = useState(false);

  const [searchStartDate, setSearchStartDate] = useState('');
  const [searchEndDate, setSearchEndDate] = useState('');

  const [linkDownload, setLinkDownload] = useState('');
  const [loadingExport, setLoadingExport] = useState(false);
  const [modalExport, setModalExport] = useState(false);
  const toggleExport = () => {
    setModalExport(!modalExport);
  }

  const deletePost = (id) => {
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };

    API.delete(`purchases/${id}`, config )
      .then(res => {
        loadData('');
      })
      .catch(error => {
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const loadData = (keyword, start_date, end_date) => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {key: keyword, include: 'company', start_at: start_date, end_at: end_date  }};
    API.get(`purchases`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setData(data)
        setSpinner(false)
        setLoadMore(false);
        setTotalCount(res.data.meta.pagination.total_entries);
        if(res.data.meta.pagination.total_pages <= 1){
          setPaging(false);
          console.log('No paging')
        }else{
          setPaging(true);
          console.log('Yes paging')
        }
      })

    API.get(`purchase_reports`, config)
      .then(res => {
        const data = res.data.report;
        setReport(data)
      })
      
  }

  const loadMoreData = () => {
    setLoadMoreButton(true);
    setLoadMore(true);
    setOffset(offset+1)
    let offset_page = offset+1
    const config = { headers: { Authorization: `Bearer ${token}` }, params: { page: offset_page, key: search, include: 'company', start_at: searchStartDate, end_at: searchEndDate } };

    API.get(`purchases`, config)
      .then(res => {
        setLoadMore(false);
        const data2 = res.data.data;
        setData([...data, ...data2]);
        if(res.data.meta.pagination.total_pages === offset_page){
          setPaging(false);
        }else{
          setLoadMoreButton(false);
        }
      })
  }

  const updateSearch = (search) => {
    setOffset(1)
    if(search === '' ){
      setSearch('');
    }else{
      setSearch(search);
    }
    setLoadMoreButton(false);
    loadData(search, searchStartDate, searchEndDate);
  }

  const updateSearchStartDate = (start_date) => {
    setSearchStartDate(start_date)
    loadData(search, start_date, searchEndDate);
  }

  const updateSearchEndDate = (end_date) => {
    setSearchEndDate(end_date)
    loadData(search, searchStartDate, end_date);
  }

  const clearSearch = (search) => {
    setOffset(1);
    updateSearch('');
    loadData('');
    setLoadMoreButton(false);
  }

  const exportData = () => {
    toggleExport();
    setLoadingExport(true);
    const config = { headers: { Authorization: `Bearer ${token}` }, params: { pagination: false, key: search, include: 'company', start_at: searchStartDate, end_at: searchEndDate } };
    API.get(`purchase_exports`, config)
      .then(res => {
        const data = res.data.report;
        setSpinner(false)
        setLoadingExport(false);
        toggleExport();
        setLinkDownload(data);
      })
      .catch(error => {
        setSpinner(false)
        setLoadingExport(false);
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  useEffect(() => {
    if(token === undefined){
      history.push("/")
    }

    setSearch('');
    loadData('');
  },[])

  return (
    <>
      <Modal isOpen={modalExport} toggle={toggleExport}>
        <ModalHeader toggle={toggleExport}>Export Data Berhasil</ModalHeader>
        <ModalBody>
          { loadingExport ? (
            <a href={linkDownload} target="_blank" className='btn btn-success' style={{width: '100%'}}>
              <Dots color='#fff' /> Loading
            </a>
          ) : (
            <a href={linkDownload} target="_blank" className='btn btn-success' style={{width: '100%'}}>Download File</a>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={(e) => toggleExport(e)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <Row>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                          PENDING
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {report.pending ? numberFormat(parseInt(report.pending)) : numberFormat(0)}
                        </span>
                        {/* <div>
                          DP <b>{report.pending ? numberFormat(parseInt(report.pending[1])) : numberFormat(0)}</b>
                          <br/>
                          Sisa Pembayaran <b>{report.pending ? numberFormat( parseInt(report.pending[0])-parseInt(report.pending[1])) : numberFormat(0)}</b>
                        </div> */}
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                          PROSES
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 text-info">
                          {report.process ? numberFormat(parseInt(report.process[0])) : numberFormat(0)}
                        </span>
                        {/* <div>
                          DP <b>{report.process ? numberFormat(parseInt(report.process[1])) : numberFormat(0)}</b>
                          <br/>
                          Sisa Pembayaran <b>{report.process ? numberFormat( parseInt(report.process[0])-parseInt(report.process[1])) : numberFormat(0)}</b>
                        </div> */}
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                          SUDAH DIBAYAR
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 text-success">
                          {report.paid ? numberFormat(report.paid) : numberFormat(0)}
                        </span>
                        {/* <div>
                          DP <b>{report.paid ? numberFormat(parseInt(report.paid[1])) : numberFormat(0)}</b>
                          <br/>
                          Sisa Pembayaran <b>{report.paid ? numberFormat( parseInt(report.paid[0])-parseInt(report.paid[1])) : numberFormat(0)}</b>
                        </div> */}
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                          DIBATALKAN
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 text-warning">
                          {report.cancelled ? numberFormat(parseInt(report.cancelled)) : numberFormat(0)}
                        </span>
                        {/* <div>
                          DP <b>{report.cancelled ? numberFormat(parseInt(report.cancelled[1])) : numberFormat(0)}</b>
                          <br/>
                          Sisa Pembayaran <b>{report.cancelled ? numberFormat( parseInt(report.cancelled[0])-parseInt(report.cancelled[1])) : numberFormat(0)}</b>
                        </div> */}
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className='row'>
                  <div className='col-sm-2'>
                    <Button className='btn btn-white full-width'>TOTAL {totalCount}</Button>
                  </div>
                  <div className='col-sm-4'>
                    <Input placeholder="Kata Pencarian" value={search} onChange={e => updateSearch(e.target.value)}/>
                  </div>
                  <div className='col-sm-2'>
                    <DatePicker id= "example-datepicker" value={searchStartDate} onChange={e => updateSearchStartDate(e)} />
                  </div>
                  <div className='col-sm-2'>
                    <DatePicker id= "example-datepicker2" value={searchEndDate} onChange={e => updateSearchEndDate(e)} />
                  </div>
                  { currentUser.type != 'Merchant' ? (
                    <div className='col-sm-1'>
                      <Button href={'purchase_new'} className='btn btn-info full-width' style={{background: (currentCompany.company_color3)}}><i class="fas fa-plus"></i></Button>
                    </div>
                  ) : null }
                  <div className='col-sm-1'>
                    <Button onClick={(e) => exportData(e)} className='btn btn-default full-width' style={{background: '#554e4e'}}><i class="fas fa-download"></i></Button>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th width='100px' scope="col text-center">Action</th>
                    <th scope="col">Status</th>
                    <th scope="col">Faktur</th>
                    <th scope="col">Kategori</th>
                    <th scope="col">Judul</th>
                    { currentUser && currentUser.type === 'Adminmaster' ? (
                      <th scope="col">Perusahaan</th>
                    ) : null }
                    <th scope="col">Total</th>
                    {/* <th scope="col">DP</th> */}
                    {/* <th scope="col">Sisa Pembayaran</th> */}
                    {/* <th scope="col">Dari Akun</th>
                    <th scope="col">Tujuan Pembayaran</th> */}
                    <th scope="col">Transaksi</th>
                    <th scope="col">Dibuat</th>
                  </tr>
                </thead>
                <tbody>
                  { spinner ? (
                    <tr>
                      <td colspan="10" className='text-center'>
                        <div className='loadLoading'><Dots color='#444' /></div>
                      </td>
                    </tr>
                  ) : data && data.length > 0 ? (
                    <>
                      {
                        data.map((u) => {
                          return (
                            <tr key={u.id}>
                              <td width='100px' className="">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                    { currentUser.type === 'Adminmaster' || currentUser.type === 'Company' ? (
                                       <DropdownItem href="#pablo" onClick={(e) => deletePost(u.id)}>
                                        Hapus
                                      </DropdownItem>
                                    ) : null } 
                                    <DropdownItem href={'purchase/'+u.id}>
                                      Lihat
                                    </DropdownItem>
                                    { currentUser.type != 'Merchant' ? (
                                      <DropdownItem href={'edit_purchase/'+u.id}>
                                        Ubah
                                      </DropdownItem>
                                    ) : null }
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                              <td>
                                <div style={styles.status(u.status_payment)}>
                                  {u.status_payment_name}
                                </div>
                              </td>
                              <td>
                                <span className="mb-0 text-sm">
                                  {u.code}
                                </span> 
                              </td>
                              <td>{u.category_name}</td>
                              <td>{u.title}</td>
                              { currentUser && currentUser.type === 'Adminmaster' ? (
                                <td>[{u.user_id}] {u.company ? u.company.full_name : ''}</td>
                              ) : null }
                              <td>
                                <b>{numberFormat( parseInt(u.bill_price)+parseInt(u.bill_shipping_price) )}</b>
                              </td>
                              {/* <td>
                                {numberFormat(u.down_payment)}
                              </td> */}
                              {/* <td>
                                <b>{numberFormat( (parseInt(u.bill_price)+parseInt(u.bill_shipping_price))-parseInt(u.down_payment) )}</b>
                              </td> */}
                              {/* <td>{u.account_id}</td>
                              <td>{u.to_account_id}</td> */}
                              <td>{u.transaction_date_format}</td>
                              <td>{u.created_at}</td>
                            </tr>
                          )
                        })
                      }
                    </>
                  ) : (
                    <div className='col col-md-12 input-field2'>
                      <span>Data Tidak Ditemukan</span>
                    </div>
                  )}
                </tbody>
              </Table>
              {paging ? (
                <div class='col'>
                  <div onClick={loadMoreData} class='load-more-btn'>
                    {loadMore ? (
                      <center><Dots color='#444' /><br/></center>
                    ) : null}
                    SELANJUTNYA
                  </div>
                </div>
              ) : null}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default PurchaseIndex;


const styles: StyleSheet = {
  status: (status) => ({
    fontSize: 11,
    color: status === 'pending' ? 'orange' : status === 'paid' ? 'green' : status === 'process' ? '#11cdef' : 'red' ,
    paddingBottom: 2,
    borderRadius: 5,
  })
}