import React, { useEffect, useState } from 'react';
import API from '../../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../../ReactSession';
import { useHistory, useParams } from "react-router-dom";
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import DatePicker from "reactstrap-date-picker";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Input,
  Modal,
  ModalHeader, ModalBody, ModalFooter,
  CardBody,
  Button,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
  Label
} from "reactstrap";

const TrackShippingIndex = () => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const history = useHistory();

  const [data, setData] = useState([]);
  const [shippingMethod, setShippingMethod] = useState('');
  const [shippingCode, setShippingCode] = useState('');
  const [spinner, setSpinner] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Data Pengiriman Belum Ditelusuri');

  const expeditions = [
    {id: 'jne', name: 'JNE'},
    {id: 'jnt', name: 'JNT'},
    {id: 'sicepat', name: 'Sicepat'},
    {id: 'ninja', name: 'Ninja'},
    {id: 'pos', name: 'POS'}
  ]

  const checkPost = () => {    
    setSpinner(true);
    setErrorMessage('');
    const config = { headers: { Authorization: `Bearer ${token}` }, params: { shipping_method: shippingMethod, shipping_code: shippingCode } };
    
    API.get(`track_order`, config)
      .then(res => {
        setSpinner(false);
        const data = res.data.report.rajaongkir.result.manifest;
        setData(data);
      })
      .catch(error => {
        setSpinner(false);
        setData([]);
        if(error === undefined){
          setErrorMessage('Nomor Resi Tidak Dapat Ditelusuri');
        }else{
          setErrorMessage('Nomor Resi Tidak Dapat Ditelusuri');
        }
        console.log(error.response);
      })
  }

  return (
    <>
      <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col-md-4">            
            <Card className="shadow mb-20">
              <CardHeader>Track Pengiriman</CardHeader>
              <CardBody>
                <div class='row'>
                  <div className='col-sm-12'>
                    <Label className='label-form-group'>Pilih Pengiriman</Label>
                    <InputGroup className="input-group-alternative mb-20">
                      <select className='form-control' value={shippingMethod} onChange={e => setShippingMethod(e.target.value)} >
                        { expeditions ? (
                          expeditions.map((ex) => {
                            return (
                              <option key={ex.id} value={ex.id} >{ex.name.toUpperCase() }</option>
                            )
                          })
                        ) : null }
                      </select>
                    </InputGroup>
                  </div>
                  <div className='col-sm-12'>
                    <Label className='label-form-group'>Nomor Resi</Label>
                    <InputGroup className="input-group-alternative mb-20">
                      <Input
                        type='text'
                        placeholder="Masukan No.Resi"
                        value={shippingCode} onChange={e => setShippingCode(e.target.value)}
                      />
                    </InputGroup>
                  </div>
                  <div className='col-sm-12'>
                    <Button onClick={(e) => checkPost(e)} className='btn btn-info full-width' style={{background: (currentCompany.company_color3)}}>TRACK PENGIRIMAN</Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>

          <div className="col-md-8">
            <Card className="shadow mb-20">
              <CardHeader>Hasil Track Pengiriman</CardHeader>
              <CardBody>
                <div class='row'>
                  <div className='col-sm-12'>
                    { spinner ? (
                        <div className='text-center'>
                          <div className='loadLoading'><Dots color='#444' /></div>
                        </div>
                      ) : (
                        <Table className="align-items-center table-flush" responsive>
                          <tbody>
                            {
                              data.map((u) => {
                                return (
                                  <tr key={u.id}>
                                    <td>
                                      <i className="ni ni-app text-blue-new" style={{color: (currentCompany.company_color1)}}></i> {u.manifest_date} - {u.manifest_time} : {u.city_name} - {u.manifest_description}
                                    </td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </Table>
                      )
                    }

                    {
                      errorMessage ? (
                        <p><small>{errorMessage}</small></p>
                      ) : null
                    }
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>

      </Container>
    </>
  );
};

export default TrackShippingIndex;
